import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLinewToBr',
  standalone: true
})
export class NewLinewToBrPipe implements PipeTransform {

  transform(value: string): string {
    if(!value) return value;
    return value.replace(/\n/g, '<br>');
  }

}
