import { Component, OnInit, } from '@angular/core';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';
import { TableData } from '../../core/interfaces/table-data';
import { TableComponent } from '../../shared/components/table/table.component';
import { SearchBarComponent } from '../../shared/components/search-bar/search-bar.component';
import { AddUserComponent } from './add-user/add-user.component';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user/user.component';
import { UsersService } from '../../core/services/api/users.service';
import { filterTableData } from '../../core/utils/table-data.utils';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [NavbarComponent, TableComponent, SearchBarComponent, 
            AddUserComponent, CommonModule, UserComponent],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit{

  columnsExcluded: string[] = ['transactions','password'];

  backUpUsers: TableData[] = [];
  users: TableData[] = [];
  addUserModal: boolean = false;
  openUserModal: boolean = false;
  selectedUser: any;

  constructor(private UsersService: UsersService) { }

  ngOnInit(): void {
    this.getUsers();
  }


  openAddUserModal() {
    this.addUserModal = true;
  }
  closeAddUserModal() {
    this.getUsers();
    this.addUserModal = false;
  }


  onRowClicked(user: TableData) {
    this.selectedUser = user;
    this.openUserModal = true;
  }

  closeUserModal() {
    this.getUsers();
    this.openUserModal = false;
  }

  handleSearch(value: string) {
    this.users = this.backUpUsers;
    this.users = filterTableData(this.users, this.columnsExcluded, value);
  }

  private getUsers() {
    this.UsersService.getUsers().subscribe((data: any) => {
      this.backUpUsers = data;
      this.users = data;
    });
  }

  
}
