import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormArray, ValidatorFn } from '@angular/forms';

export function validarEmailFormat(email: string): boolean {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export function emailValidator(
  control: AbstractControl
): ValidationErrors | null {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const valid = emailRegex.test(control.value);
  return valid ? null : { invalidEmail: true };
}

export function phoneValidator(
  control: AbstractControl
): ValidationErrors | null {
  const phoneRegex =
    /^\+?[0-9]{1,4}?[-.\s]?(\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9})$/;
  const valid = phoneRegex.test(control.value);
  return valid ? null : { invalidPhone: true };
}

export function cuitValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const cuit = control.value;

    if (!cuit || cuit.length !== 11 || isNaN(cuit)) {
      return { invalidCuit: true };
    }

    const multipliers = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    let checksum = 0;

    for (let i = 0; i < 10; i++) {
      checksum += parseInt(cuit[i], 10) * multipliers[i];
    }

    const mod = checksum % 11;
    const expectedDigit = mod === 0 ? 0 : mod === 1 ? 9 : 11 - mod;

    if (expectedDigit !== parseInt(cuit[10], 10)) {
      return { invalidCuit: true };
    }

    return null;
  };
}

export function decimalNumberValidator(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value;
  const valid =
    /^\d+([.,]\d+)?$/.test(value) && parseFloat(value.replace(',', '.')) !== 0;
  return valid ? null : { invalidQuantity: true };
}

export function atLeastOneCheckedValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const formArray = control as FormArray;
    const hasValidControl = formArray.controls.some((group) => {
      const selected = group.get('selected')?.value;
      const quantity = group.get('quantity')?.value;
      return selected && quantity != null && quantity > 0;
    });
    return hasValidControl ? null : { atLeastOneValidControl: true };
  };
}

// Validador personalizado para confirmar contraseñas
export function matchPasswordValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const password = formGroup.get('password');
    const confirmPassword = formGroup.get('confirmPassword');

    if (
      password &&
      confirmPassword &&
      password.value !== confirmPassword.value
    ) {
      return { passwordMismatch: true };
    }
    return null;
  };
}

export function conditionalRequiredValidator(
  controlName: string,
  dependentControlName: string
): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: any } | null => {
    const control = formGroup.get(controlName);
    const dependentControl = formGroup.get(dependentControlName);

    if (control && dependentControl && dependentControl.value) {
      return control.value ? null : { required: true };
    }
    return null;
  };
}


export function priceValidator(totalCost: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const price = control.value;
    if (price <= 0 || price <= totalCost) {
      return { priceInvalid: true };
    }
    return null;
  };
}

