import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-decision-modal',
  standalone: true,
  imports: [],
  templateUrl: './decision-modal.component.html',
  styleUrl: './decision-modal.component.scss'
})
export class DecisionModalComponent {
  @Output() close = new EventEmitter<string>();

  closeDecisionModal() {
    this.close.emit('');
  }

  openAddInput() {
    this.close.emit("addInput");
  }

  openAddRawMaterial() {
    this.close.emit("addRawMaterial");
  }

}
