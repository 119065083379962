<div
  class="modal modal-bg fade show"
  tabindex="-1"
  style="display: block"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content modal-content-main">
      <div
        class="modal-header d-flex justify-content-center align-items-center"
      >
        <div class="row w-100">
          <div class="col">
            <h5 class="modal-title">Detalles Usuario</h5>
          </div>
          <div class="col d-flex justify-content-center">
            <div class="form-check form-switch">
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Editar</label
              >
              <input
                class="form-check-input custom-switch"
                type="checkbox"
                id="flexSwitchCheckDefault"
                (change)="toggleEditMode()"
              />
            </div>
          </div>
          <div class="col d-flex justify-content-end">
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              (click)="closeModal()"
            ></button>
          </div>
        </div>
      </div>
      <form #aux="ngForm" [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="row">
            <div class="col">
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="email">Email</label>
                  </div>
                  <div class="col">
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      formControlName="email"
                      [ngClass]="{
                        'is-invalid':
                          (userForm.get('email')?.dirty ||
                            userForm.get('email')?.touched ||
                            aux.submitted) &&
                          userForm.get('email')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        aux.submitted ||
                        (userForm.get('email')?.invalid &&
                          (userForm.get('email')?.dirty ||
                            userForm.get('email')?.touched))
                      "
                      class="text-danger"
                    >
                      <div *ngIf="userForm.get('email')?.errors?.['required']">
                        Email es requerido.
                      </div>
                      <div
                        *ngIf="!userForm.get('email')?.errors?.['required'] && (userForm.get('email')?.errors?.['email'] ||userForm.get('email')?.errors?.['invalidEmail'])"
                      >
                        Formato de email inválido.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="name">Nombre</label>
                  </div>
                  <div class="col">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      name="name"
                      formControlName="name"
                      [ngClass]="{
                        'is-invalid':
                          (userForm.get('name')?.dirty ||
                            userForm.get('name')?.touched ||
                            aux.submitted) &&
                          userForm.get('name')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        aux.submitted ||
                        (userForm.get('name')?.invalid &&
                          (userForm.get('name')?.dirty ||
                            userForm.get('name')?.touched))
                      "
                      class="text-danger"
                    >
                      <div *ngIf="userForm.get('name')?.errors?.['required']">
                        Nombre es requerido.
                      </div>
                      <div
                        *ngIf="!userForm.get('name')?.errors?.['required'] && userForm.get('name')?.errors?.['pattern']"
                      >
                        El nombre no debe contener números ni símbolos.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="surname">Apellido</label>
                  </div>
                  <div class="col">
                    <input
                      type="text"
                      class="form-control"
                      id="surname"
                      name="surname"
                      formControlName="surname"
                      [ngClass]="{
                        'is-invalid':
                          (userForm.get('surname')?.dirty ||
                            userForm.get('surname')?.touched ||
                            aux.submitted) &&
                          userForm.get('surname')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        aux.submitted ||
                        (userForm.get('surname')?.invalid &&
                          (userForm.get('surname')?.dirty ||
                            userForm.get('surname')?.touched))
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="userForm.get('surname')?.errors?.['required']"
                      >
                        Apellido es requerido.
                      </div>
                      <div
                        *ngIf="!userForm.get('surname')?.errors?.['required'] && userForm.get('surname')?.errors?.['pattern']"
                      >
                        El apellido no debe contener números ni símbolos.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="phone">Teléfono</label>
                  </div>
                  <div class="col">
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      name="phone"
                      formControlName="phone"
                      [ngClass]="{
                        'is-invalid':
                          (userForm.get('phone')?.dirty ||
                            userForm.get('phone')?.touched ||
                            aux.submitted) &&
                          userForm.get('phone')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        (userForm.get('phone')?.invalid &&
                          (userForm.get('phone')?.dirty ||
                            userForm.get('phone')?.touched)) ||
                        (aux.submitted && userForm.get('phone')?.invalid)
                      "
                      class="text-danger"
                    >
                      <div *ngIf="userForm.get('phone')?.errors?.['required']">
                        Teléfono es requerido.
                      </div>
                      <div
                        *ngIf="userForm.get('phone')?.errors?.['invalidPhone']"
                      >
                        Formato de teléfono inválido.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-5">
                    <label for="password">Contraseña</label>
                  </div>
                  <div class="col">
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      name="password"
                      formControlName="password"
                      [ngClass]="{
                        'is-invalid':
                          (userForm.get('password')?.dirty ||
                            userForm.get('password')?.touched ||
                            aux.submitted) &&
                          userForm.get('password')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        userForm.get('password')?.value &&
                        userForm.get('password')?.invalid &&
                        (userForm.get('password')?.dirty ||
                          userForm.get('password')?.touched ||
                          aux.submitted)
                      "
                      class="text-danger"
                    >
                      Ingrese una contraseña válida.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-5">
                    <label for="confirmPassword">Confirmar Contraseña</label>
                  </div>
                  <div class="col">
                    <input
                      type="password"
                      class="form-control"
                      id="confirmPassword"
                      formControlName="confirmPassword"
                      [ngClass]="{
                        'is-invalid':
                          (userForm.get('confirmPassword')?.dirty ||
                            userForm.get('confirmPassword')?.touched ||
                            aux.submitted) &&
                          userForm.get('confirmPassword')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div *ngIf="userForm.get('password')?.value">
                      <div
                        *ngIf="
                          !userForm.get('confirmPassword')?.value &&
                          (aux.submitted ||
                            (userForm.get('confirmPassword')?.dirty &&
                            userForm.get('confirmPassword')?.touched))
                        "
                        class="text-danger"
                      >
                        Confirmar Contraseña es requerida.
                      </div>
                      <div
                        *ngIf="userForm.get('confirmPassword')?.value && userForm.hasError('passwordMismatch') && (aux.submitted || userForm.get('confirmPassword')?.dirty || userForm.get('confirmPassword')?.touched)"
                        class="text-danger"
                      >
                        Las contraseñas no coinciden.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <label for="role">Rol:</label>
                <div class="d-flex flex-column align-items-center">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="roleAdmin"
                      name="role"
                      value="Admin"
                      formControlName="role"
                      [ngClass]="{
                        'is-invalid':
                          (userForm.get('role')?.dirty ||
                            userForm.get('role')?.touched ||
                            aux.submitted) &&
                          userForm.get('role')?.invalid
                      }"
                    />
                    <label class="form-check-label" for="roleAdmin"
                      >Admin</label
                    >
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="roleUser"
                      name="role"
                      value="User"
                      formControlName="role"
                      [ngClass]="{
                        'is-invalid':
                          (userForm.get('role')?.dirty ||
                            userForm.get('role')?.touched ||
                            aux.submitted) &&
                          userForm.get('role')?.invalid
                      }"
                    />
                    <label class="form-check-label" for="roleUser">User</label>
                  </div>
                </div>
                <div class="error-container">
                  <div
                    *ngIf="
                      (userForm.get('role')?.invalid &&
                        (userForm.get('role')?.dirty ||
                          userForm.get('role')?.touched)) ||
                      (aux.submitted && userForm.get('role')?.invalid)
                    "
                    class="text-danger"
                  >
                    Rol es requerido.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="submit" class="btn btn-custom">GUARDAR</button>
          <button *ngIf="!isMe()" type="button" class="btn btn-danger" (click)="confirmDelete()">Eliminar</button>
        </div>
      </form>
    </div>
  </div>
</div>


<app-delete-modal
  *ngIf="confirmDeleteModal"
  [headerMessage]="'Eliminar Usuario'"
  [bodyMessage]="'¿Estás seguro que deseas borrar este Usuario?'"
  (cancel)="closeDeleteModal()"
  (confirm)="deleteUser()"
/>  
