import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';
import { TableComponent } from '../../shared/components/table/table.component';
import { SearchBarComponent } from '../../shared/components/search-bar/search-bar.component';
import { TableData } from '../../core/interfaces/table-data';
import { Router } from '@angular/router';
import { BatchesService } from '../../core/services/api/batches.service';
import { ViewBatchComponent } from './view-batch/view-batch.component';
import { CommonModule } from '@angular/common';
import { SellBatchComponent } from './sell-batch/sell-batch.component';
import { filterTableData } from '../../core/utils/table-data.utils';
@Component({
  selector: 'app-production',
  standalone: true,
  imports: [NavbarComponent, TableComponent, SearchBarComponent, 
    ViewBatchComponent, CommonModule, SellBatchComponent],
  templateUrl: './production.component.html',
  styleUrl: './production.component.scss'
})
export class ProductionComponent implements OnInit{
  batches: TableData[] = [];
  backUpBatches: TableData[] = [];
  excludedColumns: string[] = ['batchPackagingElementsQuantity','batchPackagingElements','batchIngredientsQuantity','batchIngredients','description','creationDate','lastModificationDate'];
  selectedBatch: TableData = {};
  openBatchModal: boolean = false;
  openSellModal: boolean = false;

  constructor(private route: Router, private batchesServices: BatchesService) {}

  ngOnInit(): void {
    this.getBatches();
  }

  goTo(){
    this.route.navigate(['/production/add']);
  }

  onRowClicked(prov: TableData) {
    this.selectedBatch = prov;
    this.openBatchModal = true;
  }

  closeModal(): void {
    this.getBatches();
    this.openBatchModal = false;
  }


  openSellBatch(){
    this.openSellModal = true;
  }
  
  closeSellModal(){
    this.getBatches();
    this.openSellModal = false;
  }

  handleSearch(value: string) {
    this.batches = this.backUpBatches;
    this.batches = filterTableData(this.batches, this.excludedColumns, value);
  }

  private getBatches(): void {
    this.batchesServices.getBatches().subscribe((data: any) => {
      this.batches = data;
      this.backUpBatches = data;
    });
    
  }

}
