import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, map, catchError } from 'rxjs';
import { environment } from '../../../../evironments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl + '/api/login';

  constructor(private http: HttpClient) {}

    logIn(email: string, password: string, rememberMe: boolean): Observable<{ success: boolean, message?: string }> {
      return this.http.post<{ JWT: { string: string } }>(`${this.apiUrl}`, { email, password })
      .pipe(
        map(response => {
          const token = response.JWT.string;
          
          // Decodifica el JWT (por ejemplo, utilizando jwt-decode) para extraer role y userId
          const payload = this.decodeToken(token);
          const role = payload.role;
          const userId = payload.id;
          console.log('role, userId',role, userId);
          //console.log('token',token);
          //console.log('rememberMe',rememberMe);
          if (rememberMe) {
            this.clearStorageItems();
            this.setStorageItems(token, role, userId);
          } else {
            this.clearSessionItems();
            this.setSessionItems(token, role, userId);
          }
          
          return { success: true };
        }),
        catchError(error => {
          let errorMsg = 'Login failed';
          if (error.status === 401) {
            errorMsg = 'Invalid email or password';
          } else if (error.status === 404) {
            errorMsg = 'Email not found';
          }
          return of({ success: false, message: errorMsg });
        })
      );
    }

  logOut(): void {
    this.clearStorageItems();
    this.clearSessionItems();
  }

  isLoggedIn(): boolean {
    return (
      !!this.getStorageItem('authToken') || !!this.getSessionItem('authToken')
    );
  }

  getUserRole(): string | null {
    return this.getStorageItem('role') || this.getSessionItem('role') || null;
  }

  getUserId(): string | null {
    return (
      this.getStorageItem('userId') || this.getSessionItem('userId') || null
    );
  }

  getToken(): string | null {
    return this.getStorageItem('authToken') || this.getSessionItem('authToken') ||
      null;
  }

  private setStorageItems(token: string, role: string, userId: string): void {
    try {
      localStorage.setItem('authToken', token);
      localStorage.setItem('role', role);
      localStorage.setItem('userId', userId);
      //console.log('Saved in localStorage');
    } catch (e) {
      console.error('Error setting items in localStorage:', e);
    }
  }

  private setSessionItems(token: string, role: string, userId: string): void {
    try {
      sessionStorage.setItem('authToken', token);
      sessionStorage.setItem('role', role);
      sessionStorage.setItem('userId', userId);
      //console.log('Saved in sessionStorage');
    } catch (e) {
      console.error('Error setting items in sessionStorage:', e);
    }
  }

  private clearStorageItems(): void {
    try {
      localStorage.removeItem('authToken');
      localStorage.removeItem('role');
      localStorage.removeItem('userId');
    } catch (e) {
      console.error('Error clearing items from localStorage:', e);
    }
  }

  private clearSessionItems(): void {
    try {
      sessionStorage.removeItem('authToken');
      sessionStorage.removeItem('role');
      sessionStorage.removeItem('userId');
    } catch (e) {
      console.error('Error clearing items from sessionStorage:', e);
    }
  }

  private getStorageItem(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      //console.error('Error getting item from localStorage:', e);
      return null;
    }
  }

  private getSessionItem(key: string): string | null {
    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      //console.error('Error getting item from sessionStorage:', e);
      return null;
    }
  }

  private decodeToken(token: string): any {
    // Usa una librería como jwt-decode o implementa tu propio decodificador si es necesario.
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload;
  }
}
