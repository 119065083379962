import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError} from 'rxjs';
import { handleError } from '../../utils/api-error-handler.utils';
import { environment } from '../../../../evironments/environment';
@Injectable({
  providedIn: 'root'
})
export class SalesChannelsService {

  private apiUrl = environment.apiUrl + '/api/SalesChannels';

  constructor(private http: HttpClient) {}

  getSalesChannels(): Observable<any> {
    return this.http.get<any>(this.apiUrl).pipe(catchError(handleError));
  }

  getSaleChannelId(id: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`).pipe(catchError(handleError));
  }
  createSaleChannel(sc: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, sc).pipe(catchError(handleError));
  }

  updateSaleChannel(sc: any): Observable<any> {
    return this.http.put<any>(this.apiUrl, sc).pipe(catchError(handleError));
  }

  deleteSaleChannel(id: any): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${id}`).pipe(catchError(handleError));
  }
}
