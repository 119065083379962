<div
  class="modal modal-bg fade show"
  tabindex="-1"
  style="display: block"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header ">
        <h1 class="modal-title fs-5" id="confirmDeleteModalLabel">
          {{ headerMessage }}
        </h1>
        <button
          type="button"
          class="btn-close"
          (click)="closeDeleteModal()"
        ></button>
      </div>
      <div class="modal-body">
        <p>{{ bodyMessage }}</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeDeleteModal()"
        >
          CANCELAR
        </button>
        <button type="button" class="btn btn-danger" (click)="deleteEntity()">
          CONFIRMAR
        </button>
      </div>
    </div>
  </div>
</div>
