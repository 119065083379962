import { TableData } from '../interfaces/table-data';
import { User } from '../interfaces/entities/user';

export function mapToTableData(data: any[]): TableData[] {
    return data.map(item => {
      let tableDataItem: TableData = {};
      for (let key in item) {
        tableDataItem[key] = item[key];
      }
      return tableDataItem;
    });
  }

export function mapToUser(data: any): User {
    let user: User = {
      id: data.id,
      name: data.name,
      surname: data.surname,
      email: data.email,
      phone: data.phone,
      password: data.password,
      role: data.role
    };
    return user;
  }

/**
 * Formatea una fecha para un input de tipo datetime-local.
 * @param date - Fecha en milisegundos o cadena de texto que puede ser convertida a un objeto Date.
 * @returns La fecha formateada en el formato YYYY-MM-DDTHH:MM.
 */
export const formatDate = (date: any): string => {
  // Verifica si el parámetro es una fecha válida
  if (!date) return '';

  // Convierte el parámetro a un objeto Date
  const dateObj = new Date(date);

  // Obtiene los componentes de la fecha
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Meses desde 0 (enero) hasta 11 (diciembre)
  const day = String(dateObj.getDate()).padStart(2, '0');
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');

  // Retorna la fecha formateada en el formato YYYY-MM-DDTHH:MM
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export function formatDateString(input: string): string {
  // Separar la fecha y la hora
  const [datePart, timePart] = input.split(' ');

  // Separar día, mes y año
  const [day, month, year] = datePart.split('/').map(Number);

  // Separar horas y minutos
  const [hours, minutes] = timePart.split(':').map(Number);

  // Crear un objeto Date con el año ajustado
  const fullYear = year + 2000; // Asumimos que el año es del siglo XXI (20xx)
  const formattedDate = new Date(fullYear, month - 1, day, hours, minutes);

  // Convertir la fecha a un formato ISO (yyyy-MM-dd'T'HH:mm:ss)
  return formattedDate.toISOString().slice(0, 19);
}
