<app-navbar></app-navbar>
<div
  class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100"
>
  <div class="col-flex justify-content-center align-items-center">
    <div class="row header-row">
      <div class="col">
        <h1 class="view-title">Mi Perfil</h1>
      </div>

      <div class="col d-flex justify-content-end">
        <a type="button" class="btn btn-custom" (click)="openUserModal()"
          >EDITAR</a
        >
      </div>
    </div>
    <div class="card ">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col">
            <label class="subtitle">Nombre:</label>
          </div>
          <div class="col">
            <p class="card-text">{{ user["name"] }}</p>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <label class="subtitle">Apellido:</label>
          </div>
          <div class="col">
            <p class="card-text">{{ user["surname"] }}</p>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <label class="subtitle">Teléfono:</label>
          </div>
          <div class="col">
            <p class="card-text">{{ user["phone"] }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="subtitle">Email:</label>
          </div>
          <div class="col">
            <p class="card-text">{{ user["email"] }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-edit-profile-modal
  *ngIf="openEditProfileModal"
  [userInput]="user"
  (close)="closeUserModal()"
></app-edit-profile-modal>