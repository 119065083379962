import { Component, EventEmitter, Output } from '@angular/core';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProvidersService } from '../../../core/services/api/providers.service';
import {
  emailValidator,
  phoneValidator,
  cuitValidator,
} from '../../../core/utils/validators';
import { TableData } from '../../../core/interfaces/table-data';

@Component({
  selector: 'app-add-provider',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './add-provider.component.html',
  styleUrl: './add-provider.component.scss',
})
export class AddProviderComponent {
  @Output() closeModalEvent = new EventEmitter<void>();
  providerForm: FormGroup;

  provider: TableData = {};

  constructor(
    private providersService: ProvidersService,
    private formBuilder: FormBuilder
  ) {
    this.providerForm = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.pattern('^[a-zA-ZÀ-ÿ0-9 ]*$')]],
      email: ['', [Validators.required, emailValidator]],
      phone: ['', [phoneValidator]],
      cuit: ['', [Validators.required, cuitValidator]],
      cp: ['', [Validators.required,  Validators.pattern('^(?!0000)[0-9]{4}$')]], // Ejemplo para un CP de 4-6 dígitos
      address: ['', [Validators.required]],
      line2: [''],
      description: [''],
    });
  }

  closeModal() {
    this.closeModalEvent.emit();
  }

  onSubmit() {
    if (this.providerForm.valid) {
      const fullAddress = `${this.providerForm.get('cp')?.value}|${
        this.providerForm.get('address')?.value
      }|${this.providerForm.get('line2')?.value}`
        .trim()
        .replace(/|\s*$/, '');
      this.provider = {
        name: this.providerForm.get('name')?.value,
        email: this.providerForm.get('email')?.value,
        phone: this.providerForm.get('phone')?.value,
        cuit: this.providerForm.get('cuit')?.value,
        address: fullAddress,
        description: this.providerForm.get('description')?.value || '',
      };
      //Call to backend
      this.providersService.createProvider(this.provider).subscribe(
        (response) => {
          this.closeModal();
        },
        (error) => {
          console.error('Error al crear el proveedor:', error);
        }
      );
    } else {
      console.error('Formulario inválido. Revise los campos.');
    }
  }
}
