<div
  class="container-fluid vh-100 d-flex flex-column justify-content-center align-items-center login-background"
  style="overflow: hidden"
>
  <div class="logo-container mb-5">
    <img
      src="assets/logo_salacomunitaria.svg"
      alt="Logo principal"
      class="img-fluid logo-principal"
    />
  </div>

  <div class="login-container col-12 col-md-8 col-lg-8 col-xl-4">
    <form
      #aux="ngForm"
      [formGroup]="loginForm"
      (ngSubmit)="onLogin()"
      class="login-form p-4 shadow"
    >
      <div class="input-group mb-3">
        <span
          class="input-group-text text-white align-items-center justify-content-center"
          [ngClass]="{
            'is-invalid':
              (loginForm.get('email')?.dirty ||
                loginForm.get('email')?.touched ||
                aux.submitted) &&
              loginForm.get('email')?.invalid
          }"
        >
          <i class="bi bi-envelope"></i>
        </span>
        <input
          type="email"
          id="email"
          formControlName="email"
          class="form-control login-input text-white"
          placeholder="Ingrese su email"
          aria-label="Email"
          [ngClass]="{
            'is-invalid':
              (loginForm.get('email')?.dirty ||
                loginForm.get('email')?.touched ||
                aux.submitted) &&
              loginForm.get('email')?.invalid
          }"
        />
      </div>
      <div class="input-group mb-3">
        <span
          class="input-group-text text-white align-items-center justify-content-center"
          [ngClass]="{
            'is-invalid':
              (loginForm.get('password')?.dirty ||
                loginForm.get('password')?.touched ||
                aux.submitted) &&
              loginForm.get('password')?.invalid
          }"
          >
          <i class="bi bi-lock"></i>
        </span>
        <input
          type="password"
          id="password"
          formControlName="password"
          class="form-control login-input text-white"
          placeholder="Ingrese su contraseña"
          aria-label="Contraseña"
          [ngClass]="{
            'is-invalid':
              (loginForm.get('password')?.dirty ||
                loginForm.get('password')?.touched ||
                aux.submitted) &&
              loginForm.get('password')?.invalid
          }"
        />
      </div>
      <button type="submit" class="btn login-btn w-100 text-white">
        LOGIN
      </button>
      <div class="error-container">
        <span class="error-message">{{ error }}</span>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="rememberMe"
          formControlName="rememberMe"
        />
        <label class="form-check-label" for="rememberMe">Recordarme</label>
      </div>
    </form>
  </div>

  <footer class="footer mt-auto py-3 w-100">
    <div
      class="container d-flex justify-content-center align-items-center footer-container"
    >
      <img
        src="assets/logo_informatica.png"
        alt="Logo facultad Informatica"
        class="logo-footer mx-2"
      />
      <div class="vr"></div>
      <img
        src="assets/logo_unlp.png"
        alt="Logo UNLP"
        class="logo-footer mx-2"
      />
      <div class="vr"></div>
      <img
        src="assets/logo_veterinaria.png"
        alt="Logo facultad Veterinaria"
        class="logo-footer mx-2"
      />
    </div>
  </footer>
</div>
