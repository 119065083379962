import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { TableData } from '../../../core/interfaces/table-data';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProvidersService } from '../../../core/services/api/providers.service';
import { phoneValidator, emailValidator, cuitValidator } from '../../../core/utils/validators';
import { DeleteModalComponent } from '../../../shared/components/delete-modal/delete-modal.component';
@Component({
  selector: 'app-provider',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormsModule, DeleteModalComponent],
  templateUrl: './provider.component.html',
  styleUrl: './provider.component.scss'
})
export class ProviderComponent {
  @Input() providerInput: TableData = {};
  @Output() closeProviderModalEvent = new EventEmitter<void>();

  providerForm: FormGroup;
  isToggled: boolean = false;
  confirmDeleteModal: boolean = false;

  constructor(private providersService: ProvidersService, private fb: FormBuilder) {
    this.providerForm = this.fb.group({
      name: [{ value: '', disabled: !this.isToggled }, [Validators.required,  Validators.pattern('^[a-zA-ZÀ-ÿ0-9 ]*$')]],
      email: [{ value: '', disabled: !this.isToggled }, [Validators.required, emailValidator]],
      phone: [{ value: '', disabled: !this.isToggled }, [Validators.required, phoneValidator]],
      cuit: [{ value: '', disabled: !this.isToggled }, [Validators.required, cuitValidator]],
      cp: [{ value: '', disabled: !this.isToggled }, [Validators.required, Validators.pattern('^(?!0000)[0-9]{4}$')]],
      address: [{ value: '', disabled: !this.isToggled },[Validators.required]],
      line2: [{ value: '', disabled: !this.isToggled }],
      description: [{ value: '', disabled: !this.isToggled }]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['providerInput'] && changes['providerInput'].currentValue) {
      //console.log(this.transformProviderInput(this.providerInput));
      this.providerForm.patchValue(this.transformProviderInput(this.providerInput));
    }
  }

  toggleEditMode() {
    this.isToggled = !this.isToggled;
    if (this.isToggled) {
      this.providerForm.enable(); // Habilita todos los controles del formulario
    } else {
      this.providerForm.disable(); // Deshabilita todos los controles del formulario
    }
  }
 
  provider: any;
  onSubmit() {
    //console.log(this.providerForm);
    if (this.providerForm.valid) {
      const fullAddress = `${this.providerForm.get('cp')?.value}|${
        this.providerForm.get('address')?.value
      }|${this.providerForm.get('line2')?.value}`
        .trim()
        .replace(/|\s*$/, '');
      this.provider = {
        id: this.providerInput['id'],
        name: this.providerForm.get('name')?.value,
        email: this.providerForm.get('email')?.value,
        phone: this.providerForm.get('phone')?.value,
        cuit: this.providerForm.get('cuit')?.value,
        address: fullAddress,
        description: this.providerForm.get('description')?.value || '' 
      };
      //Call to backend
      this.providersService.updateProvider(this.provider).subscribe(
        (response) => {
          //console.log('Proveedor actualizado:', response);
          this.closeModal();
        },
        (error) => {
          console.error('Error al actualizar el proveedor:', error);
        }
      );
    } else {
      this.providerForm.markAllAsTouched();
    }
  }

  closeModal() {
    this.closeProviderModalEvent.emit();
  }

  confirmDelete() {
    this.confirmDeleteModal = true;
  }

  closeDeleteModal() {
    this.confirmDeleteModal = false;
  }

  deleteProvider() {
    //Call backend
    this.providersService.deleteProvider(this.providerInput['id']).subscribe(
      (response) => {
        //console.log('Proveedor eliminado:', response);
        this.confirmDeleteModal = false;
        this.closeModal();
      },
      (error) => {
        console.error('Error al eliminar el proveedor:', error);
      }
    );
    
  }

  
  private transformProviderInput(data: TableData): any {
    const [cp = '', address = '', line2 = ''] = data['address']?.split('|') || [];
    return {
      id: data['id'] ?? null,
      name: data['name'] ?? '',
      email: data['email'] ?? '',
      phone: data['phone'] ?? '',
      cuit: data['cuit'] ?? '',
      cp: cp.trim(),
      address: address.trim(),
      line2: line2.trim(),
      description: data['description']
    };
  }


}
