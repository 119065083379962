import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../../../shared/components/navbar/navbar.component';
import { RecipesService } from '../../../core/services/api/recipes.service';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NewLinewToBrPipe } from '../../../core/pipes/new-linew-to-br.pipe';
import { TableData } from '../../../core/interfaces/table-data';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { parse } from 'path';
@Component({
  selector: 'app-view-recipe',
  standalone: true,
  imports: [NavbarComponent, CommonModule, NewLinewToBrPipe],
  templateUrl: './view-recipe.component.html',
  styleUrl: './view-recipe.component.scss',
})
export class ViewRecipeComponent implements OnInit {
  id: number = 0;
  recipe: TableData = {};

  leftColumnIngredients: Argument[] = [];
  rightColumnIngredients: Argument[] = [];
  leftColumnInputs: Argument[] = [];
  rightColumnInputs: Argument[] = [];

  constructor(
    private route: ActivatedRoute,
    private recipeService: RecipesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id = +(this.route.snapshot.paramMap.get('id') ?? 0);
    if (this.id !== 0) {
      this.recipeService.getRecipeById(this.id).subscribe(
        (res) => {
          (this.recipe = res), this.splitIngredients(), this.splitInputs();
        },
        (error) => console.error(error)
      );
    }
    //console.log(this.recipe);
  }

  goTo() {
    this.router.navigate(['/recipes', this.id, 'modify']);
  }

  deleteRecipe() {
    this.recipeService.deleteRecipe(this.id).subscribe(
      (res) => {
        this.router.navigate(['/recipes']);
      },
      (error) => console.error(error)
    );
  }

  generatePDF() {
    const doc = new jsPDF();
    let yPosition = 10;
  
    // Título de la receta
    doc.setFontSize(18);
    doc.text(this.recipe['name'], 10, yPosition);
  
    // Sección de Ingredientes
    yPosition += 20;
    doc.setFontSize(14);
    doc.text('Ingredientes:', 10, yPosition);
    yPosition += 10;
  
    // Listar los ingredientes
    doc.setFontSize(12);
    parseList(this.recipe['recipeIngredients']).forEach((ing: any) => {
      doc.text(`- ${ing.name}: ${ing.quantity} ${ing.unit}`, 10, yPosition);
      yPosition += 10;
    });
  
    // Sección de Insumos
    yPosition += 5;
    doc.setFontSize(14);
    doc.text('Insumos:', 10, yPosition);
    yPosition += 10;
  
    // Listar los insumos
    doc.setFontSize(12);
    parseList(this.recipe['recipePackagingElements']).forEach((ins: any) => {
      doc.text(`- ${ins.name}: ${ins.quantity} ${ins.unit}`, 10, yPosition);
      yPosition += 10;
    });
  
    // Sección de Procedimiento
    yPosition += 10;
    doc.setFontSize(14);
    doc.text('Procedimiento', 10, yPosition);
    yPosition += 10;
  
    // Convertir HTML a texto plano
    const procedureText = this.convertHTMLToText(this.recipe['description']);
    const procedureLines = doc.splitTextToSize(procedureText, 180);
  
    // Agregar las líneas de procedimiento al PDF
    procedureLines.forEach((line: string) => {
      doc.text(line, 10, yPosition);
      yPosition += 10;
    });
  
    // Genera y descarga el PDF
    doc.save(`${this.recipe['name']}.pdf`);
  }
  
  // Función para convertir HTML en texto plano
  convertHTMLToText(html: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
  
    // Reemplazar los <br> con saltos de línea
    const replaceBreaks = (node: ChildNode) => {
      if (node.nodeName === 'BR') {
        return '\n';
      }
      return node.textContent || '';
    };
  
    // Reemplazar las etiquetas de lista con viñetas
    const replaceLists = (node: ChildNode) => {
      if (node.nodeName === 'LI') {
        return `• ${node.textContent?.trim()}\n`;
      }
      return node.textContent || '';
    };
  
    // Extraer texto del HTML, respetando las etiquetas relevantes
    let text = Array.from(tempDiv.childNodes)
      .map((node) => {
        if (node.nodeName === 'BR') {
          return replaceBreaks(node);
        } else if (node.nodeName === 'UL' || node.nodeName === 'OL') {
          return Array.from(node.childNodes).map(replaceLists).join('');
        } else if (node.nodeName === 'P') {
          return `${node.textContent?.trim()}\n\n`;
        }
        return node.textContent || '';
      })
      .join('');
  
    return text.trim();
  }
  

  private splitIngredients() {
    if (this.recipe && this.recipe['recipeIngredients']) {
      let toggle: boolean = true;
      const ingredients = parseList(this.recipe['recipeIngredients']);
      ingredients.forEach((element: Argument) => {
        if (toggle) {
          this.leftColumnIngredients.push(element);
        } else {
          this.rightColumnIngredients.push(element);
        }
        toggle = !toggle;
      });
    } else {
      console.error('Ingredients are undefined or not an array');
    }
  }

  private splitInputs() {
    if (this.recipe && this.recipe['recipePackagingElements']) {
      let toggle: boolean = true;
      const packages = parseList(this.recipe['recipePackagingElements']);
      packages.forEach((element: Argument) => {
        if (toggle) {
          this.leftColumnInputs.push(element);
        } else {
          this.rightColumnInputs.push(element);
        }
        toggle = !toggle;
      });
    } else {
      console.error('Inpusts are undefined or not an array');
    }
  }
}

interface Argument {
  name: string;
  quantity: string;
  unit: string;
}

function parseList(list: string): Argument[] {
  if (!list) return [];

  // Asegúrate de que la cadena no tenga espacios innecesarios alrededor
  const parsedList = list.split(';').filter((item) => item.trim() !== '');
  const args: Argument[] = parsedList.map((element: string) => {
    const [name, quantity, unit] = element.split(',');
    if (!name || !quantity || !unit) {
      console.error(`Invalid format: ${element}`);
      return { name: '', quantity: '', unit: '' };
    }
    return {
      name: name.trim(),
      quantity: quantity.trim(),
      unit: unit.trim(),
    };
  });
  return args;
}
