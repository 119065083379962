<div
  class="modal modal-bg fade show"
  tabindex="-1"
  style="display: block"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content modal-content-main">
      <div
        class="modal-header d-flex justify-content-center align-items-center"
      >
        <h5 class="modal-title">Detalles Evento</h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="closeModal()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <!-- Columna Izquierda -->
          <div class="col">
            <div *ngFor="let key of splitKeys().leftColumn" class="p-2">
              <div class="row row-custom">
                <div class="col-3">
                  <h3>{{ translateLabel(key) }}:</h3>
                </div>
                <div class="col">
                  <span class="data">{{ event[key] }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Columna Derecha -->
          <div class="col">
            <div *ngFor="let key of splitKeys().rightColumn" class="p-2">
              <div class="row row-custom">
                <div class="col-3">
                  <h3>{{ translateLabel(key) }}:</h3>
                </div>
                <div class="col">
                  <span *ngIf="key === 'user'" class="data"
                    >{{ event[key]["name"] }} {{ event[key]["surname"] }} ({{
                      event[key]["id"]
                    }})</span
                  >
                  <span *ngIf="key === 'saleChannel'" class="data">{{
                    event[key]["name"]
                  }}</span>
                  <span
                    *ngIf="key !== 'user' && key !== 'saleChannel'"
                    class="data"
                  >
                    <div *ngIf="key === 'cost'">${{ event[key] }}</div>
                    <div *ngIf="key !== 'cost'">{{ event[key] }}</div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="custom-container">
              <h3 class="p-2">Descripción:</h3>
              <div *ngIf="isSale">
                <div class="col p-2">
                  <ul class="p-2">
                    <!-- Primera columna de elementos -->
                    <li
                      *ngFor="let element of splitElementsResult.leftColumn"
                      class="p-2"
                    >
                      <span>{{ element }}</span>
                    </li>
                  </ul>
                  <ul class="p-2">
                    <!-- Segunda columna de elementos -->
                    <li
                      *ngFor="let element of splitElementsResult.rightColumn"
                      class="p-2"
                    >
                      <span>{{ element }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="!isSale">
                <div class="col">
                  <span class="p-2">{{ event["description"] }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn btn-custom" (click)="closeModal()">
              CERRAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
