import { Component, input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { NavbarComponent } from '../../../shared/components/navbar/navbar.component';
import { Units } from '../../../shared/enums/units.enum';
import { CategoriesService } from '../../../core/services/enums/categories.service';
import { RawMaterialsService } from '../../../core/services/api/rawmaterials.service';
import { InputsService } from '../../../core/services/api/inputs.service';
import { EditorModule } from 'primeng/editor';
import { atLeastOneCheckedValidator } from '../../../core/utils/validators';
import { BatchesService } from '../../../core/services/api/batches.service';
import {
  formatDate,
  formatDateString,
} from '../../../core/utils/mapping.utils';
import { TableData } from '../../../core/interfaces/table-data';
import { Cons, last } from 'rxjs';
@Component({
  selector: 'app-modify-batch',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NavbarComponent, EditorModule],
  templateUrl: './modify-batch.component.html',
  styleUrl: './modify-batch.component.scss',
})
export class ModifyBatchComponent implements OnInit {
  batchId: number = 0;
  batchForm: FormGroup;
  addCategoryForm: FormGroup;
  batch: TableData = {};
  categories: string[] = [];
  units: string[] = [];
  minDate: string = '';
  addCategoryModal: boolean = false;
  ad: any;
  lmd: any;

  avaibleRawMaterials: any[] = [];
  avaibleInputs: any[] = [];

  preSelectedIngredients: element[] = [];
  preSelectedIngredientsQuantity: any[] = [];
  preSelectedInputs: element[] = [];
  totalCost: number = 0;
  constructor(
    private formbuild: FormBuilder,
    private CategoriesService: CategoriesService,
    private RawMaterialsService: RawMaterialsService,
    private InputsService: InputsService,
    private BatchesService: BatchesService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    this.batchForm = this.formbuild.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      category: ['', [Validators.required]],
      quantity: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      unit: ['', [Validators.required]],
      expirationDate: [''],
      description: [''],
      price: [0, [Validators.required, Validators.min(this.totalCost)]],
      ingredients: this.formbuild.array([], {
        validators: atLeastOneCheckedValidator(),
      }),
      packages: this.formbuild.array([], {
        validators: atLeastOneCheckedValidator(),
      }),
    });
    this.addCategoryForm = this.formbuild.group({
      newCategory: [
        '',
        [Validators.required, Validators.pattern('[a-zA-Z ]*')],
      ],
    });
  }

  ngOnInit(): void {
    this.getBatch();
    this.getRawMaterials();
    this.getInputs();
    this.CategoriesService.chargeCategories();
    this.getCategories();
    this.units = Object.values(Units);
    const now = new Date();
    this.minDate = now.toISOString().slice(0, 16); // Formato YYYY-MM-DDTHH:MM

    console.log('Preselected Ingredients: ', this.preSelectedIngredients);
    console.log('Preselected Inputs: ', this.preSelectedInputs);
  }

  addCategory(): void {
    if (this.addCategoryForm.valid) {
      this.categories.push(this.addCategoryForm.value.newCategory);
      this.addCategoryForm.reset();
      this.addCategoryModal = false;
    } else {
      console.log('Formulario de categoria inválido.');
    }
  }

 
  onSubmit(): void {
    console.log(this.batchForm);
    if (this.batchForm.valid) {
      const selectedIngredientControls =
        this.batchForm.get('ingredients')?.value;
      //console.log('FSelected Ingredients: ', selectedIngredientControls);
      const selectedInputControls = this.batchForm.get('packages')?.value;
      //console.log('FSelected Inputs: ', selectedInputControls);

      const selectedIngredientIds = selectedIngredientControls
        .filter((ingredient: any) => ingredient.selected)
        .map((ingredient: any) => ingredient.id);
      //console.log('FSelected Ingredients: ', selectedIngredientIds);
      const selectedInputIds = selectedInputControls
        .filter((input: any) => input.selected)
        .map((input: any) => input.id);
      //console.log('FSelected Inputs: ', selectedInputIds);
      // Ingredientes y empaques seleccionados y deseleccionados
      const updatedIngredients = this.avaibleRawMaterials.filter(
        (material: any) => selectedIngredientIds.includes(material.id)
      );
      //console.log('FUpdated Ingredients: ', updatedIngredients);
      const updatedInputs = this.avaibleInputs.filter((material: any) =>
        selectedInputIds.includes(material.id)
      );
      //console.log('FUpdated Inputs: ', updatedInputs);

      const ingredientsQuantity = selectedIngredientControls
        .filter((ingredient: any) => ingredient.selected)
        .map((ingredient: any) => `${ingredient.id},${ingredient.quantity}`)
        .join(';');
      //console.log('IngredientesQ: ', ingredientsQuantity);
      const inputsQuantity = selectedInputControls
        .filter((input: any) => input.selected)
        .map((input: any) => `${input.id},${input.quantity}`)
        .join(';');
      //console.log('EmpaquesQ: ', inputsQuantity)
      // Lote actualizado
      const batch = {
        id: this.batchId,
        name: this.batchForm.get('name')?.value,
        category: this.batchForm.get('category')?.value,
        quantity: this.batchForm.get('quantity')?.value,
        unit: this.batchForm.get('unit')?.value,
        expiration: this.batchForm.get('expirationDate')?.value,
        creationDate: this.ad,
        lastModificationDate: this.lmd,
        description: this.batchForm.get('description')?.value || '',
        batchIngredients: updatedIngredients,
        batchIngredientsQuantity: ingredientsQuantity,
        batchPackagingElements: updatedInputs,
        batchPackagingElementsQuantity: inputsQuantity,
        price: this.batchForm.get('price')?.value,
      };
      console.log('Batch: ', batch);

      console.log('BatchJS: ', JSON.stringify(batch));

      this.BatchesService.updateBatch(batch).subscribe(
        (data) => {
          console.log('Lote creado correctamente.');

          // Promesas para actualizar ingredientes seleccionados
          const ingredientUpdatePromises = this.updateSelectedIngredients(
            selectedIngredientControls
          );

          // Promesas para actualizar empaques seleccionados
          const inputUpdatePromises = this.updateSelectedInputs(
            selectedInputControls
          );

          // Promesas para restaurar stock de ingredientes deseleccionados
          const ingredientRestorePromises = this.restoreDeselectedIngredients(
            selectedIngredientControls
          );

          // Promesas para restaurar stock de empaques deseleccionados
          const inputRestorePromises = this.restoreDeselectedInputs(
            selectedInputControls
          );

          // Ejecutar todas las promesas
          Promise.all([
            ...ingredientUpdatePromises,
            ...inputUpdatePromises,
            ...ingredientRestorePromises,
            ...inputRestorePromises,
          ])
            .then(() => {
              console.log('Todos los materiales actualizados correctamente.');
            })
            .catch((error) => {
              console.error('Error al actualizar materiales: ', error);
            });

          this.router.navigate(['/production']);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.log('Formulario de lote inválido.');
    }
  }

  updateSelectedIngredients(selectedIngredientControls: any[]): Promise<any>[] {
    return selectedIngredientControls.map((field: any) => {
      const ingredient = this.avaibleRawMaterials.find(
        (material) => material.id === field.id
      );
      console.log('Selected Ingredient: ', field);
      console.log('Ingredient base: ', ingredient);
      const originalQuantity = Number(
        this.preSelectedIngredients[field.id] || 0
      );
      console.log('Original Quantity: ', originalQuantity);
      if (ingredient) {
        const newQuantity = Number(field.quantity);
        console.log('New Quantity: ', newQuantity);
        if (newQuantity !== originalQuantity) {
          const difference = newQuantity - originalQuantity;
          console.log('Difference: ', difference);
          if (difference > 0) {
            // Restar diferencia al stock
            ingredient.quantity -= difference;
          } else {
            // Sumar diferencia al stock
            ingredient.quantity += Math.abs(difference);
          }
          console.log('New Ingredient Quantity: ', ingredient.quantity);
          return this.RawMaterialsService.updateRawMaterial(
            ingredient
          ).toPromise();
        }
      }
      return Promise.resolve(null);
    });
  }

  updateSelectedInputs(selectedInputControls: any[]): Promise<any>[] {
    return selectedInputControls.map((field: any) => {
      const input = this.avaibleInputs.find(
        (material) => material.id === field.id
      );
      const originalQuantity = Number(this.preSelectedInputs[field.id] || 0);

      if (input) {
        const newQuantity = Number(field.quantity);

        if (newQuantity !== originalQuantity) {
          const difference = newQuantity - originalQuantity;

          if (difference > 0) {
            // Restar diferencia al stock
            input.quantity -= difference;
          } else {
            // Sumar diferencia al stock
            input.quantity += Math.abs(difference);
          }

          return this.InputsService.updateInput(input).toPromise();
        }
      }
      return Promise.resolve(null);
    });
  }

  restoreDeselectedIngredients(
    selectedIngredientControls: any[]
  ): Promise<any>[] {
    return this.avaibleRawMaterials
      .filter(
        (material: any) =>
          !selectedIngredientControls.find(
            (control: any) => control.id === material.id
          )
      )
      .map((deselected: any) => {
        const originalQuantity =
          this.preSelectedIngredients[deselected.id] || 0;
        deselected.quantity += originalQuantity;
        return this.RawMaterialsService.updateRawMaterial(
          deselected
        ).toPromise();
      });
  }

  restoreDeselectedInputs(selectedInputControls: any[]): Promise<any>[] {
    return this.avaibleInputs
      .filter(
        (material: any) =>
          !selectedInputControls.find(
            (control: any) => control.id === material.id
          )
      )
      .map((deselected: any) => {
        const originalQuantity = this.preSelectedInputs[deselected.id] || 0;
        deselected.quantity += originalQuantity;
        return this.InputsService.updateInput(deselected).toPromise();
      });
  }

  openAddCategoryModal() {
    this.addCategoryModal = true;
  }

  closeAddCategoryModal() {
    this.addCategoryModal = false;
  }

  get selectedIngredients(): FormArray {
    return this.batchForm.get('ingredients') as FormArray;
  }

  private addRawMaterialControls(): void {
    while (this.selectedIngredients.length) {
      this.selectedIngredients.removeAt(0);
    } // Asegúrate de eliminar controles antiguos

    this.avaibleRawMaterials.forEach((material) => {
      this.selectedIngredients.push(
        this.formbuild.group({
          id: [material.id],
          name: [material.name],
          selected: [false],
          quantity: [
            null,
            [Validators.min(1), Validators.max(material.availableQuantity)],
          ],
        })
      );
    });
  }

  openRMFilters() {}

  get selectedInputs(): FormArray {
    return this.batchForm.get('packages') as FormArray;
  }

  private addInputsControls(): void {
    while (this.selectedInputs.length) {
      this.selectedInputs.removeAt(0);
    }
    this.avaibleInputs.forEach((material) => {
      this.selectedInputs.push(
        this.formbuild.group({
          id: [material.id],
          name: [material.name],
          selected: [false],
          quantity: [
            null,
            [Validators.min(1), Validators.max(material.availableQuantity)],
          ],
        })
      );
    });
  }

  openIFilters() {}

  private getCategories(): void {
    this.CategoriesService.getCategories().subscribe(
      (data) => {
        this.categories = data.sort((a, b) => a.localeCompare(b));
      },
      (error) => {
        console.error(error);
      }
    );
  }

  private getBatch(): void {
    this.batchId = +(this.activeRoute.snapshot.paramMap.get('id') ?? 0);
    this.BatchesService.getBatchById(this.batchId).subscribe(
      (res) => {
        console.log(res);
        this.batch = res as TableData;
        console.log(this.batch);
        // Patch general batch data
        this.batchForm.patchValue({
          name: res.name,
          category: res.category,
          quantity: res.quantity,
          unit: res.unit,
          expirationDate: formatDate(res.expiration),
          description: res.description,
          price: res.price,
        });
        this.ad = res.creationDate;
        this.lmd = res.lastModificationDate;

        // Convert quantities from string to array
        /*const ingredientQuantities = res.batchIngredientsQuantity
          .split(',')
          .map((q: string) => parseFloat(q));
        const packagingQuantities = res.batchPackagingElementsQuantity
          .split(',')
          .map((q: string) => parseFloat(q));
        */
        const ingredientQuantities = res.batchIngredientsQuantity
          .split(';')
          .reduce((acc: { [key: string]: number }, pair: string) => {
            const [id, quantity] = pair.split(','); // separate id and quantity
            acc[id] = parseFloat(quantity); // store id as key and quantity as value
            return acc;
          }, {});

        const packagingQuantities = res.batchPackagingElementsQuantity
          .split(';')
          .reduce((acc: { [key: string]: number }, pair: string) => {
            const [id, quantity] = pair.split(','); // separate id and quantity
            acc[id] = parseFloat(quantity); // store id as key and quantity as value
            return acc;
          }, {});

        this.totalCost = res.price;
        // Patch ingredients FormArray with batchIngredients and ingredientQuantities
        this.getRawMaterials().then(() => {
          const ingredientsArray = this.batchForm.get(
            'ingredients'
          ) as FormArray;
          /*res.batchIngredients.forEach((ingredient: any, index: number) => {
            const control = ingredientsArray.controls.find(
              (ctrl) => ctrl.value.id === ingredient.id
            );
            if (control) {
              control.patchValue({
                selected: true,
                quantity:
                  ingredientQuantities[index] !== undefined
                    ? ingredientQuantities[index]
                    : null,
              });
              this.preSelectedIngredients[ingredient.id] =
                ingredientQuantities[index];
            }
          }*/
          res.batchIngredients.forEach((ingredient: any) =>
          {
            const control = ingredientsArray.controls.find((ctrl) => ctrl.value.id === ingredient.id);
            if(control){
              control.patchValue({
                selected: true,
                quantity: ingredientQuantities[ingredient.id] !== undefined ? ingredientQuantities[ingredient.id] : null,
              });
              this.preSelectedIngredients[ingredient.id] = ingredientQuantities[ingredient.id];
            }
          });
        });

        // Patch packages FormArray with batchPackagingElements and packagingQuantities
        this.getInputs().then(() => {
          const packagesArray = this.batchForm.get('packages') as FormArray;
          /*res.batchPackagingElements.forEach(
            (packaging: any, index: number) => {
              const control = packagesArray.controls.find(
                (ctrl) => ctrl.value.id === packaging.id
              );
              if (control) {
                control.patchValue({
                  selected: true,
                  quantity:
                    packagingQuantities[index] !== undefined
                      ? packagingQuantities[index]
                      : null,
                });
                this.preSelectedInputs[packaging.id] =
                  packagingQuantities[index];
              }
            }*/
          res.batchPackagingElements.forEach((packaging: any) => {
            const control = packagesArray.controls.find((ctrl) => ctrl.value.id === packaging.id);
            if(control){
              control.patchValue({
                selected: true,
                quantity: packagingQuantities[packaging.id] !== undefined ? packagingQuantities[packaging.id] : null,
              });
              this.preSelectedInputs[packaging.id] = packagingQuantities[packaging.id];
            }
          }
          );
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  private getRawMaterials(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.RawMaterialsService.getRawMaterials().subscribe(
        (data: any[]) => {
          this.avaibleRawMaterials = data.sort((a, b) => a.name.localeCompare(b.name));
          this.addRawMaterialControls();
          resolve();
        },
        (error) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  private getInputs(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.InputsService.getInputs().subscribe(
        (data: any[]) => {
          this.avaibleInputs = data.sort((a, b) => a.name.localeCompare(b.name));
          this.addInputsControls();
          resolve();
        },
        (error) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  /*
  private updateElement(
    element: any,
    actualQuantity: any,
    oldQuantity: any
  ): any {
    if (oldQuantity === actualQuantity) {
      return element.quantity;
    }
    if (actualQuantity > oldQuantity) {
      return (element.quantity -= actualQuantity - oldQuantity);
    } else {
      if (actualQuantity < oldQuantity) {
        return (element.quantity += oldQuantity - actualQuantity);
      }
    }
  }
    **/
  private updateElement(
    stock: any,
    actualQuantity: any,
    oldQuantity: any
  ): number {
    if (oldQuantity === actualQuantity) {
      return stock.quantity;
    } else if (actualQuantity > oldQuantity) {
      return stock.quantity - (actualQuantity - oldQuantity);
    } else {
      return stock.quantity + (oldQuantity - actualQuantity);
    }
  }

  calculateCost(): void {
    this.totalCost = 0; // Reinicia el costo total
    this.batchForm.get('ingredients')?.value.forEach((ingredient: any) => {
      console.log(ingredient);
      if (ingredient.selected) {
        const rawMaterial = this.avaibleRawMaterials.find((material) => {
          console.log(material.id, ingredient.id);
          console.log(material.id === ingredient.id);
          material.id === ingredient.id;
          return material.id === ingredient.id;
        });
        console.log(rawMaterial, rawMaterial.cost);
        if (rawMaterial) {
          this.totalCost += ingredient.quantity * rawMaterial.cost;
        }
      }
    });
    // Calcular el costo de los empaques seleccionados
    this.batchForm.get('packages')?.value.forEach((input: any) => {
      if (input.selected) {
        const packagingMaterial = this.avaibleInputs.find((material) => {
          material.id === input.id;
          return material.id === input.id;
        });
        if (packagingMaterial) {
          this.totalCost += input.quantity * packagingMaterial.cost;
        }
      }
    });

    this.updatePriceValidator();
  }

  updatePriceValidator(): void {
    const priceControl = this.batchForm.get('price');
    if (priceControl) {
      // Actualiza los validadores del control con el nuevo valor de totalCost
      priceControl.setValidators([
        Validators.required,
        Validators.min(this.totalCost),
      ]);

      // Recalcula la validez del control para aplicar los nuevos validadores
      priceControl.updateValueAndValidity();
    }
  }
}

interface element {
  [key: number]: number;
}
