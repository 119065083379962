import { Component,  OnInit } from '@angular/core';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';
import { TableComponent } from '../../shared/components/table/table.component';
import { SearchBarComponent } from '../../shared/components/search-bar/search-bar.component';
import { TableData } from '../../core/interfaces/table-data';
import { AddClientComponent } from './add-client/add-client.component';
import { ClientComponent} from './client/client.component';
import { CommonModule } from '@angular/common';
import { ClientsService } from '../../core/services/api/clients.service';
import { filterTableData } from '../../core/utils/table-data.utils';

@Component({
  selector: 'app-clients',
  standalone: true,
  imports: [NavbarComponent, TableComponent, SearchBarComponent, AddClientComponent, ClientComponent, CommonModule],
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.scss'
})
export class ClientsComponent implements OnInit {

  clients: TableData[] = [];
  backUpClients: TableData[] = [];
  //Modales
  addClientModal: boolean = false;
  openClientModal: boolean = false;
  selectedClient: any;
  exportColumns: string[] = ['description','cuit'];

  constructor(private ClientsService: ClientsService) { }


  ngOnInit(): void {
    this.getClients();
  }

  openAddClientModal() {
    this.addClientModal = true;
  }
  closeAddClientModal() {
    this.getClients();
    this.addClientModal = false;
  }

  onRowClicked(user: TableData) {
    this.selectedClient = user;
    this.openClientModal = true;
  }

  closeClientModal() {
    this.getClients();
    this.openClientModal = false;
  }

  handleSearch(value: string) {
    this.clients = this.backUpClients;
    this.clients = filterTableData(this.clients, this.exportColumns, value);
  }

  private getClients() {
    this.ClientsService.getClients().subscribe((data: any) => {
      this.clients = data;
      this.backUpClients = data;
    });
  }
  
}
