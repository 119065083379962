import { Routes } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { HomeComponent } from './views/home/home.component';
import { InventoryComponent } from './views/inventory/inventory.component';
import { ProductionComponent } from './views/production/production.component';
import { RecipesComponent } from './views/recipes/recipes.component';
import { HistoryComponent } from './views/history/history.component';
import { UsersComponent } from './views/users/users.component';
import { ProvidersComponent } from './views/providers/providers.component';
import { ClientsComponent } from './views/clients/clients.component';
import { AddRecipeComponent } from './views/recipes/add-recipe/add-recipe.component';
import { ModifyRecipeComponent } from './views/recipes/modify-recipe/modify-recipe.component';
import { ViewRecipeComponent } from './views/recipes/view-recipe/view-recipe.component';
import { AddBatchComponent } from './views/production/add-batch/add-batch.component';
import { ViewBatchComponent } from './views/production/view-batch/view-batch.component';

import { AuthGuard } from './core/guards/auth.guard';
import { ModifyBatchComponent } from './views/production/modify-batch/modify-batch.component';
import { allReadyLogGuard } from './core/guards/all-ready-log.guard';
import { ProfileComponent } from './views/profile/profile.component';


export const routes: Routes = [
    { path: '', component: LoginComponent, canActivate: [allReadyLogGuard], pathMatch: 'full' },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
    { path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard]},
    { path: 'production', component: ProductionComponent, canActivate: [AuthGuard]},
    { path: 'production/add', component: AddBatchComponent, canActivate: [AuthGuard]},
    { path: 'production/:id', component: ViewBatchComponent, canActivate: [AuthGuard]},
    { path: 'production/:id/modify', component: ModifyBatchComponent, canActivate: [AuthGuard]},
    { path: 'recipes', component: RecipesComponent, canActivate: [AuthGuard]},
    { path: 'recipes/add', component: AddRecipeComponent, canActivate: [AuthGuard]},
    { path: 'recipes/:id', component: ViewRecipeComponent, canActivate: [AuthGuard]},
    { path: 'recipes/:id/modify', component: ModifyRecipeComponent, canActivate: [AuthGuard]},
    { path: 'history', component: HistoryComponent, canActivate: [AuthGuard]},
    { path: 'users', component: UsersComponent, canActivate: [AuthGuard]},
    { path: 'providers', component: ProvidersComponent, canActivate: [AuthGuard]},
    { path: 'clients', component: ClientsComponent, canActivate: [AuthGuard]},
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
    { path: '**', redirectTo: '' }
];
