<app-navbar></app-navbar>
<div class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100">
    
    <div class="col-flex justify-content-center align-items-center">
        <div class="row header-row">
            <div class="col">
                <h1 class="view-title">Clientes</h1>
            </div>
            <div class="col">
               <app-search-bar (search)="handleSearch($event)"></app-search-bar>
            </div>
            <div class="col d-flex justify-content-center">
                <button class="btn btn-custom" (click)="openAddClientModal()">AGREGAR CLIENTE</button>
            </div>
        </div>

        <app-table [data]="clients" (rowClicked)="onRowClicked($event)"></app-table>

    </div>
</div>

<app-add-client *ngIf="addClientModal"  (closeModalEvent)="closeAddClientModal()"></app-add-client>
<app-client *ngIf="openClientModal" [clientInput]="selectedClient" (closeClientModalEvent)="closeClientModal()"></app-client>