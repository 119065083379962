import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError} from 'rxjs';
import { handleError } from '../../utils/api-error-handler.utils';
import { environment } from '../../../../evironments/environment';
@Injectable({
  providedIn: 'root'
})
export class InputsService {

  private apiUrl = environment.apiUrl + '/api/Inputs';

  constructor(private http: HttpClient) {}

  getInputs(): Observable<any> {
    return this.http.get<any>(this.apiUrl).pipe(catchError(handleError));
  }

  getInputById(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`).pipe(catchError(handleError));
  }
  createInput(input: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, input).pipe(catchError(handleError));
  }

  updateInput(input: any): Observable<any> {
    return this.http.put<any>(this.apiUrl, input).pipe(catchError(handleError));
  }

  deleteInput(id: number): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${id}`).pipe(catchError(handleError));
  }


  //CUSTOM METHODS

  getInputsTypes(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/getTypes`).pipe(catchError(handleError));
  }

}
