import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../../../shared/components/navbar/navbar.component';
import { CommonModule } from '@angular/common';
import {
  ReactiveFormsModule,
  FormBuilder,
  Validators,
  FormGroup,
  FormArray,
} from '@angular/forms';
import { Units } from '../../../shared/enums/units.enum';
import { CategoriesService } from '../../../core/services/enums/categories.service';
import { RawMaterialsService } from '../../../core/services/api/rawmaterials.service';
import { InputsService } from '../../../core/services/api/inputs.service';
import { EditorModule } from 'primeng/editor';
import { atLeastOneCheckedValidator } from '../../../core/utils/validators';
import { BatchesService } from '../../../core/services/api/batches.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-batch',
  standalone: true,
  imports: [CommonModule, NavbarComponent, ReactiveFormsModule, EditorModule],
  templateUrl: './add-batch.component.html',
  styleUrl: './add-batch.component.scss',
})
export class AddBatchComponent implements OnInit {
  batchForm: FormGroup;
  addCategoryForm: FormGroup;
  categories: string[] = [];
  units: string[] = [];
  minDate: string = '';
  addCategoryModal: boolean = false;

  avaibleRawMaterials: any[] = [];
  avaibleInputs: any[] = [];
  totalCost = 0;
  constructor(
    private formbuild: FormBuilder,
    private CategoriesService: CategoriesService,
    private RawMaterialsService: RawMaterialsService,
    private InputsService: InputsService,
    private BatchesService: BatchesService,
    private router: Router
  ) {
    this.batchForm = this.formbuild.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      category: ['', [Validators.required]],
      quantity: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      unit: ['', [Validators.required]],
      expirationDate: [''],
      description: [''],
      price: [0, [Validators.required, Validators.min(1)]],
      ingredients: this.formbuild.array([], {
        validators: atLeastOneCheckedValidator(),
      }),
      packages: this.formbuild.array([], {
        validators: atLeastOneCheckedValidator(),
      }),
    });
    this.addCategoryForm = this.formbuild.group({
      newCategory: [
        '',
        [Validators.required, Validators.pattern('[a-zA-Z ]*')],
      ],
    });
  }

  ngOnInit(): void {
    this.CategoriesService.chargeCategories();
    this.getCategories();
    this.units = Object.values(Units);
    const now = new Date();
    this.minDate = now.toISOString().slice(0, 16);
    this.getRawMaterials();
    this.getInputs();
  }

  addCategory(): void {
    if (this.addCategoryForm.valid) {
      this.categories.push(this.addCategoryForm.value.newCategory);
      this.addCategoryForm.reset();
      this.addCategoryModal = false;
    } else {
      console.log('Formulario de categoria inválido.');
    }
  }

  onSubmit(): void {
    console.log(this.batchForm);
    if (this.batchForm.valid) {
      const selectedIngredientControls =
        this.batchForm.get('ingredients')?.value;
      const selectedIngredientIds = selectedIngredientControls
        .filter((ingredient: any) => ingredient.selected)
        .map((ingredient: any) => ingredient.id);

      const ingredients = this.avaibleRawMaterials.filter((material: any) =>
        selectedIngredientIds.includes(material.id)
      );

      const selectedInputControls = this.batchForm.get('packages')?.value;
      const selectedInputIds = selectedInputControls
        .filter((input: any) => input.selected)
        .map((input: any) => input.id);

      const inputs = this.avaibleInputs.filter((material: any) =>
        selectedInputIds.includes(material.id)
      );

      const futureDate = new Date();
      futureDate.setFullYear(futureDate.getFullYear() + 70);

      const batch = {
        name: this.batchForm.get('name')?.value,
        category: this.batchForm.get('category')?.value,
        quantity: this.batchForm.get('quantity')?.value,
        unit: this.batchForm.get('unit')?.value,
        expiration: this.batchForm.get('expirationDate')?.value || futureDate,
        description: this.batchForm.get('description')?.value || '',
        batchIngredients: ingredients,
        batchIngredientsQuantity: selectedIngredientControls
          .filter((ingredient: any) => ingredient.selected)
          .map((ingredient: any) => `${ingredient.id},${ingredient.quantity}`)
          .join(';'),
        batchPackagingElements: inputs,
        batchPackagingElementsQuantity: selectedInputControls
          .filter((input: any) => input.selected)
          .map((input: any) => `${input.id},${input.quantity}`)
          .join(';'),
        price: this.batchForm.get('price')?.value,
      };

      console.log('Batch: ', JSON.stringify(batch));

      this.BatchesService.createBatch(batch).subscribe(
        (data) => {
          console.log('Lote creado correctamente.');

          // Actualizar solo los ingredientes seleccionados
          const ingredientUpdatePromises = ingredients
            .map((ingredient: any) => {
              const selectedControl = selectedIngredientControls.find(
                (control: any) => control.id === ingredient.id
              );
              if (selectedControl) {
                ingredient.quantity -= selectedControl.quantity;
                console.log('Ingredient post update: ', ingredient);
                return this.RawMaterialsService.updateRawMaterial(
                  ingredient
                ).toPromise();
              }
              return null; // Retornar null si no hay ingrediente seleccionado
            })
            .filter(Boolean); // Filtrar los nulls

          // Actualizar solo los empaques seleccionados
          const inputUpdatePromises = inputs
            .map((input: any) => {
              const selectedControl = selectedInputControls.find(
                (control: any) => control.id === input.id
              );
              if (selectedControl) {
                input.quantity -= selectedControl.quantity;
                console.log('Input post update: ', input);
                return this.InputsService.updateInput(input).toPromise();
              }
              return null; // Retornar null si no hay empaque seleccionado
            })
            .filter(Boolean); // Filtrar los nulls

          Promise.all([...ingredientUpdatePromises, ...inputUpdatePromises])
            .then(() => {
              console.log('Todos los materiales actualizados correctamente.');
            })
            .catch((error) => {
              console.error('Error al actualizar materiales: ', error);
            });

          this.router.navigate(['/production']);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.log('Formulario de lote inválido.');
    }
  }

  openAddCategoryModal() {
    this.addCategoryModal = true;
  }

  closeAddCategoryModal() {
    this.addCategoryModal = false;
  }

  get selectedIngredients(): FormArray {
    return this.batchForm.get('ingredients') as FormArray;
  }

  private addRawMaterialControls(): void {
    this.avaibleRawMaterials.forEach((material) => {
      this.selectedIngredients.push(
        this.formbuild.group({
          id: [material.id],
          name: [material.name],
          selected: [false],
          quantity: [
            null,
            [Validators.min(1), Validators.max(material.availableQuantity)],
          ],
        })
      );
    });
  }
  private getRawMaterials(): any {
    this.RawMaterialsService.getRawMaterials().subscribe(
      (data: any[]) => {
        this.avaibleRawMaterials = data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        this.addRawMaterialControls();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  openRMFilters() {}

  get selectedInputs(): FormArray {
    return this.batchForm.get('packages') as FormArray;
  }

  private addInputsControls(): void {
    this.avaibleInputs.forEach((material) => {
      this.selectedInputs.push(
        this.formbuild.group({
          id: [material.id],
          name: [material.name],
          selected: [false],
          quantity: [
            null,
            [Validators.min(1), Validators.max(material.availableQuantity)],
          ],
        })
      );
    });
  }

  private getInputs(): any {
    this.InputsService.getInputs().subscribe(
      (data: any[]) => {
        this.avaibleInputs = data.sort((a, b) => a.name.localeCompare(b.name));
        this.addInputsControls();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  openIFilters() {}

  private getCategories(): void {
    this.CategoriesService.getCategories().subscribe(
      (data) => {
        this.categories = data.sort((a, b) => a.localeCompare(b));
      },
      (error) => {
        console.error(error);
      }
    );
  }

  calculateCost(): void {
    this.totalCost = 0; // Reinicia el costo total
    this.batchForm.get('ingredients')?.value.forEach((ingredient: any) => {
      console.log(ingredient);
      if (ingredient.selected) {
        const rawMaterial = this.avaibleRawMaterials.find((material) => {
          console.log(material.id, ingredient.id);
          console.log(material.id === ingredient.id);
          material.id === ingredient.id;
          return material.id === ingredient.id;
        });
        console.log(rawMaterial, rawMaterial.cost);
        if (rawMaterial) {
          this.totalCost += ingredient.quantity * rawMaterial.cost;
        }
      }
    });
    // Calcular el costo de los empaques seleccionados
    this.batchForm.get('packages')?.value.forEach((input: any) => {
      if (input.selected) {
        const packagingMaterial = this.avaibleInputs.find((material) => {
          material.id === input.id;
          return material.id === input.id;
        });
        if (packagingMaterial) {
          this.totalCost += input.quantity * packagingMaterial.cost;
        }
      }
    });

    this.updatePriceValidator();
  }

  updatePriceValidator(): void {
    const priceControl = this.batchForm.get('price');
    if (priceControl) {
      // Actualiza los validadores del control con el nuevo valor de totalCost
      priceControl.setValidators([
        Validators.required,
        Validators.min(this.totalCost),
      ]);

      // Recalcula la validez del control para aplicar los nuevos validadores
      priceControl.updateValueAndValidity();
    }
  }
}
