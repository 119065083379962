import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError} from 'rxjs';
import { handleError } from '../../utils/api-error-handler.utils';
import { environment } from '../../../../evironments/environment';
@Injectable({
  providedIn: 'root'
})
export class BatchesService {

  private apiUrl = environment.apiUrl + '/api/batches';

  constructor(private http: HttpClient) {}

  getBatches(): Observable<any> {
    return this.http.get<any>(this.apiUrl).pipe(catchError(handleError));
  }

  getBatchById(id: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getBy/${id}`).pipe(catchError(handleError));
  }
  createBatch(batch: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, batch).pipe(catchError(handleError));
  }

  updateBatch(batch: any): Observable<any> {
    return this.http.put<any>(this.apiUrl, batch).pipe(catchError(handleError));
  }

  deleteBatch(id: any): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${id}`).pipe(catchError(handleError));
  }

  getCategories(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/getCategories`).pipe(catchError(handleError));
  }
}
