import {
  Component,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
} from '@angular/core';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Units } from '../../../shared/enums/units.enum';
import {
  decimalNumberValidator,
  conditionalRequiredValidator,
} from '../../../core/utils/validators';
import { TableData } from '../../../core/interfaces/table-data';
import { InputsService } from '../../../core/services/api/inputs.service';
import { InventoryService } from '../../../core/services/enums/inventory.service';
import { ProvidersService } from '../../../core/services/api/providers.service';
import { DeleteModalComponent } from '../../../shared/components/delete-modal/delete-modal.component';
import { formatDate, formatDateString } from '../../../core/utils/mapping.utils';
@Component({
  selector: 'app-inputs',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, DeleteModalComponent],
  templateUrl: './inputs.component.html',
  styleUrl: './inputs.component.scss',
})
export class InputsComponent {
  @Input() productInput: TableData = {};
  @Output() close = new EventEmitter<void>();
  productForm: FormGroup;
  addTypeForm: FormGroup;

  inventoryTypes: string[] = [];
  inputsTypes: string[] = [];
  units: string[] = [];
  addTypeModal: boolean = false;

  providers: TableData[] = [];

  avaibleFreezed: boolean = false;
  isToggled: boolean = false;
  confirmDeleteModal: boolean = false;

  constructor(
    private InputsService: InputsService,
    private formBuilder: FormBuilder,
    private InventoryService: InventoryService,
    private ProvidersService: ProvidersService
  ) {
    this.productForm = this.formBuilder.group(
      {
        name: [{ value: '', disabled: !this.isToggled }, [Validators.required]],
        type: [
          { value: '', disabled: !this.isToggled },
          [Validators.required, Validators.pattern('^[a-zA-Z ]*$')],
        ],
        quantity: [
          { value: '', disabled: !this.isToggled },
          [Validators.required],
        ],
        unit: [{ value: '', disabled: !this.isToggled }, [Validators.required]],
        cost: [
          { value: '', disabled: !this.isToggled },
          [Validators.required, decimalNumberValidator],
        ],
        description: [
          { value: '', disabled: !this.isToggled },
          [Validators.pattern(/^[a-zA-Z0-9\s]+$/)],
        ],
        provider: [
          { value: '', disabled: !this.isToggled },
          [Validators.required],
        ],
      },
      {
        validators: conditionalRequiredValidator('expirationDate', 'freezed'),
      }
    );
    this.addTypeForm = this.formBuilder.group({
      newType: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
    });
  }

  ngOnInit() {
    this.InventoryService.chargeInputsTypes();
    this.getProviders();
    this.loadProductTypes();
    this.InputsService.getInputsTypes().subscribe(
      (types) => (this.inputsTypes = types)
    );
    this.units = Object.values(Units);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['productInput'] && changes['productInput'].currentValue) {
      //console.log(this.transformProductInput(this.productInput));
      this.productForm.patchValue(
        this.transformProductInput(this.productInput)
      );
    }
  }

  toggleEditMode() {
    this.isToggled = !this.isToggled;
    if (this.isToggled) {
      this.productForm.enable(); // Habilita todos los controles del formulario
    } else {
      this.productForm.disable(); // Deshabilita todos los controles del formulario
    }
  }

  closeModal() {
    this.close.emit();
  }

  confirmDelete() {
    this.confirmDeleteModal = true;
  }

  closeDeleteModal() {
    this.confirmDeleteModal = false;
  }

  deleteProduct() {
    //Call backend
    /*console.log(
      'Eliminando producto',
      this.productForm.get('freezed')?.enabled,
      !this.inputsTypes.includes(this.productForm.get('type')?.value)
    );*/
    this.InputsService.deleteInput(this.productInput['id']).subscribe(
      () => {
        //console.log('Insumo eliminado con éxito');
        this.closeModal();
      },
      (error) => {
        console.error('Error al eliminar el insumo', error);
      }
    );
  }

  //Type and Providers
  openAddTypeModal() {
    this.addTypeModal = true;
  }

  closeAddTypeModal() {
    this.addTypeModal = false;
  }

  addType() {
    if (this.addTypeForm.valid) {
      const newType = this.addTypeForm.value.newType;
      this.InventoryService.addInputsType(newType);
      this.InventoryService.getInputsTypes().subscribe((types) => {
        this.inventoryTypes = types; // Actualiza la lista en el componente
        this.closeAddTypeModal();
      });
    } else {
      console.log('Formulario de tipo inválido.');
    }
  }

  onSubmit() {
    if (this.productForm.valid) {
      const providerId = this.productForm.get('provider')?.value;
      const selectedProvider = this.providers.find((provider) => {
        return Number(provider['id']) === Number(providerId);
      });
      //console.log('form', this.productForm);
      const input: TableData = {
        id: this.productInput['id'],
        name: this.productForm.get('name')?.value,
        type: this.productForm.get('type')?.value,
        quantity: this.productForm.get('quantity')?.value,
        unit: this.productForm.get('unit')?.value,
        cost: this.productForm.get('cost')?.value,
        description: this.productForm.get('description')?.value || '',
        provider: selectedProvider || {},
        admissionDate: formatDateString(this.productInput['admissionDate']),
        lastModificationDate: formatDateString(this.productInput['lastModificationDate'])
      }
      this.InputsService.updateInput(input).subscribe(
        () => {
          //console.log('Insumo modificado con éxito');
          this.closeModal();
        },
        (error) => {
          console.error('Error al crear el insumo', error);
        }
      );
    } else {
      console.error('Formulario inválido');
    }
  }

  private transformProductInput(data: TableData): any {
    return {
      name: data['name'] ?? '',
      type: data['type'] ?? '',
      quantity: data['quantity'] ? String(data['quantity']) : '',
      unit: data['unit'] ?? '',
      cost: data['cost'] ? String(data['cost']) : '',
      freezed: data['freeze'] ?? false,
      expirationDate: formatDate(data['expirationDate']),
      provider: data['provider'].id ?? '',
    };
  }

  private getProviders() {
    this.ProvidersService.getProviders().subscribe((res: any[]) => {
      this.providers = res.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  private loadProductTypes(): void {
    this.InventoryService.getInputsTypes().subscribe((types) => {
      this.inventoryTypes = types.sort((a, b) => a.localeCompare(b));
    });
  }
}
