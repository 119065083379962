import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map} from 'rxjs';
import { handleError } from '../../utils/api-error-handler.utils';
import { mapToTableData } from '../../utils/mapping.utils';
import { TableData } from '../../interfaces/table-data';
import { environment } from '../../../../evironments/environment';
@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private apiUrl = environment.apiUrl + '/api/events';

  constructor(private http: HttpClient) {}

  getEvents(): Observable<TableData[]> {
    return this.http.get<any[]>(this.apiUrl).pipe(map(data => mapToTableData(data)),catchError(handleError));
  }

  getEventById(id: any): Observable<TableData> {
    return this.http.get<any>(`${this.apiUrl}/${id}`).pipe(map(data => mapToTableData(data)),catchError(handleError));
  }

  createEvent(event: any): Observable<any> {
    return this.http.post<any>(this.apiUrl,event).pipe(catchError(handleError));
  }
}
