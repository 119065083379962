import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { ReactiveFormsModule,FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Units } from '../../../shared/enums/units.enum';
import { decimalNumberValidator } from '../../../core/utils/validators';
import { TableData } from '../../../core/interfaces/table-data';
import { RawMaterialsService } from '../../../core/services/api/rawmaterials.service';
import { ProvidersService } from '../../../core/services/api/providers.service';
import { InventoryService } from '../../../core/services/enums/inventory.service';

@Component({
  selector: 'app-add-raw-material',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './add-raw-material.component.html',
  styleUrl: './add-raw-material.component.scss'
})
export class AddRawMaterialComponent {
 
  @Output() close = new EventEmitter<void>();
  productForm: FormGroup;
  addTypeForm: FormGroup;
  
  providers: TableData[] = [];
  units: string[] = [];
  inventoryTypes: string[] = [];
  addTypeModal: boolean = false;
  isRawMaterial: boolean = false;
  avaibleFreezed: boolean = false;

  constructor(private RawMaterialsServices: RawMaterialsService, 
    private formBuilder: FormBuilder , private InventoryService: InventoryService,
    private providersService: ProvidersService) {
    this.productForm = this.formBuilder.group(
      {
        name: ['', [Validators.required]],
        type: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
        quantity: ['', [Validators.required, decimalNumberValidator]],
        unit: ['', [Validators.required]],
        cost: ['', [Validators.required, decimalNumberValidator]],
        description: ['', [Validators.pattern(/^[a-zA-Z0-9\s]+$/) ]],
        freezed: [false],
        expirationDate: [{value: '', disabled: true}, [Validators.required]],
        provider: ['', [Validators.required]],
      }
    )
    this.addTypeForm = this.formBuilder.group(
      {
        newType: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      }
    )

  }

  ngOnInit() {
    this.InventoryService.chargeRawMaterialTypes();
    this.getProviders();
    this.loadProductTypes();
    this.units = Object.values(Units);
    // Suscribirse a los cambios en el campo 'freezed'
    this.productForm.get('freezed')?.valueChanges.subscribe(isFreezed => {
      this.updateExpirationDateState(isFreezed);
    });
  }

  closeModal() {
    this.close.emit();
  }

  openAddTypeModal() {
    this.addTypeModal = true;
  }

  closeAddTypeModal() {
    this.addTypeModal = false;
  }


  addType() {
    if (this.addTypeForm.valid) {
      const newType = this.addTypeForm.value.newType;
      this.InventoryService.addRawMaterialType(newType);
      this.closeAddTypeModal();
    } else {
      console.error('Formulario de tipo inválido.');
    }
  }

  onSubmit() {
    const providerId = this.productForm.get('provider')?.value;
    //const selectedProvider = this.providers.find(provider => provider['id'] === providerId);
    const selectedProvider = this.providers.find(provider => {
      return Number(provider['id']) === Number(providerId);
    });
    if (this.productForm.valid) {
      const rawMaterial: TableData = {
        name: this.productForm.get('name')?.value,
        type: this.productForm.get('type')?.value,
        quantity: this.productForm.get('quantity')?.value,
        unit: this.productForm.get('unit')?.value,
        cost: this.productForm.get('cost')?.value,
        descripction: this.productForm.get('description')?.value || '',
        freeze: this.productForm.get('freezed')?.value,
        expirationDate: this.productForm.get('expirationDate')?.value || '',
        provider: selectedProvider || {}
      }
      this.RawMaterialsServices.createRawMaterial(rawMaterial).subscribe(
        () => {
          this.closeModal();
        },
        (error) => {
          console.error('Error al crear la materia prima', error);
        });
    }
  }

  private getProviders() {
    this.providersService.getProviders().subscribe((res: any[]) => {
      this.providers = res.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  private loadProductTypes(): void {
    this.InventoryService.getInputsTypes().subscribe((types) => {
      this.inventoryTypes = types.sort((a, b) => a.localeCompare(b));
    });
  }

  private updateExpirationDateState(isFreezed: boolean): void {
    const expirationDateControl = this.productForm.get('expirationDate');
    if (isFreezed) {
      expirationDateControl?.enable();
    } else {
      expirationDateControl?.disable();
    }
  }

}
