<app-navbar></app-navbar>
<div class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100">
    
    <div class="col-flex justify-content-center align-items-center">
        <div class="row header-row">
            <div class="col">
                <h1 class="view-title">Poveedores</h1>
            </div>
            <div class="col">
               <app-search-bar (search)="handleSearch($event)"></app-search-bar>
            </div>
            <div class="col d-flex justify-content-center">
                <button class="btn btn-custom" (click)="openAddProviderModal()">AGREGAR PROVEEDOR</button>
            </div>
        </div>

        <app-table [data]="providers" [columnsExcluded]="exportColumns" (rowClicked)="onRowClicked($event)"></app-table>

    </div>
</div>

<app-add-provider *ngIf="addProviderModal"  (closeModalEvent)="closeAddProviderModal()"></app-add-provider>
<app-provider *ngIf="openProviderModal" [providerInput]="selectedProvider" (closeProviderModalEvent)="closeProviderModal()"></app-provider>