import { Component } from '@angular/core';
import { NavbarComponent } from '../../../shared/components/navbar/navbar.component';
import {
  ReactiveFormsModule,
  FormBuilder,
  Validators,
  FormGroup,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormArray } from '@angular/forms';
import { Units } from '../../../shared/enums/units.enum';
import { EditorModule } from 'primeng/editor';
import { TableData } from '../../../core/interfaces/table-data';
import { RecipesService } from '../../../core/services/api/recipes.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-recipe',
  standalone: true,
  imports: [NavbarComponent, CommonModule, ReactiveFormsModule, EditorModule],
  templateUrl: './add-recipe.component.html',
  styleUrl: './add-recipe.component.scss',
})
export class AddRecipeComponent {
  units: string[] = [];
  recipeForm: FormGroup;

  // Configuración del toolbar personalizada
  toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: 1 }, { header: 2 }],
    [{ align: [] }],
    ['link', 'clean'],
  ];

  constructor(private formBuilder: FormBuilder, private recipesService: RecipesService, private router: Router) {
    this.recipeForm = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      procedure: ['', [Validators.required]],
      ingredients: this.formBuilder.array([this.createIngredientFormGroup()]),
      inputs: this.formBuilder.array([this.createInputsFormGroup()]),
    });
  }

  ngOnInit() {
    this.units = Object.values(Units);
  }

  createIngredientFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      quantity: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      unit: ['', [Validators.required]],
    });
  }

  createInputsFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')
      ]],
      quantity: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      unit: ['', [Validators.required]],
    });
  }

  get ingredients(): FormArray {
    return this.recipeForm.get('ingredients') as FormArray;
  }

  addIngredient() {
    this.ingredients.push(this.createIngredientFormGroup());
  }

  removeIngredient(index: number) {
    this.ingredients.removeAt(index);
  }

  get inputs(): FormArray {
    return this.recipeForm.get('inputs') as FormArray;
  }
  addInput() {
    this.inputs.push(this.createIngredientFormGroup());
  }

  removeInput(index: number) {
    this.inputs.removeAt(index);
  }

  onSubmit() {
    console.log(this.recipeForm);
    if (this.recipeForm.valid) {
      const ingredientList = this.recipeForm
        .get('ingredients')
        ?.value.map((ingredient: any) => {
          return `${ingredient.name},${ingredient.quantity},${ingredient.unit}`;
        })
        .join(';'); // Concatenar con ';'
      console.log(ingredientList);

      // Obtener y transformar la lista de insumos
      const inputsList = this.recipeForm
        .get('inputs')
        ?.value.map((input: any) => {
          return `${input.name},${input.quantity},${input.unit}`;
        })
        .join(';'); // Concatenar con ';'
      console.log(inputsList);

      const recipe: TableData = {
        name: this.recipeForm.get('name')?.value,
        description: this.recipeForm.get('procedure')?.value,
        recipeIngredients: ingredientList,
        recipePackagingElements: inputsList,
      };
      this.recipesService.createRecipe(recipe).subscribe(
        (response) => {
          console.log(response);
          this.router.navigate(['/recipes']);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }
}
