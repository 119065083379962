import { Component, Input } from '@angular/core';
import { RecipeAbstraction } from '../../../core/interfaces/recipe-abstraction';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {
  @Input() recipe: RecipeAbstraction | undefined;
 
  constructor(private router: Router) { }
  goTo(){
this.router.navigate(['/recipes', this.recipe?.id])  }
}
