<div *ngIf="recipes.length<6">
  <p-carousel
  [value]="recipes"
  [circular]="true"
  [numVisible]="3"
  [numScroll]="2"
  [responsiveOptions]="responsiveOptions"
  [showIndicators]="false"
>
  <ng-template let-product pTemplate="item">
    <div class="col" >
      <div class="row col-margin">
        <div class="carousel-item" >  
          <div *ngFor="let recipe of recipes; let i = index">
            <app-card [recipe]="recipes[i]"></app-card>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-carousel>
</div>
<div *ngIf="recipes.length>=6" class="card">
  <p-carousel
    [value]="recipes"
    [circular]="true"
    [numVisible]="3"
    [numScroll]="1"
    [responsiveOptions]="responsiveOptions"
    [showIndicators]="false"
  >
    <ng-template let-product pTemplate="item">
      <div class="col" >
        <div class="row col-margin">
          <div class="carousel-item" >        
            <app-card [recipe]="recipes[getIndex()]"></app-card>
          </div>
        </div>
        <div class="row col-margin">
            <div class="carousel-item">             
              <app-card [recipe]="recipes[getIndex()]"></app-card>
            </div>
          </div>
      </div>
    </ng-template>
  </p-carousel>
</div>
