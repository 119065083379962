import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../../../shared/components/navbar/navbar.component';
import { ReactiveFormsModule, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormArray } from '@angular/forms';
import { Units } from '../../../shared/enums/units.enum';
import { ActivatedRoute } from '@angular/router';
import { RecipesService } from '../../../core/services/api/recipes.service';
import { EditorModule } from 'primeng/editor';
import { TableData } from '../../../core/interfaces/table-data';
import { Router } from '@angular/router';
@Component({
  selector: 'app-view-recipe',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NavbarComponent,EditorModule],
  templateUrl: './modify-recipe.component.html',
  styleUrl: './modify-recipe.component.scss'
})
export class ModifyRecipeComponent implements OnInit {
  id: number = 0;
  units: string[] = [];
  recipe: any;

  recipeForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, 
    private recipeService: RecipesService, private router: Router) { 
    this.recipeForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      procedure: ['', [Validators.required]],
      ingredients: this.formBuilder.array([this.createIngredientFormGroup()]),
      inputs: this.formBuilder.array([this.createInputsFormGroup()]),
    });
  }

  ngOnInit() {
    this.id = +(this.route.snapshot.paramMap.get('id') ?? 0);
    if(this.id !== 0){
      this.recipe = this.recipeService.getRecipeById(this.id).subscribe(
        (recipe: any) => {
          this.recipe = recipe;
          this.patchForm();
    });
      
    }
    this.units = Object.values(Units);
  }

  createIngredientFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      quantity: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      unit: ['', [Validators.required]]
    });
  }
  
  createInputsFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      quantity: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      unit: ['', [Validators.required]]
    });
  }


  get ingredients(): FormArray {
    return this.recipeForm.get('ingredients') as FormArray;
  }

  addIngredient() {
    this.ingredients.push(this.createIngredientFormGroup());
  }

  removeIngredient(index: number) {
    this.ingredients.removeAt(index);
  }

  get inputs(): FormArray {
    return this.recipeForm.get('inputs') as FormArray;
  }
  addInput() {
    this.inputs.push(this.createIngredientFormGroup());
  }

  removeInput(index: number) {
    this.inputs.removeAt(index);
  }

  onSubmit() {
    if(this.recipeForm.valid){
      console.log(this.recipeForm.value);
      const ingredientList = this.recipeForm
        .get('ingredients')
        ?.value.map((ingredient: any) => {
          return `${ingredient.name},${ingredient.quantity},${ingredient.unit}`;
        })
        .join(';'); // Concatenar con ';'
      console.log(ingredientList);

      // Obtener y transformar la lista de insumos
      const inputsList = this.recipeForm
        .get('inputs')
        ?.value.map((input: any) => {
          return `${input.name},${input.quantity},${input.unit}`;
        })
        .join(';'); // Concatenar con ';'
      console.log(inputsList);
      const recipe: TableData = {
        id: this.id,
        name: this.recipeForm.get('name')?.value,
        description: this.recipeForm.get('procedure')?.value,
        recipeIngredients: ingredientList,
        recipePackagingElements: inputsList,
      };
      this.recipeService.updateRecipe(recipe).subscribe(
        (response) => {
          console.log(response);
          this.router.navigate(['/recipes']);
        },
        (error) => {
          console.error(error);
        }
      );
    }
    
  }


  private patchForm() {
    // Rellena los campos básicos
    this.recipeForm.patchValue({
      name: this.recipe['name'],
      procedure: this.recipe['description']
    });
  
    // Limpia los FormArrays antes de agregar nuevos elementos
    this.ingredients.clear();
    this.inputs.clear();
  
    // Obtén los ingredientes y agrégales al FormArray
    const ingredients = parseList(this.recipe['recipeIngredients']);
    ingredients.forEach((ingredient: Argument) => {
      this.ingredients.push(this.formBuilder.group({
        name: ingredient.name,
        quantity: ingredient.quantity,
        unit: ingredient.unit
      }));
    });
  
    // Obtén los inputs y agrégales al FormArray
    const inputs = parseList(this.recipe['recipePackagingElements']);
    inputs.forEach((input: Argument) => {
      this.inputs.push(this.formBuilder.group({
        name: input.name,
        quantity: input.quantity,
        unit: input.unit
      }));
    });
  }
  
}


interface Argument{
  name: string;
  quantity: string;
  unit: string;
}

function parseList(list: string): Argument[] {
  if (!list) return [];
  
  // Asegúrate de que la cadena no tenga espacios innecesarios alrededor
  const parsedList = list.split(';').filter(item => item.trim() !== '');
  const args: Argument[] = parsedList.map((element: string) => {
    const [name, quantity, unit] = element.split(',');
    if (!name || !quantity || !unit) {
      console.error(`Invalid format: ${element}`);
      return { name: '', quantity: '', unit: '' };
    }
    return {
      name: name.trim(),
      quantity: quantity.trim(),
      unit: unit.trim()
    };
  });
  return args;
}