<div
  class="modal modal-bg fade show"
  tabindex="-1"
  style="display: block"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content modal-content-custom">
      <div
        class="modal-header d-flex justify-content-center align-items-center"
      >
        <div class="row w-100">
          <div class="col">
            <h5 class="modal-title">Vender Lote</h5>
          </div>
          <div class="col d-flex justify-content-end">
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              (click)="closeModal()"
            ></button>
          </div>
        </div>
      </div>
      <form
        #aux="ngForm"
        [formGroup]="sellForm"
        class="w-100"
      >
        <div class="modal-body">
          <div class="col">
            <div class="row mb-3">
              <div class="form-group d-flex align-items-center">
                <label>Comprador</label>
                <select
                  id="customer"
                  class="form-control ms-2"
                  formControlName="customer"
                  [ngClass]="{
                    'is-invalid':
                      (sellForm.get('customer')?.touched || aux.submitted) &&
                      sellForm.get('customer')?.invalid
                  }"
                >
                  <option
                    *ngFor="let customer of customers"
                    [value]="customer['id']"
                  >
                    {{ customer["name"] }}
                  </option>
                </select>
              </div>
              <div class="error-container">
                <div
                  class="text-danger"
                  *ngIf="
                    (sellForm.get('customer')?.touched || aux.submitted) &&
                    sellForm.get('customer')?.invalid
                  "
                >
                  Seleccione un comprador
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col d-flex">
                <div class="form-group w-100">
                  <div class="row align-items-center">
                    <div class="col">
                      <label>Lotes Disponibles</label>
                    </div>
                    <div class="col d-flex justify-content-end">
                      <button
                        type="button"
                        class="btn btn-icon text-success"
                        (click)="addRow()"
                      >
                        <i class="bi bi-plus-square-fill"></i>
                      </button>
                    </div>
                  </div>
                  <div class="row d-flex">
                    <div class="table-container">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="col-8">Producto</th>
                            <th class="col-3">Cantidad</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody class="tbody-container">
                          <tr
                            *ngFor="let item of items.controls; let i = index"
                          >
                            <td>
                              <select
                                class="form-control"
                                placeholder="Produccion"
                                (change)="onBatchChange($event, i)"
                                [formControl]="getBatchIdControl(i)"
                                [ngClass]="{
                                  'is-invalid':
                                    itemControls[i].invalid &&
                                    (itemControls[i].touched || aux.submitted)
                                }"
                              >
                                <option
                                  *ngFor="let batch of avaibleBatches"
                                  [value]="batch['id']"
                                  [disabled]="batch['selected']"
                                >
                                  {{ batch["name"] }} {{ batch["quantity"] }}
                                  {{ batch["unit"] }} - VTO: {{ getDate(batch["expiration"]) }}
                                </option>
                              </select>
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                [formControl]="getQuantityControl(i)"
                                [max]="maxQuantities[i]"
                                [min]="1"
                                placeholder="Cantidad"
                                (change)="calculateCost()"
                                [ngClass]="{
                                  'is-invalid':
                                    itemControls[i].invalid &&
                                    (itemControls[i].touched || aux.submitted)
                                }"
                              />
                              <div
                                *ngIf="
                                  itemControls[i].invalid &&
                                  (itemControls[i].touched || aux.submitted)
                                "
                                class="text-danger"
                              >
                                <div *ngIf="itemControls[i].errors?.['min']">
                                  La cantidad mínima es 1.
                                </div>
                                <div *ngIf="itemControls[i].errors?.['max']">
                                  La cantidad máxima es {{ maxQuantities[i] }}.
                                </div>
                              </div>
                              <div *ngIf="sellForm.get('items')?.errors?.['duplicateBatches']" class="text-danger">
                                No puedes seleccionar el mismo lote más de una vez.
                              </div>                              
                            </td>
                            <td style="text-align: center">
                              <div *ngIf="i > 0">
                                <button
                                  class="btn btn-danger"
                                  (click)="removeRow(i)"
                                >
                                  <i class="bi bi-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col d-flex justify-content-center align-items-center">
                <label class="me-3">Precio</label>
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    formControlName="price"
                    placeholder="Precio"
                    [ngClass]="{
                      'is-invalid':
                        (sellForm.get('price')?.touched || aux.submitted) &&
                        sellForm.get('price')?.invalid
                    }"
                  />
                </div>
                <label class="ms-3 cost">Costo: {{ totalCost }}</label>
              </div>
              <div class="col-12">
                <div class="error-container mt-2 text-center">
                  <div
                    *ngIf="
                      (sellForm.get('price')?.touched || aux.submitted) &&
                      sellForm.get('price')?.invalid
                    "
                    class="text-danger"
                  >
                    El precio es requerido.
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="closeModal()"
          >
            Cancelar
          </button>
          <button type="submit" class="btn btn-custom" (click)="onSubmit()">
            Vender
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

