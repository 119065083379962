<app-navbar></app-navbar>
<div
  class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100"
>
  <div class="col-flex justify-content-center align-items-center">
    <div class="container custom-container justify-content-center">
      <div class="row header-row">
        <div class="col">
          <h1 class="view-title">Recetario</h1>
        </div>
        <div class="col">
          <app-search-bar (search)="handleSearch($event)"></app-search-bar>
        </div>
        <div class="col">
          <button class="btn btn-custom" (click)="goTo()">NUEVA RECETA</button>
        </div>
      </div>
      <div class="container justify-content-center align-items-center">
        <div *ngIf="recipes.length === 0" class="row">
          <div class="no-recipe-container">
            <h2 class="text-center">No hay recetas disponibles</h2>
          </div>
        </div>
        <div *ngIf="recipes.length > 0" class="row">
          <app-carousel [recipes]="recipes"> </app-carousel>
        </div>
      </div>
      <!--app-carousel [recipes]="recipes"> </app-carousel-->
    </div>  
</div>
