import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map} from 'rxjs';
import { handleError } from '../../utils/api-error-handler.utils';
import { TableData } from '../../interfaces/table-data';
import { mapToTableData, mapToUser } from '../../utils/mapping.utils';
import { environment } from '../../../../evironments/environment';
@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private apiUrl = environment.apiUrl + '/api/user';

  constructor(private http: HttpClient) {}

  getUsers(): Observable<TableData[]> {
    return this.http.get<any[]>(this.apiUrl).pipe(map(data => mapToTableData(data)),catchError(handleError));
  }

  getUsersById(id: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`).pipe(map(data => mapToUser(data)),catchError(handleError));
  }
  createUser(user: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, user).pipe(catchError(handleError));
  }

  updateUser(user: any): Observable<any> {
    return this.http.put<any>(this.apiUrl, user).pipe(catchError(handleError));
  }

  deleteUser(id: any): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${id}`).pipe(catchError(handleError));
  }

}
