import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersService } from '../../../core/services/api/users.service';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator, phoneValidator } from '../../../core/utils/validators';
import { matchPasswordValidator } from '../../../core/utils/validators';
import { TableData } from '../../../core/interfaces/table-data';
@Component({
  selector: 'app-add-user',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './add-user.component.html',
  styleUrl: './add-user.component.scss'
})
export class AddUserComponent {
  @Output() closeModalEvent = new EventEmitter<void>();
  userForm: FormGroup;
  confirmPassword: string = '';
  fatalError: boolean = false;
  fatalErrorMessage: string = 'Hubo un error al intentar crear el usuario. Por favor, intente nuevamente.';

  constructor(private userService: UsersService, private formBuilder: FormBuilder) {
    this.userForm = this.formBuilder.group({
      email: ['', [Validators.required, emailValidator]],
      name: ['', [Validators.required,  Validators.pattern("^[a-zA-ZÀ-ÿ'\\- ]+$"),]],
      surname: ['', [Validators.required,  Validators.pattern("^[a-zA-ZÀ-ÿ'\\- ]+$"),]],
      phone: ['', [Validators.required, phoneValidator]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      role: ['', Validators.required]
    }, { validator: matchPasswordValidator() });
  }

  closeModal() {
    this.closeModalEvent.emit();
  }


  onSubmit() {
    if (this.userForm.valid) {
      this.fatalError = false;
      const user: TableData = {
        name: this.userForm.get('name')?.value,
        surname: this.userForm.get('surname')?.value,
        email: this.userForm.get('email')?.value,
        phone: this.userForm.get('phone')?.value,
        password: this.userForm.get('password')?.value ||'', // Password is optional
        role: this.userForm.get('role')?.value
      };
      //Call to backend
      this.userService.createUser(user).subscribe(() => {
        this.closeModalEvent.emit(); // Cerrar el modal
      });
    }
    else {
      this.fatalError = true;
    }
  }
}
