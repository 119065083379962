<app-navbar></app-navbar>
<div class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100">
    
    <div class="col-flex justify-content-center align-items-center">
        <div class="row header-row">
            <div class="col">
                <h1 class="view-title">Existencias</h1>
            </div>
            <div class="col">
               <app-search-bar (search)="handleSearch($event)"></app-search-bar>
            </div>
            <div class="col d-flex justify-content-center">
                <button class="btn btn-custom" (click)="openDecisionModal()">AGREGAR EXISTENCIA</button>
            </div>
        </div>

        <app-table [isTableReady]="isLoading" [data]="inputs" [columnsExcluded]="excludeColumns" (rowClicked)="onRowClicked($event)"></app-table>

    </div>
</div>



<app-decision-modal *ngIf="decisionModal" (close)="closeDecisionModal($event)"></app-decision-modal>
<app-add-input *ngIf="inputsModal" (close)="closeAddInputModal()"></app-add-input>
<app-add-raw-material *ngIf="rawMaterialsModal" (close)="closeAddRawMaterialModal()"></app-add-raw-material>

<app-raw-material *ngIf="editRawMaterialsModal" [productInput]="selectedProduct" (close)="closeProductModal()"></app-raw-material>
<app-inputs *ngIf="editInputsModal" [productInput]="selectedProduct" (close)="closeProductModal()"></app-inputs>