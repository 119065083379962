import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Units } from '../../../shared/enums/units.enum';
import { decimalNumberValidator } from '../../../core/utils/validators';
import { TableData } from '../../../core/interfaces/table-data';
import { InputsService } from '../../../core/services/api/inputs.service';
import { ProvidersService } from '../../../core/services/api/providers.service';
import { InventoryService } from '../../../core/services/enums/inventory.service';

@Component({
  selector: 'app-add-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './add-input.component.html',
  styleUrl: './add-input.component.scss',
})
export class AddInputComponent {
  @Output() close = new EventEmitter<void>();
  productForm: FormGroup;
  addTypeForm: FormGroup;

  providers: TableData[] = [];
  units: string[] = [];
  inventoryTypes: string[] = [];
  addTypeModal: boolean = false;
  isRawMaterial: boolean = false;
  avaibleFreezed: boolean = false;

  constructor(
    private InputsService: InputsService,
    private formBuilder: FormBuilder,
    private InventoryService: InventoryService,
    private providersService: ProvidersService
  ) {
    this.productForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      quantity: ['', [Validators.required, decimalNumberValidator]],
      unit: ['', [Validators.required]],
      cost: ['', [Validators.required, decimalNumberValidator]],
      description: ['', [Validators.pattern(/^[a-zA-Z0-9À-ÿ\s'_-]+$/)]],
      provider: ['', [Validators.required]],
    });
    this.addTypeForm = this.formBuilder.group({
      newType: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
    });
  }

  ngOnInit() {
    this.InventoryService.chargeInputsTypes();
    this.getProviders();
    this.loadProductTypes();
    this.units = Object.values(Units);
  }

  closeModal() {
    this.close.emit();
  }

  openAddTypeModal() {
    this.addTypeModal = true;
  }

  closeAddTypeModal() {
    this.addTypeModal = false;
  }

  addType() {
    if (this.addTypeForm.valid) {
      const newType = this.addTypeForm.value.newType;
      this.InventoryService.addInputsType(newType);
      this.closeAddTypeModal();
    } else {
      console.error('Formulario de tipo inválido.');
    }
  }

  onSubmit() {
    //console.log('form', this.productForm);
    if (this.productForm.valid) {
      const providerId = this.productForm.get('provider')?.value;
      //const selectedProvider = this.providers.find(provider => provider['id'] === providerId);
      const selectedProvider = this.providers.find((provider) => {
        return Number(provider['id']) === Number(providerId);
      });
      const input: TableData = {
        name: this.productForm.get('name')?.value,
        type: this.productForm.get('type')?.value,
        quantity: this.productForm.get('quantity')?.value,
        unit: this.productForm.get('unit')?.value,
        cost: this.productForm.get('cost')?.value,
        description: this.productForm.get('description')?.value || '',
        provider: selectedProvider || {},
      };
      this.InputsService.createInput(input).subscribe(
        () => {
          //console.log('Insumo creado con éxito');
          this.closeModal();
        },
        (error) => {
          console.error('Error al crear el insumo', error);
        }
      );
    }
  }

  private getProviders() {
    this.providersService.getProviders().subscribe((res: any[]) => {
      this.providers = res.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  private loadProductTypes(): void {
    this.InventoryService.getInputsTypes().subscribe((types) => {
      this.inventoryTypes = types.sort((a, b) => a.localeCompare(b));
    });
  }
}
