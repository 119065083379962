<app-navbar></app-navbar>
<div class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100">
  <div class="row justify-content-center align-items-center w-100">
    <div class="col-10 col-main">
      <form #aux="ngForm" [formGroup]="recipeForm" (ngSubmit)="onSubmit()" class="w-100">
        <div class="row header-row mb-4">
          <div class="col">
            <h1 class="view-title">Modificar Receta</h1>
          </div>
          <div class="col d-flex justify-content-end">
            <button type="submit" class="btn btn-custom">GUARDAR</button>
          </div>
        </div>
        <div class="row custom-row">
          <div class="row">
            <div class="form-group">
              <div class="row align-items-center mb-3">
                <div class="col-3">
                  <label for="name" class="title">Nombre</label>
                </div>
                <div class="col">
                  <input type="text" class="form-control" id="name" name="name" formControlName="name"
                    [ngClass]="{ 'is-invalid': (recipeForm.get('name')?.dirty || recipeForm.get('name')?.touched || aux.submitted) && recipeForm.get('name')?.invalid }" />
                  <div class="error-container">
                    <div *ngIf="aux.submitted || recipeForm.get('name')?.invalid && (recipeForm.get('name')?.dirty || recipeForm.get('name')?.touched)"
                      class="text-danger">
                      <div *ngIf="recipeForm.get('name')?.errors?.['required']">Nombre es requerido.</div>
                      <div *ngIf="!recipeForm.get('name')?.errors?.['required'] && recipeForm.get('name')?.errors?.['pattern']">
                        El nombre no debe contener números ni símbolos.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center py-2">
            <!-- Columna Izquierda: Ingredientes -->
            <div class="col-md-6">
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col">
                    <label class="title">Ingredientes</label>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <button type="button" class="btn btn-icon text-success" (click)="addIngredient()"><i class="bi bi-plus-square-fill"></i></button>
                  </div>
                </div>
                <div formArrayName="ingredients" class="adder-container">
                  <div *ngFor="let ingredient of ingredients.controls; let i = index" [formGroupName]="i" class="form-group adder-row">
                    <div class="row align-items-center">
                      <div class="col">
                        <input id="ingredient-name-{{i}}" formControlName="name" class="form-control" placeholder="Nombre del ingrediente" 
												[ngClass]="{ 'is-invalid': (ingredient.get('name')?.dirty || ingredient.get('name')?.touched || aux.submitted) && ingredient.get('name')?.invalid }"/>
                      </div>
                      <div class="col-2">
                        <input type="number" id="ingredient-quantity-{{i}}" formControlName="quantity" class="form-control" placeholder="Cantidad" 
												[ngClass]="{ 'is-invalid': (ingredient.get('quantity')?.dirty || ingredient.get('quantity')?.touched || aux.submitted) && ingredient.get('quantity')?.invalid }" />
                      </div>
                      <div class="col-2">
                        <select id="ingredient-unit-{{i}}" formControlName="unit" class="form-select me-2" aria-label="Unit Selection"
												[ngClass]="{ 'is-invalid': (ingredient.get('unit')?.dirty || ingredient.get('unit')?.touched || aux.submitted) && ingredient.get('unit')?.invalid }">
                          <option value="" disabled selected>...</option>
                          <option *ngFor="let unit of units" [value]="unit">
                            {{ unit }}
                          </option>
                        </select>
                      </div>
                      <div class="col-2">
                        <div *ngIf="i !== 0">
                          <button type="button" class="btn btn-icon text-danger" (click)="removeIngredient(i)">
                            <i class="bi bi-x-square-fill"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Columna Derecha: Insumos -->
            <div class="col-md-6">
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col">
                    <label class="title">Insumos</label>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <button type="button" class="btn btn-icon text-success" (click)="addInput()"><i class="bi bi-plus-square-fill"></i></button>
                  </div>
                </div>
                <div formArrayName="inputs" class="adder-container">
                  <div *ngFor="let input of inputs.controls; let j = index" [formGroupName]="j" class="form-group adder-row">
                    <div class="row align-items-center">
                      <div class="col">
                        <input id="inputs-name-{{j}}" formControlName="name" class="form-control" placeholder="Nombre del insumo" 
												[ngClass]="{ 'is-invalid': (input.get('name')?.dirty || input.get('name')?.touched || aux.submitted) && input.get('name')?.invalid }" />
												
                      </div>
                      <div class="col-3">
                        <input id="inputs-quantity-{{j}}" formControlName="quantity" class="form-control" placeholder="Cantidad" 
												[ngClass]="{ 'is-invalid': (input.get('quantity')?.dirty || input.get('quantity')?.touched || aux.submitted) && input.get('quantity')?.invalid }" />
                      </div>
                      <div class="col-2">
                        <select id="inputs-unit-{{j}}" formControlName="unit" class="form-select me-2" aria-label="Unit Selection"
												[ngClass]="{ 'is-invalid': (input.get('unit')?.dirty || input.get('unit')?.touched || aux.submitted) && input.get('unit')?.invalid }" >
                          <option value="" disabled selected>...</option>
                          <option *ngFor="let unit of units" [value]="unit">
                            {{ unit }}
                          </option>
                        </select>
                      </div>
                      <div class="col-2">
                        <div *ngIf="j !== 0">
                          <button type="button" class="btn btn-icon text-danger" (click)="removeInput(j)">
                            <i class="bi bi-x-square-fill"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="form-group">
            <label class="title">Procedimiento</label>
            <!--textarea id="procedure" name="procedure" formControlName="procedure" class="form-control" rows="10"
						[ngClass]="{ 'is-invalid': (recipeForm.get('procedure')?.dirty || recipeForm.get('procedure')?.touched || aux.submitted) && recipeForm.get('procedure')?.invalid }" ></textarea-->
            <p-editor formControlName="procedure" class="form-control p-editor-custom"
                      [style]="{ height: '240px' }"
                      [ngClass]="{ 'is-invalid': (recipeForm.get('procedure')?.dirty || recipeForm.get('procedure')?.touched || aux.submitted) && recipeForm.get('procedure')?.invalid }">
                      <ng-template pTemplate="header">
                        <span class="ql-formats">
                          <select class="ql-header" aria-label="Select header">
                            <option selected></option>
                            <option value="1">Heading 1</option>
                            <option value="2">Heading 2</option>
                            <option value="3">Heading 3</option>
                          </select>
                          <select class="ql-font" aria-label="Select font">
                            <option selected></option>
                            <option value="serif">Serif</option>
                            <option value="monospace">Monospace</option>
                          </select>
                          <button class="ql-bold" aria-label="Bold"></button>
                          <button class="ql-italic" aria-label="Italic"></button>
                          <button class="ql-underline" aria-label="Underline"></button>
                          <select class="ql-align" aria-label="Select align">
                            <option selected></option>
                            <option value="center">Center</option>
                            <option value="right">Right</option>
                            <option value="justify">Justify</option>
                          </select>
                          <button class="ql-list" value="ordered" aria-label="Ordered list"></button>
                          <button class="ql-list" value="bullet" aria-label="Bullet list"></button>
                          <button class="ql-link" aria-label="Insert link"></button>
                        </span>
                      </ng-template>
                    </p-editor>
						<div class="error-container">
							<div *ngIf="aux.submitted || recipeForm.get('procedure')?.invalid && (recipeForm.get('procedure')?.dirty || recipeForm.get('procedure')?.touched)"
								class="text-danger">
								<div *ngIf="recipeForm.get('procedure')?.errors?.['required']">Procedimiento es requerido.</div>
								<div *ngIf="!recipeForm.get('procedure')?.errors?.['required'] && recipeForm.get('procedure')?.errors?.['pattern']">
										Es necesario agregar un procedimiento.
								</div>
							</div>
						</div>
					</div>
        </div>
      </form>
    </div>
  </div>
</div>
