<div
  class="modal modal-bg fade show"
  tabindex="-1"
  style="display: block"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content modal-content-custom">
      <div
        class="modal-header d-flex justify-content-center align-items-center"
      >
        <div class="row w-100">
          <div class="col">
            <h5 class="modal-title">Detalles Existencia</h5>
          </div>
          <div class="col d-flex justify-content-center">
            <div class="form-check form-switch">
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Editar</label
              >
              <input
                class="form-check-input custom-switch"
                type="checkbox"
                id="flexSwitchCheckDefault"
                (change)="toggleEditMode()"
              />
            </div>
          </div>
          <div class="col d-flex justify-content-end">
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              (click)="closeModal()"
            ></button>
          </div>
        </div>
      </div>
      <form #aux="ngForm" [formGroup]="productForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="row">
            <div class="col">
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="name">Nombre</label>
                  </div>
                  <div class="col">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      name="name"
                      formControlName="name"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        aux.submitted ||
                        (productForm.get('name')?.invalid &&
                          (productForm.get('name')?.dirty ||
                            productForm.get('name')?.touched))
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="productForm.get('name')?.errors?.['required']"
                      >
                        Nombre es requerido.
                      </div>
                      <div
                        *ngIf="!productForm.get('name')?.errors?.['required'] && productForm.get('name')?.errors?.['pattern']"
                      >
                        El nombre no debe contener números ni símbolos.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="type" class="mb-0">Tipo</label>
                  </div>
                  <div class="col">
                    <select
                      id="type"
                      formControlName="type"
                      class="form-select me-2"
                      aria-label="Type Selection"
                      placeholder="Seleccione un tipo"
                    >
                      <option value="" disabled selected>
                        Selecciona un tipo
                      </option>
                      <option
                        *ngFor="let type of inventoryTypes"
                        [value]="type"
                      >
                        {{ type }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <button
                      type="button"
                      class="btn btn-custom"
                      [disabled]="!isToggled"
                      (click)="openAddTypeModal()"
                    >
                      Agregar Tipo
                    </button>
                  </div>
                </div>
                <div class="error-container">
                  <div
                    *ngIf="aux.submitted && productForm.get('type')?.invalid"
                    class="text-danger mt-1"
                  >
                    <div *ngIf="productForm.get('type')?.errors?.['required']">
                      Tipo es requerido.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="quantity">Cantidad</label>
                  </div>
                  <div class="col">
                    <input
                      type="number"
                      class="form-control"
                      id="quantity"
                      name="quantity"
                      formControlName="quantity"
                    />
                  </div>
                  <div class="col-2">
                    <label for="unit">Unidad</label>
                  </div>
                  <div class="col-2">
                    <select
                      id="unit"
                      formControlName="unit"
                      class="form-select me-2"
                      aria-label="Unit Selection"
                    >
                      <option value="" disabled selected>...</option>
                      <option *ngFor="let unit of units" [value]="unit">
                        {{ unit }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row error-container align-items-center">
                  <div
                    class="col-8 text-danger"
                    *ngIf="
                      aux.submitted ||
                      (productForm.get('quantity')?.invalid &&
                        (productForm.get('quantity')?.dirty ||
                          productForm.get('quantity')?.touched))
                    "
                  >
                    <div
                      *ngIf="productForm.get('quantity')?.errors?.['required']"
                    >
                      Es necesario ingresar una cantidad.
                    </div>
                    <div
                      *ngIf="!productForm.get('quantity')?.errors?.['required'] && productForm.get('quantity')?.errors?.['invalidQuantity']"
                    >
                      La cantidad debe ser un número válido mayor a 0.
                    </div>
                  </div>
                  <div
                    class="col text-danger"
                    *ngIf="
                      aux.submitted ||
                      (productForm.get('unit')?.invalid &&
                        (productForm.get('unit')?.dirty ||
                          productForm.get('unit')?.touched))
                    "
                  >
                    <div *ngIf="productForm.get('unit')?.errors?.['required']">
                      Ingrese una unidad.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="cost">Precio Unitario</label>
                  </div>
                  <div class="col">
                    <div class="input-group mb-3">
                      <span class="input-group-text">$</span>
                      <input
                        type="text"
                        class="form-control"
                        id="cost"
                        name="cost"
                        formControlName="cost"
                        aria-label="Costo"
                      />
                    </div>
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        aux.submitted ||
                        (productForm.get('cost')?.invalid &&
                          (productForm.get('cost')?.dirty ||
                            productForm.get('cost')?.touched))
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="productForm.get('cost')?.errors?.['required']"
                      >
                        Es necesario ingresar un Precio.
                      </div>
                      <div
                        *ngIf="!productForm.get('cost')?.errors?.['required'] && productForm.get('cost')?.errors?.['invalidQuantity']"
                      >
                        El Precio debe ser un número válido y mayor que cero.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="expirationDate">Proveedor</label>
                  </div>
                  <div class="col">
                    <select
                      id="provider"
                      formControlName="provider"
                      class="form-select me-2"
                      aria-label="Provider Selection"
                    >
                      <option value="" disabled selected>...</option>
                      <option
                        *ngFor="let provider of providers"
                        [value]="provider['id']"
                      >
                        {{ provider["name"] }}
                      </option>
                    </select>
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        (aux.submitted &&
                          productForm.get('provider')?.invalid) ||
                        (productForm.get('provider')?.invalid &&
                          (productForm.get('provider')?.dirty ||
                            productForm.get('provider')?.touched))
                      "
                      class="text-danger"
                    >
                      Se necesita un Provedor.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="desscription">Descripcion</label>
              <textarea
                type="text"
                class="form-control desc"
                id="descripcion"
                name="description"
                formControlName="description"
              >
              </textarea>
              <div class="error-container">
                <div
                  *ngIf="
                    productForm.get('description')?.invalid &&
                    productForm.get('description')?.dirty &&
                    productForm.get('description')?.value
                  "
                  class="text-danger"
                >
                  Descripcion no admite simbolos.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="submit" class="btn btn-custom" [disabled]="!isToggled">
            GUARDAR
          </button>
          <button
            type="button"
            class="btn btn-danger"
            (click)="confirmDelete()"
          >
            BORRAR
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  *ngIf="addTypeModal"
  class="modal modal-bg fade show"
  tabindex="-1"
  style="display: block"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="confirmDeleteModalLabel">
          Agregar Tipo
        </h1>
        <button
          type="button"
          class="btn-close"
          (click)="closeAddTypeModal()"
        ></button>
      </div>
      
      <form #aux2="ngForm" [formGroup]="addTypeForm" (ngSubmit)="addType()">
        <div class="modal-body">
          <div class="form-group">
            <label for="newType">Nuevo Tipo</label>
            <input
              type="text"
              class="form-control"
              id="newType"
              formControlName="newType"
              placeholder="Ingrese el nuevo tipo"
              [ngClass]="{
                'is-invalid':
                  (addTypeForm.get('newType')?.touched || aux2.submitted) &&
                  addTypeForm.get('newType')?.invalid
              }"
            />
            <div
              *ngIf="
                (addTypeForm.get('newType')?.touched || aux2.submitted) &&
                addTypeForm.get('newType')?.invalid
              "
              class="text-danger mt-1"
            >
              <div *ngIf="addTypeForm.get('newType')?.errors?.['required']">
                El tipo es requerido.
              </div>
              <div *ngIf="addTypeForm.get('newType')?.errors?.['pattern']">
                El tipo no debe contener números ni símbolos.
              </div>
            </div>
          </div>
        </div>
        
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="closeAddTypeModal()"
          >
            CANCELAR
          </button>
          <button type="submit" class="btn btn-success">
            GUARDAR
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<app-delete-modal
  *ngIf="confirmDeleteModal"
  [headerMessage]="'Eliminar Proveedor'"
  [bodyMessage]="'¿Estás seguro que deseas borrar este Proveedor?'"
  (cancel)="closeDeleteModal()"
  (confirm)="deleteProduct()"
>
</app-delete-modal>
