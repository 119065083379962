<div
  class="modal modal-bg fade show"
  tabindex="-1"
  style="display: block"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Agregar Proveedor</h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="closeModal()"
        ></button>
      </div>
      <form
        #aux="ngForm"
        [formGroup]="providerForm"
        (ngSubmit)="onSubmit()"
        noValidate
      >
        <div class="modal-body">
          <div class="row">
            <div class="col">
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="name" class="title">Nombre</label>
                  </div>
                  <div class="col">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      name="name"
                      formControlName="name"
                      placeholder="Nombre"
                      [ngClass]="{
                        'is-invalid':
                          (providerForm.get('name')?.dirty ||
                            providerForm.get('name')?.touched ||
                            aux.submitted) &&
                          providerForm.get('name')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="(aux.submitted || providerForm.get('name')?.dirty || providerForm.get('name')?.touched) && providerForm.get('name')?.invalid"
                      class="text-danger"
                    >
                      <div *ngIf="providerForm.get('name')?.errors?.['required']">
                        Nombre es requerido.
                      </div>
                      <div *ngIf="providerForm.get('name')?.errors?.['pattern']">
                        El nombre no debe contener números ni símbolos.
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
             
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-1">
                    <label for="email" class="title">Email</label>
                  </div>
                  <div class="col">
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      formControlName="email"
                      placeholder="Correo electrónico"
                      [ngClass]="{
                        'is-invalid':
                          (providerForm.get('email')?.dirty ||
                            providerForm.get('email')?.touched ||
                            aux.submitted) &&
                          providerForm.get('email')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        aux.submitted ||
                        (providerForm.get('email')?.invalid &&
                          (providerForm.get('email')?.dirty ||
                            providerForm.get('email')?.touched))
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="providerForm.get('email')?.errors?.['required']"
                      >
                        Email es requerido.
                      </div>
                      <div
                        *ngIf="!providerForm.get('email')?.errors?.['required'] && (providerForm.get('email')?.errors?.['email'] || providerForm.get('email')?.errors?.['invalidEmail'])"
                      >
                        Formato de email inválido.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="phone" class="title">Teléfono</label>
                  </div>
                  <div class="col">
                    <input
                      type="number"
                      class="form-control"
                      id="phone"
                      name="phone"
                      formControlName="phone"
                      placeholder="Teléfono"
                      [ngClass]="{
                        'is-invalid':
                          (providerForm.get('phone')?.dirty ||
                            providerForm.get('phone')?.touched ||
                            aux.submitted) &&
                          providerForm.get('phone')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        (providerForm.get('phone')?.invalid &&
                          (providerForm.get('phone')?.dirty ||
                          providerForm.get('phone')?.touched)) ||
                        (aux.submitted && providerForm.get('phone')?.invalid)
                      "
                      class="text-danger"
                    >
                      <div *ngIf="providerForm.get('phone')?.errors?.['required']">
                        Teléfono es requerido.
                      </div>
                      <div
                        *ngIf="providerForm.get('phone')?.errors?.['invalidPhone']"
                      >
                        Formato de teléfono inválido.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="cuit" class="title">CUIT</label>
                  </div>
                  <div class="col">
                    <input
                      type="number"
                      class="form-control"
                      id="cuit"
                      name="cuit"
                      formControlName="cuit"
                      placeholder="000000000000"
                      [ngClass]="{
                        'is-invalid':
                          (providerForm.get('cuit')?.dirty ||
                            providerForm.get('cuit')?.touched ||
                            aux.submitted) &&
                          providerForm.get('cuit')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        aux.submitted ||
                        (providerForm.get('cuit')?.invalid &&
                          (providerForm.get('cuit')?.dirty ||
                            providerForm.get('cuit')?.touched))
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="providerForm.get('cuit')?.errors?.['required']"
                      >
                        CUIT es requerido.
                      </div>
                      <div
                        *ngIf="!providerForm.get('cuit')?.errors?.['required'] && providerForm.get('cuit')?.errors?.['pattern']"
                      >
                        El CUIT no debe contener letras ni símbolos, y debe
                        contener 11 digitos.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <div class="row align-items-center py-2">
                  <div class="col-2">
                    <label for="address" class="title">Direccion</label>
                  </div>
                  <div class="col">
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      formControlName="address"
                      placeholder="Direccion"
                      [ngClass]="{
                        'is-invalid':
                          (providerForm.get('address')?.dirty ||
                            providerForm.get('address')?.touched ||
                            aux.submitted) &&
                          providerForm.get('address')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        (aux.submitted && providerForm.get('address')?.invalid) ||
                        (providerForm.get('address')?.invalid &&
                          (providerForm.get('address')?.dirty ||
                            providerForm.get('address')?.touched))
                      "
                      class="text-danger"
                    >
                      Se necesita una direccion.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-1">
                    <label for="cp" class="title">CP</label>
                  </div>
                  <div class="col-2">
                    <input
                      type="text"
                      class="form-control"
                      id="cp"
                      name="cp"
                      formControlName="cp"
                      placeholder="0000"
                      [ngClass]="{
                        'is-invalid':
                          (providerForm.get('cp')?.dirty ||
                            providerForm.get('cp')?.touched ||
                            aux.submitted) &&
                          providerForm.get('cp')?.invalid
                      }"
                    />
                  </div>
                  <div class="col-2">
                    <label for="line2" class="title">Notas</label>
                  </div>
                  <div class="col-7">
                    <input
                      type="text"
                      class="form-control"
                      id="line2"
                      formControlName="line2"
                      placeholder="Entre calles 00 y 00"
                      [ngClass]="{
                        'is-invalid':
                          (providerForm.get('line2')?.dirty ||
                            providerForm.get('line2')?.touched ||
                            aux.submitted) &&
                          providerForm.get('line2')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        providerForm.get('line2')?.invalid &&
                        (providerForm.get('line2')?.dirty ||
                          providerForm.get('line2')?.touched)
                      "
                      class="text-danger"
                    >
                      Revise las notas.
                    </div>
                    <div
                      *ngIf="
                        (aux.submitted && providerForm.get('cp')?.invalid) ||
                        (providerForm.get('cp')?.invalid &&
                          (providerForm.get('cp')?.dirty ||
                            providerForm.get('cp')?.touched))
                      "
                      class="text-danger"
                    >
                      CP es requerido.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label
                  for="description"
                  class="title"
                  style="padding-bottom: 0.3rem"
                  >Descripción</label
                >
                <textarea
                  class="form-control desc"
                  id="description"
                  name="description"
                  formControlName="description"
                  maxlength="200"
                  [ngClass]="{
                    'is-invalid':
                      (providerForm.get('description')?.dirty ||
                        providerForm.get('description')?.touched ||
                        aux.submitted) &&
                      providerForm.get('description')?.invalid
                  }"
                >
                </textarea>
                <div class="error-container">
                  <div
                    *ngIf="
                      providerForm.get('description')?.invalid &&
                      (providerForm.get('description')?.dirty ||
                        providerForm.get('description')?.touched)
                    "
                    class="text-danger"
                  >
                    Descripción no admite símbolos.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="submit" class="btn btn-custom">GUARDAR</button>
        </div>
      </form>
    </div>
  </div>
</div>
