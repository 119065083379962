<div
  class="modal modal-bg fade show"
  tabindex="-1"
  style="display: block"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content modal-content-custom">
      <div
        class="modal-header d-flex justify-content-center align-items-center"
      >
        <div class="row w-100">
          <div class="col">
            <h5 class="modal-title">Detalles Lote</h5>
          </div>
          <div class="col d-flex justify-content-center">
            <button type="button" class="btn btn-success me-2" aria-label="edit" (click)="goTo()">Editar</button>
            <button type="button" class="btn btn-danger" aria-label="delete" (click)="openDeleteModal()">Eliminar</button>
          </div>
          <div class="col d-flex justify-content-end">
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              (click)="closeModal()"
            ></button>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <label class="me-2">Producto:</label>
              <a>{{ batch["name"] }}</a>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <label class="me-2">Categoría:</label>
              <a>{{ batch["category"] }}</a>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <label class="me-2">Cantidad:</label>
              <a>{{ batch["quantity"] }} {{ batch["unit"] }}</a>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <label class="me-2">Vencimiento:</label>
              <a>{{ batch["expiration"] }}</a>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <label class="me-2">Fecha de Elaboración:</label>
              <a>{{ batch["creationDate"] }}</a>
            </div>
          </div>          
          <div class="col">
            <div class="row align-items-center">
              <label>Ingredientes:</label>
              <div class="ingredients-container">
                <ul>
                  <li *ngFor="let ingredient of batchIngredientsData">
                    {{ ingredient["name"] }} - {{ ingredient["quantity"] }} {{ ingredient["unit"] }} - {{ ingredient["provider"] }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row align-items-center">
              <label>Insumos:</label>
              <div class="ingredients-container">
                <ul>
                  <li *ngFor="let input of batchInputsData">
                    {{ input["codInput"] }} - {{ input["name"] }} - {{ input["quantity"] }} {{ input["unit"] }} - {{input["provider"]}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>  
      </div>
      <div class="modal-footer justify-content-center"></div>
    </div>
  </div>
</div>



<app-delete-modal
  *ngIf="confirmDeleteModal"
  [headerMessage]="'Eliminar Lote'"
  [bodyMessage]="'¿Estás seguro que deseas borrar este Lote?'"
  (cancel)="closeDeleteModal()"
  (confirm)="deleteProduct()"
>
</app-delete-modal>
