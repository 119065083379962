import { Injectable } from '@angular/core';
import { InputsService } from '../api/inputs.service';
import { RawMaterialsService } from '../api/rawmaterials.service';
import { forkJoin, Observable, map, catchError, BehaviorSubject, retryWhen, scan, delay, throwError} from 'rxjs';
import { TableData } from '../../interfaces/table-data';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  private inputsTypesSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private rawMaterialTypesSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(private InputsService: InputsService, private RawMaterialService: RawMaterialsService) { }



  chargeInputsTypes(): void {
    this.InputsService.getInputsTypes().subscribe(types => this.inputsTypesSubject.next(types));
  }

  chargeRawMaterialTypes(): void {
    this.RawMaterialService.getRawMaterialsTypes().subscribe(types => this.rawMaterialTypesSubject.next(types));
  }
  
  getInputsTypes(): Observable<string[]> {
    return this.inputsTypesSubject.asObservable();
  }

  getRawMaterialTypes(): Observable<string[]> {
    return this.rawMaterialTypesSubject.asObservable();
  }

  addInputsType(type: string): void {
    this.inputsTypesSubject.pipe(
      map(types => {
        if (!types.includes(type)) {
          types.push(type);
          types.sort();
          this.inputsTypesSubject.next(types); // Emit the updated list
        }
      })
    ).subscribe(); // Make sure to subscribe to the observable
  }

  addRawMaterialType(type: string): void {
    this.rawMaterialTypesSubject.pipe(
      map(types => {
        if (!types.includes(type)) {
          types.push(type);
          types.sort();
          this.rawMaterialTypesSubject.next(types); // Emit the updated list
        }
      })
    ).subscribe(); // Make sure to subscribe to the observable
  }

  getInventory(): Observable<TableData[]> {
    const maxRetries = 5; // Número máximo de intentos
    const retryDelay = 5000; // Retraso entre intentos (en milisegundos)
  
    return forkJoin({
      inputs: this.InputsService.getInputs(),
      rawMaterials: this.RawMaterialService.getRawMaterials()
    }).pipe(
      map(({ inputs, rawMaterials }) => {
        // Si alguno es null, se convierte en un array vacío
        const safeInputs = inputs || [];
        const safeRawMaterials = rawMaterials || [];
        return safeInputs.concat(safeRawMaterials).sort();
      }),
      retryWhen(errors =>
        errors.pipe(
          scan((retryCount, error) => {
            if (retryCount >= maxRetries) {
              throw error; // Si se excede el número de intentos, lanza el error
            }
            console.warn(`Retrying... (${retryCount + 1}/${maxRetries})`);
            return retryCount + 1;
          }, 0),
          delay(retryDelay) // Espera un tiempo antes de reintentar
        )
      ),
      catchError(err => {
        console.error('Error fetching inventory', err);
        return throwError(() => err); // Propaga el error final
      })
    );
  }
  
}


