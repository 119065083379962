import { Component, OnInit, Input } from '@angular/core';
import { CardComponent } from '../card/card.component';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { RecipeAbstraction } from '../../../core/interfaces/recipe-abstraction';
@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [CardComponent, CarouselModule, ButtonModule, TagModule],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
})
export class CarouselComponent implements OnInit{
  @Input() recipes: RecipeAbstraction[] = [];

    private currentIndex: number = 0;
    responsiveOptions: any[] | undefined;

    constructor() {}

    ngOnInit() {
        this.responsiveOptions = [
            {
                breakpoint: '1199px',
                numVisible: 1,
                numScroll: 1
            },
            {
                breakpoint: '991px',
                numVisible: 2,
                numScroll: 1
            },
            {
                breakpoint: '767px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    logToConsole(m: any):void {
        console.log("i:"+m);
    }

    getIndex(): number {
      const currentIndex = this.currentIndex;
      this.currentIndex = (this.currentIndex + 1) % this.recipes.length; // Incrementar el índice
      return currentIndex;
    }
    
}
