<app-navbar></app-navbar>
<div
  class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100"
>
  <div class="row justify-content-center align-items-center w-100">
    <div class="col-8 col-main">
      <div class="row header-row mb-4 align-items-center">
        <div class="col">
          <h1 class="view-title">{{ recipe["name"] }}</h1>
        </div>
        <div class="col d-flex justify-content-center">
          <button type="button" class="btn btn-custom" (click)="generatePDF()">
            <i class="bi bi-file-arrow-down-fill"></i> PDF
          </button>
        </div>

        <div class="col d-flex justify-content-end">
          <button type="button" (click)="goTo()" class="btn btn-custom">
            EDITAR
          </button>
          <button type="button" (click)="deleteRecipe()" class="btn btn-custom">
            ELIMINAR
          </button>
        </div>
      </div>
      <div class="col custom-col">
        <div class="row px-2 mb-4">
          <div class="col">
            <label class="subtitle"> INGREDIENTES </label>
            <div class="row">
              <div class="col">
                <div *ngFor="let ingredient of leftColumnIngredients">
                  <li>
                    {{ ingredient.name }} {{ ingredient.quantity
                    }}{{ ingredient.unit }}
                  </li>
                </div>
              </div>
              <div class="col">
                <div *ngFor="let ingredient of rightColumnIngredients">
                  <li>
                    {{ ingredient.name }} {{ ingredient.quantity
                    }}{{ ingredient.unit }}
                  </li>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <label class="subtitle"> Insumos </label>
            <div class="row">
              <div class="col">
                <div *ngFor="let input of leftColumnInputs">
                  <li>{{ input.name }} {{ input.quantity }}{{ input.unit }}</li>
                </div>
              </div>
              <div class="col">
                <div *ngFor="let input of rightColumnInputs">
                  <li>{{ input.name }} {{ input.quantity }}{{ input.unit }}</li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <label class="subtitle"> Procedimiento </label>
          <div class="procedure">
            <div [innerHTML]="recipe['description'] | newLinewToBr"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
