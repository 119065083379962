<div
  class="modal modal-bg fade show"
  tabindex="-1"
  style="display: block"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Agregar Cliente</h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="closeModal()"
        ></button>
      </div>
      <form #aux="ngForm" [formGroup]="clientForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="row">
            <div class="col">
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="form-group row-container">
                    <div class="row align-items-center">
                      <div class="col-2">
                        <label for="name">Nombre</label>
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          name="name"
                          formControlName="name"
                          [ngClass]="{
                            'is-invalid':
                              (clientForm.get('name')?.dirty ||
                                clientForm.get('name')?.touched ||
                                aux.submitted) &&
                              clientForm.get('name')?.invalid
                          }"
                        />
                      </div>
                      <div class="error-container">
                        <div
                          *ngIf="
                            aux.submitted ||
                            (clientForm.get('name')?.invalid &&
                              (clientForm.get('name')?.dirty ||
                                clientForm.get('name')?.touched))
                          "
                          class="text-danger"
                        >
                          <div
                            *ngIf="clientForm.get('name')?.errors?.['required']"
                          >
                            Nombre es requerido.
                          </div>
                          <div
                            *ngIf="!clientForm.get('name')?.errors?.['required'] && clientForm.get('name')?.errors?.['pattern']"
                          >
                            El nombre no debe contener números ni símbolos.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1">
                    <label for="email">Email</label>
                  </div>
                  <div class="col">
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      formControlName="email"
                      [ngClass]="{
                        'is-invalid':
                          (clientForm.get('email')?.dirty ||
                            clientForm.get('email')?.touched ||
                            aux.submitted) &&
                          clientForm.get('email')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        aux.submitted ||
                        (clientForm.get('email')?.invalid &&
                          (clientForm.get('email')?.dirty ||
                            clientForm.get('email')?.touched))
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="clientForm.get('email')?.errors?.['required']"
                      >
                        Email es requerido.
                      </div>
                      <div
                        *ngIf="!clientForm.get('email')?.errors?.['required'] && (clientForm.get('email')?.errors?.['email'] ||clientForm.get('email')?.errors?.['invalidEmail'])"
                      >
                        Formato de email inválido.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="surname">CUIT</label>
                  </div>
                  <div class="col">
                    <input
                      type="number"
                      class="form-control"
                      id="cuit"
                      name="cuit"
                      formControlName="cuit"
                      [ngClass]="{
                        'is-invalid':
                          (clientForm.get('cuit')?.dirty ||
                            clientForm.get('cuit')?.touched ||
                            aux.submitted) &&
                          clientForm.get('cuit')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        aux.submitted ||
                        (clientForm.get('cuit')?.invalid &&
                          (clientForm.get('cuit')?.dirty ||
                            clientForm.get('cuit')?.touched))
                      "
                      class="text-danger"
                    >
                      <div *ngIf="clientForm.get('cuit')?.errors?.['required']">
                        El CUIT es requerido.
                      </div>
                      <div
                        *ngIf="!clientForm.get('cuit')?.errors?.['required'] && clientForm.get('cuit')?.errors?.['pattern']"
                      >
                        El cuit debe no debe contener simbolos ni letras, y una
                        longitude de 17 digitos.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="phone">Teléfono</label>
                  </div>
                  <div class="col">
                    <input
                      type="number"
                      class="form-control"
                      id="phone"
                      name="telefono"
                      formControlName="phone"
                      [ngClass]="{
                        'is-invalid':
                          (clientForm.get('phone')?.dirty ||
                            clientForm.get('phone')?.touched ||
                            aux.submitted) &&
                          clientForm.get('phone')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        (aux.submitted && clientForm.get('phone')?.invalid) ||
                        (clientForm.get('phone')?.invalid &&
                          (clientForm.get('phone')?.dirty ||
                            clientForm.get('phone')?.touched))
                      "
                      class="text-danger"
                    >
                      Teléfono es requerido.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-2">
                    <label for="address" class="title">Direccion</label>
                  </div>
                  <div class="col">
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      formControlName="address"
                      [ngClass]="{
                        'is-invalid':
                          (clientForm.get('address')?.dirty ||
                            clientForm.get('address')?.touched ||
                            aux.submitted) &&
                          clientForm.get('address')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                      (aux.submitted && clientForm.get('address')?.invalid) ||
                        (clientForm.get('address')?.invalid &&
                          (clientForm.get('address')?.dirty ||
                            clientForm.get('address')?.touched))
                      "
                      class="text-danger"
                    >
                      Se necesita una direccion.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-1">
                    <label for="cp" class="title">CP</label>
                  </div>
                  <div class="col-2">
                    <input
                      type="text"
                      class="form-control"
                      id="cp"
                      name="cp"
                      formControlName="cp"
                      [ngClass]="{
                        'is-invalid':
                          (clientForm.get('cp')?.dirty ||
                            clientForm.get('cp')?.touched ||
                            aux.submitted) &&
                          clientForm.get('cp')?.invalid
                      }"
                    />
                  </div>
                  <div class="col-2">
                    <label for="line2" class="title">Notas</label>
                  </div>
                  <div class="col-7">
                    <input
                      type="text"
                      class="form-control"
                      id="line2"
                      formControlName="line2"
                      [ngClass]="{
                        'is-invalid':
                          (clientForm.get('line2')?.dirty ||
                            clientForm.get('linea2')?.touched ||
                            aux.submitted) &&
                          clientForm.get('linea2')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        clientForm.get('line2')?.invalid &&
                        (clientForm.get('line2')?.dirty ||
                          clientForm.get('line2')?.touched)
                      "
                      class="text-danger"
                    >
                      Revise las notas.
                    </div>
                    <div
                      *ngIf="
                      (aux.submitted && clientForm.get('cp')?.invalid) ||
                        (clientForm.get('cp')?.invalid &&
                          (clientForm.get('cp')?.dirty ||
                            clientForm.get('cp')?.touched))
                      "
                      class="text-danger"
                    >
                      CP es requerido.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="submit" class="btn btn-custom">GUARDAR</button>
        </div>
      </form>
    </div>
  </div>
</div>
