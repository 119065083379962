import { Component, EventEmitter, Output, Input, SimpleChanges, OnInit } from '@angular/core';
import { TableData } from '../../../core/interfaces/table-data';
import { CommonModule } from '@angular/common';
import { Units } from '../../../shared/enums/units.enum';
import { BatchesService } from '../../../core/services/api/batches.service';
import { Router } from '@angular/router';
import { DeleteModalComponent } from '../../../shared/components/delete-modal/delete-modal.component';

@Component({
  selector: 'app-view-batch',
  standalone: true,
  imports: [CommonModule, DeleteModalComponent],
  templateUrl: './view-batch.component.html',
  styleUrl: './view-batch.component.scss'
})
export class ViewBatchComponent implements OnInit {
  @Input() batch: TableData = {};
  @Output() close = new EventEmitter<void>();

  confirmDeleteModal=false;
  batchIngredientsData: TableData[] = [];
  batchInputsData: TableData[] = [];
  constructor(private batchesService: BatchesService, private router: Router) {}

  ngOnInit(): void {
    this.getBatchIngredientsData();
    this.getBatchInputsData();
  }
  closeModal(): void {
    this.close.emit();
  }

  closeDeleteModal() {
    this.confirmDeleteModal = false;
  }

  openDeleteModal() {
    this.confirmDeleteModal = true;
  }

  deleteProduct(){
    this.batchesService.deleteBatch(this.batch['id']).subscribe(() => {
      this.closeModal();
    });
  }

  goTo() {
    this.router.navigate([`/production/${this.batch['id']}/modify`]);
  }
  
  private getBatchIngredientsData(): void {
    if (Array.isArray(this.batch['batchIngredients'])) {
      // Dividir la cadena de batchIngredientsQuantity en un array de cantidades
      /*const quantities = this.batch['batchIngredientsQuantity']
        ? this.batch['batchIngredientsQuantity'].split(';')
        : [];
      */
        const quantities = this.batch['batchIngredientsQuantity']
        ? this.batch['batchIngredientsQuantity'].split(';').reduce((acc: { [key: string]: number }, pair: string) => {
            const [id, quantity] = pair.split(','); // Separa el id del quantity
            acc[id] = Number(quantity); // Asigna el quantity al id como clave
            return acc;
        }, {})
        : {};
    
      // Mapear los ingredientes, asignando la cantidad correspondiente
      this.batchIngredientsData = this.batch['batchIngredients'].map((ingredient: TableData) => {
        const ingredientId = ingredient['id'];
        return {
          name: ingredient['name'],
          // Asignar la cantidad correspondiente si existe, o usar la cantidad original del ingrediente
          quantity: quantities[ingredientId] !== undefined ? quantities[ingredientId] : ingredient['quantity'],
          unit: ingredient['unit'],
          provider: ingredient['provider']?.name || 'N/A',
        };
      });
    } else {
      console.warn('No se encontraron ingredientes en los datos del lote.');
    }
  }


  private getBatchInputsData(): void {
    if (Array.isArray(this.batch['batchPackagingElements'])) {
      // Dividir la cadena de batchPackagingElementsQuantity en un array de cantidades
      /*const quantities = this.batch['batchPackagingElementsQuantity']
        ? this.batch['batchPackagingElementsQuantity'].split(',')
        : [];
      */
      const quantities = this.batch['batchPackagingElementsQuantity']
        ? this.batch['batchPackagingElementsQuantity'].split(';').reduce((acc: { [key: string]: number }, pair: string) => {
            const [id, quantity] = pair.split(','); // Separa el id del quantity
            acc[id] = Number(quantity); // Asigna el quantity al id como clave
            return acc;
        }, {})
        : {};
      // Mapear los inputs, asignando la cantidad correspondiente
      this.batchInputsData = this.batch['batchPackagingElements'].map((input: TableData, index: number) => {
        return {
          codInput: input['id'],
          name: input['name'],
          // Asignar la cantidad correspondiente si existe, o usar la cantidad original del input
          quantity: quantities[index] || input['quantity'],
          unit: input['unit'],
          provider: input['provider']?.name || 'N/A',
        };
      });
    } else {
      console.warn('No se encontraron insumos en los datos del lote.');
    }
  }

}
