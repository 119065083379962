import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

export function handleError(error: HttpErrorResponse): Observable<never> {
  let errorMessage = '';

  /*if (error.error instanceof ErrorEvent) {
    console.error('Client-side error:', error.error.message);
    errorMessage = `Client-side error: ${error.error.message}`;
  } else {
    console.error(`Server-side error: ${error.status} - ${error.message}`);
    errorMessage = `Server-side error: ${error.status}\nMessage: ${error.message}`;
  }*/

  return throwError(() => new Error(errorMessage));
}
