import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { TableData } from '../../../core/interfaces/table-data';
import { DeleteModalComponent } from '../../../shared/components/delete-modal/delete-modal.component';
import { CommonModule } from '@angular/common';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { BatchesService } from '../../../core/services/api/batches.service';
import { SalesChannelsService } from '../../../core/services/api/saleschannels.service';
import { formatDate } from '../../../core/utils/mapping.utils';
import { AuthService } from '../../../core/services/auth/auth.service';
import { UsersService } from '../../../core/services/api/users.service';
import { priceValidator } from '../../../core/utils/validators';
import { EventsService } from '../../../core/services/api/events.service';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-sell-batch',
  standalone: true,
  imports: [CommonModule, DeleteModalComponent, ReactiveFormsModule],
  templateUrl: './sell-batch.component.html',
  styleUrls: ['./sell-batch.component.scss'],
})
export class SellBatchComponent implements OnInit {
  @Output() close = new EventEmitter<void>();

  confirmDeleteModal = false;
  sellForm: FormGroup;
  avaibleBatches: TableData[] = [];
  customers: TableData[] = [];
  maxQuantities: number[] = [];
  totalCost = 0;
  userId: any;

  constructor(
    private batchesService: BatchesService,
    private fb: FormBuilder,
    private salesChannelsService: SalesChannelsService,
    private authService: AuthService,
    private userService: UsersService,
    private eventService: EventsService
  ) {
    this.sellForm = this.fb.group({
      customer: ['', Validators.required],
      items: this.fb.array([], { validators: uniqueBatchesValidator }),
      price: [0, [Validators.required, priceValidator(this.totalCost)]],
    });
  }

  ngOnInit(): void {
    this.getClients();
    this.getAvaibleBatches();
    this.initializeRows(); // Initialize with one row and set max to five
    this.userId = this.authService.getUserId();
  }

  closeModal(): void {
    this.close.emit();
  }

  closeDeleteModal() {
    this.confirmDeleteModal = false;
  }

  onSubmit(): void {
    console.log(this.sellForm);
    if (this.sellForm.valid) {
      const formErrors = this.sellForm.get('items')?.errors;
      if (formErrors?.['duplicateBatches']) {
        console.error('No puedes seleccionar el mismo lote más de una vez.');
        return; // Detiene el proceso de envío
      }
      const batchesId = this.items.controls.map((control) => {
        return control.get('batchId')?.value;
      });
      const batchesQuantities = this.items.controls
        .map((control) => control.get('quantity')?.value.toString())
        .join(',');
      const batches = this.avaibleBatches.filter((b) =>
        batchesId.includes(b['id'])
      );
      const prices = batches.map((b) => b['price']);
      const pricesString = prices.join(',');
      const selectedBatches = batchesId.map((batchId) => {
        return { id: batchId };
      });
      //console.log('Batches:', selectedBatches);
      const descriptionParts = batches.map((batch, index) => {
        const quantity = batchesQuantities[index];
        return `${quantity} unit - ${batch['name']} (${batch['id']})`;
      });

      console.log('BatchesQuantities:', batchesQuantities);

      const fullDescription = descriptionParts.join(', ');
      const sellData = {
        type: 'sales',
        title: 'Venta',
        description: fullDescription,
        cost: this.sellForm.get('price')?.value,
        saleChannel: { id: this.sellForm.get('customer')?.value },
        user: { id: this.userId },
        soldBatches: selectedBatches,
        unitsPrice: pricesString,
        quantities: batchesQuantities,
      };
      console.log('Sell data:', sellData);
      console.log('JSON:', JSON.stringify(sellData));
      console.log('Selcted Batches:', selectedBatches);
      console.log('Batches Avaible:', this.avaibleBatches);
      const bq = batchesQuantities.split(',');
      console.log('Batches Quantities:', bq);
      this.eventService.createEvent(sellData).subscribe((res) => {
        const batchesToUpdate = this.avaibleBatches
          .map((batch) => {
            const selected = selectedBatches.find((b) => b.id === batch['id']);
            console.log('Selected:', selected);
            if (selected) {
              console.log('selectedBatches.indexOf(selected)',selectedBatches.indexOf(selected),
              'batchesQuantities[selectedBatches.indexOf(selected)]',batchesQuantities[selectedBatches.indexOf(selected)]);
              batch['quantity'] -= parseInt(
                bq[selectedBatches.indexOf(selected)],
                10
              );
              delete batch['selected'];
              console.log('Batch updated:', batch);
              return this.batchesService.updateBatch(batch).toPromise();
            }
            return null;
          })
          .filter(Boolean);
        Promise.all(batchesToUpdate).then(() => {
          console.log('Batches updated');
        });
        this.closeModal();
      });
    }
  }

  addRow(): void {
    this.items.push(
      this.fb.group({
        quantity: [0, Validators.required],
        batchId: [null], // Almacena el ID del lote seleccionado
      })
    );
    this.maxQuantities.push(0); // Initialize with default max quantity
    //this.updateCost();
  }

  removeRow(index: number): void {
    const removedBatchId = this.items.at(index).get('batchId')?.value;

    // Desmarcar el lote seleccionado
    if (removedBatchId) {
      const removedBatch = this.avaibleBatches.find(
        (b) => b['id'] === removedBatchId
      );
      if (removedBatch) {
        removedBatch['selected'] = false;
      }
    }

    this.items.removeAt(index);
    this.maxQuantities.splice(index, 1);
  }

  onBatchChange(event: Event, index: number): void {
    const target = event.target as HTMLSelectElement;
    const batchId = parseInt(target.value, 10);

    const batch = this.avaibleBatches.find((b) => b['id'] === batchId);
    if (batch) {
      this.maxQuantities[index] = batch['quantity'];
      this.items.at(index).patchValue({ batchId });

      // Marcar el lote seleccionado
      batch['selected'] = true;

      // Limpiar la selección del lote anterior si existía
      const previousBatchId = this.items.at(index).get('batchId')?.value;
      if (previousBatchId) {
        const previousBatch = this.avaibleBatches.find(
          (b) => b['id'] === previousBatchId
        );
        if (previousBatch) {
          previousBatch['selected'] = false;
        }
      }

      const quantityControl = this.items.at(index).get('quantity');
      quantityControl?.setValidators([
        Validators.required,
        Validators.min(1),
        Validators.max(this.maxQuantities[index]),
      ]);
      quantityControl?.updateValueAndValidity();
    }
  }

  private getAvaibleBatches(): void {
    this.batchesService.getBatches().subscribe((batches: TableData[]) => {
      this.avaibleBatches = batches.sort((a, b) =>
        a['name'].localeCompare(b['name'])
      );
    });
  }

  private getClients(): void {
    this.salesChannelsService
      .getSalesChannels()
      .subscribe((clients: TableData[]) => {
        this.customers = clients.sort((a, b) =>
          a['name'].localeCompare(b['name'])
        );
      });
  }

  private initializeRows(): void {
    this.items.clear();
    this.maxQuantities = [];
    this.items.push(
      this.fb.group({
        quantity: [0, Validators.required],
        batchId: [null, Validators.required],
      })
    );
    this.maxQuantities.push(0);
  }

  get items(): FormArray {
    return this.sellForm.get('items') as FormArray;
  }

  get itemControls(): FormControl[] {
    return this.items.controls as FormControl[];
  }

  // Método para obtener el control con verificación de tipo
  getBatchIdControl(index: number): FormControl {
    const control = this.items.at(index).get('batchId') as FormControl;
    if (!control) {
      console.error('Error!');
      throw new Error(`Control 'batchId' not found at index ${index}`);
    }
    return control;
  }

  getQuantityControl(index: number): FormControl {
    const control = this.items.at(index).get('quantity') as FormControl;
    if (!control) {
      console.error('Error!');
      throw new Error(`Control 'batchId' not found at index ${index}`);
    }
    return control;
  }

  calculateCost(): void {
    this.totalCost = 0; // Reinicia el costo total
    this.items.controls.forEach((control) => {
      const batchId = control.get('batchId')?.value;
      const quantity = control.get('quantity')?.value;

      if (batchId !== null && quantity !== null) {
        const batch = this.avaibleBatches.find((b) => b['id'] === batchId);
        if (batch) {
          const batchPrice = batch['price'] || 2;
          const batchQuantity = batch['quantity'] || 1;
          const result = Math.round(batchPrice / batchQuantity);
          this.totalCost += quantity *result;
        }
      }
    });
    this.updatePriceValidator();
  }

  updatePriceValidator(): void {
    const priceControl = this.sellForm.get('price');
    if (priceControl) {
      // Actualiza los validadores del control con el nuevo valor de totalCost
      priceControl.setValidators([
        Validators.required,
        Validators.min(this.totalCost),
      ]);

      // Recalcula la validez del control para aplicar los nuevos validadores
      priceControl.updateValueAndValidity();
    }
  }

  getDate(date: any): string {
    return formatDate(date);
  }
}
interface BatchItem {
  batchId: number; // o el tipo correcto si no es number
}

function uniqueBatchesValidator(
  control: AbstractControl
): ValidationErrors | null {
  const items = control.value as BatchItem[]; // Define el tipo de 'items'
  const batchIds = items.map((item: BatchItem) => item.batchId); // Especifica el tipo de 'item'
  const hasDuplicates = new Set(batchIds).size !== batchIds.length;

  return hasDuplicates ? { duplicateBatches: true } : null;
}
