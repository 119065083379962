import { Injectable } from '@angular/core';
import { BatchesService } from '../api/batches.service';
import { TableData } from '../../interfaces/table-data';
import { Observable,  BehaviorSubject, map } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private batchCategories: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  constructor(private batchesService: BatchesService) { }

  chargeCategories(): void {
    this.batchesService.getCategories().subscribe({
      next: (res) => {
        if (Array.isArray(res)) {
          this.batchCategories.next(res); // Actualiza el BehaviorSubject con los datos recibidos
        } else {
          console.error('Unexpected response format', res);
        }
      },
      error: err => {
        console.error('Error fetching categories', err);
      }
    });
  }

  getCategories(): Observable<string[]> {
    return this.batchCategories.asObservable();
  }

  addCategory(category: string): void {
   this.batchCategories.pipe(
      map(categories => {
        if (!categories.includes(category)) {
          categories.push(category);
          categories.sort();
          this.batchCategories.next(categories); // Emit the updated list
        }
      })
    ).subscribe(); // Asegúrate de suscribirte al observable
  }

 
}
