import { TableData } from '../interfaces/table-data';

export function sortTableData(dataArray: TableData[]): TableData[] {
  return dataArray.map((data) => {
    const orderedData: TableData = {};
    const order = [
      'id',
      'name',
      'surname',
      'email',
      'type',
      'category',
      'quantity',
      'unit',
      'expiration',
      'expirationDate',
    ];

    // Añadir las claves en el orden especificado
    order.forEach((key) => {
      if (key in data) {
        orderedData[key] = data[key];
      }
    });

    // Añadir las claves que no están en el orden especificado
    Object.keys(data).forEach((key) => {
      if (!(key in orderedData)) {
        orderedData[key] = data[key];
      }
    });

    return orderedData;
  });
}

export function formatTableData(data: TableData[]): TableData[] {
  return data.map((item) => {
    const formattedItem: TableData = {};

    // Claves que deben ser formateadas como fechas
    const dateKeys = [
      'expiration',
      'expirationDate',
      'creationDate',
      'lastModificationDate',
      'admissionDate',
      'envenDate',
    ];

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'America/Argentina/Buenos_Aires',
      hour12: false,
    };

    Object.keys(item).forEach((key) => {
      if (dateKeys.includes(key) && item[key]) {
        // Convertir el valor a una instancia de Date y formatear
        const date = new Date(item[key]);
        formattedItem[key] = new Intl.DateTimeFormat('es-AR', options)
          .format(date)
          .replace(',', '');
      } else {
        // Copiar el valor sin cambios
        formattedItem[key] = item[key];
      }
    });
    return formattedItem;
  });
}
// Ordenar datos de la tabla

export function sortTableDataByColumn(
  data: TableData[],
  column: string,
  direction: 'up' | 'down' | ''
): TableData[] {
  if (!column || direction === '') {
    return data; // Sin columna o dirección vacía, no ordenar
  }

  // Determina el orden de clasificación basado en la dirección
  const isAscending = direction === 'up';

  return data.slice().sort((a, b) => {
    if (a[column] === undefined || b[column] === undefined) {
      return 0;
    }

    // Comparar valores, asumiendo que son números o cadenas
    const aValue = a[column];
    const bValue = b[column];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return isAscending
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return isAscending ? aValue - bValue : bValue - aValue;
    }

    return 0;
  });
}

export function filterTableData(
  data: TableData[],
  excludeColumns: string[],
  filterValue: string
): TableData[] {
  if (!filterValue) {
    return data; // Sin valor de filtro, no filtrar
  }

  const filter = filterValue.trim().toLowerCase();

  return data.filter((item) => {
    return Object.keys(item).some((key) => {
      if (excludeColumns.includes(key)) {
        return false;
      }
      const value = item[key];
      if (typeof value === 'string') {
        return value.toLowerCase().includes(filter);
      }
      return false;
    });
  });
}
