<app-navbar></app-navbar>
<div class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100">
    
    <div class="col-flex justify-content-center align-items-center">
        <div class="row header-row">
            <div class="col">
                <h1 class="view-title">Lotes</h1>
            </div>
            <div class="col">
               <app-search-bar (search)="handleSearch($event)"></app-search-bar>
            </div>
            <div class="col d-flex justify-content-center">
                <button class="btn btn-custom" (click)="openSellBatch()">VENDER</button>
            </div>
            <div class="col d-flex justify-content-center">
                <button class="btn btn-custom" (click)="goTo()">AGREGAR LOTE</button>
            </div>
        </div>

        <app-table [data]="batches" [columnsExcluded]="excludedColumns" (rowClicked)="onRowClicked($event)"></app-table>

    </div>
</div>

<app-view-batch [batch]="selectedBatch" *ngIf="openBatchModal" (close)="closeModal()"></app-view-batch>

<app-sell-batch *ngIf="openSellModal" (close)="closeSellModal()"></app-sell-batch>
