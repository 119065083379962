import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';
import { TableComponent } from '../../shared/components/table/table.component';
import { SearchBarComponent } from '../../shared/components/search-bar/search-bar.component';
import { TableData } from '../../core/interfaces/table-data';
import { ProvidersService } from '../../core/services/api/providers.service';
import { AddProviderComponent } from './add-provider/add-provider.component';
import { ProviderComponent } from './provider/provider.component';
import { ProductionComponent } from "../production/production.component";
import { CommonModule } from '@angular/common';
import { filterTableData } from '../../core/utils/table-data.utils';

@Component({
  selector: 'app-providers',
  standalone: true,
  imports: [CommonModule, NavbarComponent, TableComponent, SearchBarComponent, AddProviderComponent, ProviderComponent, ProductionComponent],
  templateUrl: './providers.component.html',
  styleUrl: './providers.component.scss'
})
export class ProvidersComponent implements OnInit {

  //Call to backend
  //providers = this.ProvidersService.getProviders();

  providers: TableData[] = [];
  backUpProviders: TableData[] = [];

  addProviderModal: boolean = false;
  openProviderModal: boolean = false;
  selectedProvider: any;

  exportColumns: string[] = ['description','cuit'];
  constructor(private ProvidersService: ProvidersService) { }

  ngOnInit(): void {
    this.getProviders();
  }

  openAddProviderModal() {
    this.addProviderModal = true;
  }
  closeAddProviderModal() {
    this.getProviders();
    this.addProviderModal = false;
  }
  onRowClicked(prov: TableData) {
    this.selectedProvider = prov;
    this.openProviderModal = true;
  }

  handleSearch(value: string) {
    this.providers = this.backUpProviders;
    this.providers = filterTableData(this.providers, this.exportColumns, value);
  }

  closeProviderModal() {
    this.getProviders();
    this.openProviderModal = false;
  }


  private getProviders() {
    this.ProvidersService.getProviders().subscribe((res: any) => {
      this.backUpProviders = res;
      this.providers = res;
    });
  }
}
