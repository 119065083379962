import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';
import { TableComponent } from '../../shared/components/table/table.component';
import { TableData } from '../../core/interfaces/table-data';
import { EventComponent } from './event/event.component';
import { EventsService } from '../../core/services/api/events.service';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-history',
  standalone: true,
  imports: [NavbarComponent , TableComponent, EventComponent, CommonModule],
  templateUrl: './history.component.html',
  styleUrl: './history.component.scss'
})

export class HistoryComponent implements OnInit {

  selectedEvent: TableData = {};
  openEventModal: boolean = false;
  movements: TableData[] = [];
  excludeColumns: string[] = ['user','description','soldBatches', 'quantities','unitsPrice','saleChannel','cost'];

  constructor(private eventsService: EventsService) {}
  
  ngOnInit(): void {
    this.getMovements();
  }

  onRowClicked(event: TableData) {
    this.selectedEvent = event;
    this.openEventModal = true;
  }

  closeEventModal() {
    this.openEventModal = false;
  }

  private getMovements() {
    this.eventsService.getEvents().subscribe((movements: TableData[]) => {
      this.movements = movements;
    });
  }
}
