import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError} from 'rxjs';
import { handleError } from '../../utils/api-error-handler.utils';
import { environment } from '../../../../evironments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  private apiUrl = environment.apiUrl + '/api/Providers';

  constructor(private http: HttpClient) {}

  getProviders(): Observable<any> {
    return this.http.get<any>(this.apiUrl).pipe(catchError(handleError));
  }

  getProviderById(id: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`).pipe(catchError(handleError));
  }
  createProvider(provider: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, provider).pipe(catchError(handleError));
  }

  updateProvider(provider: any): Observable<any> {
    return this.http.put<any>(this.apiUrl, provider).pipe(catchError(handleError));
  }

  deleteProvider(id: any): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${id}`).pipe(catchError(handleError));
  }

}
