<app-navbar></app-navbar>
<div class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100">
    
    <div class="col-flex justify-content-center align-items-center">
        <div class="row header-row">
            <div class="col">
                <h1 class="view-title">Usuarios</h1>
            </div>
            <div class="col">
               <app-search-bar (search)="handleSearch($event)"></app-search-bar>
            </div>
            <div class="col d-flex justify-content-center">
                <a type="button" class="btn btn-custom"  (click)="openAddUserModal()">AGREGAR USUARIO</a>
            </div>
        </div>

        <app-table [data]="users" [columnsExcluded]="columnsExcluded" (rowClicked)="onRowClicked($event)"></app-table>

    </div>
</div>


<app-add-user *ngIf="addUserModal"  (closeModalEvent)="closeAddUserModal()"></app-add-user>
<app-user *ngIf="openUserModal" [userInput]="selectedUser" (closeUserModalEvent)="closeUserModal()"></app-user>