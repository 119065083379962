import { Component, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'], // Asegúrate de que el nombre sea correcto
})
export class SearchBarComponent {
  @Output() search = new EventEmitter<string>();
  searchControl = new FormControl('');

  onFilter(): void {
    const value = this.searchControl.value?.trim();
    if (value !== undefined) {
      if (this.isValid(value)) {
        this.search.emit(value);
      } else {
        this.search.emit('');
      }
    }
  }

  // Manejar el evento de teclado
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onFilter();
    }
  }

  private isValid(value: string): boolean {
    const regex = /^[a-zA-Z0-9@. ]*$/;
    return typeof value === 'string' && value.length > 0 && regex.test(value);
  }
}
