<div
  class="modal modal-bg fade show"
  tabindex="-1"
  style="display: block"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content modal-content-custom">
      <div class="modal-header">
        <h5 class="modal-title">Agregar Existencia</h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="closeDecisionModal()"
        ></button>
      </div>
      <div class="modal-body d-flex align-items-center justify-content-center">
        <div class="row w-100">
          <div class="col d-flex justify-content-center">
            <button class="btn btn-custom btn-lg" (click)="openAddInput()">Agregar Insumo</button>
          </div>
          <div class="col d-flex justify-content-center">
            <button class="btn btn-custom btn-lg" (click)="openAddRawMaterial()">Agregar Materia Prima</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
