import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';
import { UsersService } from '../../core/services/api/users.service';
import { AuthService } from '../../core/services/auth/auth.service';
import { TableData } from '../../core/interfaces/table-data';
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [NavbarComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {

  user: TableData = {};

  constructor(
    private UsersService: UsersService,
    private AuthService: AuthService
  ) { }

  ngOnInit() {
    const id = this.AuthService.getUserId();
    this.UsersService.getUsersById(id).subscribe((user: TableData) => {
      this.user = user;
    });
  }

}
