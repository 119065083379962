import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { TableData } from '../../../core/interfaces/table-data';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SalesChannelsService } from '../../../core/services/api/saleschannels.service';
import { emailValidator, phoneValidator, cuitValidator } from '../../../core/utils/validators';
import { DeleteModalComponent } from '../../../shared/components/delete-modal/delete-modal.component';
@Component({
  selector: 'app-client',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DeleteModalComponent],
  templateUrl: './client.component.html',
  styleUrl: './client.component.scss'
})
export class ClientComponent {
  @Input() clientInput: TableData = {};
  @Output() closeClientModalEvent = new EventEmitter<void>();

  clientForm: FormGroup;
  isToggled: boolean = false;
  confirmDeleteModal: boolean = false;

  constructor(private salesChannelService: SalesChannelsService, private formBuilder: FormBuilder) {
    this.clientForm = this.formBuilder.group({
      name: [{ value: '', disabled: !this.isToggled }, [Validators.required,  Validators.pattern('^[a-zA-ZÀ-ÿ0-9 ]*$')]],
      cuit: [{ value: '', disabled: !this.isToggled }, [Validators.required, cuitValidator]],
      email: [{ value: '', disabled: !this.isToggled }, [Validators.required, emailValidator]],
      phone: [{ value: '', disabled: !this.isToggled }, [Validators.required, phoneValidator]],
      cp: [{ value: '', disabled: !this.isToggled }, [Validators.required, Validators.pattern('^(?!0000)[0-9]{4}$')]], // Ejemplo para un CP de 4-6 dígitos
      address: [{ value: '', disabled: !this.isToggled }, [Validators.required]],
      line2: [{ value: '', disabled: !this.isToggled }],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['clientInput'] && changes['clientInput'].currentValue) {
      this.clientForm.patchValue(this.transformClientInput(this.clientInput));
    }
  }

  toggleEditMode() {
    this.isToggled = !this.isToggled;
    if (this.isToggled) {
      this.clientForm.enable(); // Habilita todos los controles del formulario
    } else {
      this.clientForm.disable(); // Deshabilita todos los controles del formulario
    }
  }
 
  onSubmit() {
    if (this.clientForm.valid) {
      const cp = this.clientForm.get('cp')?.value;
      const direccion = this.clientForm.get('address')?.value;
      const linea2 = this.clientForm.get('line2')?.value; 
      const fullAddress = `${cp}|${direccion}|${linea2}`.trim().replace(/|\s*$/, '');
      const saleChannel = {
        id: this.clientInput['id'],
        name: this.clientForm.get('name')?.value,
        email: this.clientForm.get('email')?.value,
        phone: this.clientForm.get('phone')?.value,
        cuit: this.clientForm.get('cuit')?.value,
        addres: fullAddress,
      };
      //Call to backend
      this.salesChannelService.updateSaleChannel(saleChannel).subscribe(
        () => {
          this.closeClientModalEvent.emit();
        }
      );
    } else {
      this.clientForm.markAllAsTouched();
    }
  }

  
  closeModal() {
    this.closeClientModalEvent.emit();
  }

  confirmDelete() {
    this.confirmDeleteModal = true;
  }

  closeDeleteModal() {
    this.confirmDeleteModal = false;
  }

  deleteClient() {
    //Call backend
    this.salesChannelService.deleteSaleChannel(this.clientInput['id']).subscribe(
      () => {
        this.closeClientModalEvent.emit();
      }
    );
    
  }

  private transformClientInput(data: TableData): any {
    const [cp = '', address = '', line2 = ''] = data['addres']?.split('|') || [];

    return {
      id: data['id'] ?? null,
      name: data['name'] ?? '',
      email: data['email'] ?? '',
      phone: data['phone'] ?? '',
      cuit: data['cuit'] ?? '',
      cp: cp.trim(),
      address: address.trim(),
      line2: line2.trim(),
    };
  }
}
