import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-delete-modal',
  standalone: true,
  imports: [],
  templateUrl: './delete-modal.component.html',
  styleUrl: './delete-modal.component.scss'
})
export class DeleteModalComponent {
  @Input() confirmDeleteModal: boolean = false;
  @Input() headerMessage: string = '';
  @Input() bodyMessage: string = '';
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  closeDeleteModal() {
    this.cancel.emit();
  }

  deleteEntity() {
    this.confirm.emit();
  }

}
