import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TableData } from '../../../core/interfaces/table-data';
import { CommonModule } from '@angular/common';
import { UsersService } from '../../../core/services/api/users.service';
import { SalesChannelsService } from '../../../core/services/api/saleschannels.service';
@Component({
  selector: 'app-event',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './event.component.html',
  styleUrl: './event.component.scss'
})
export class EventComponent {
  @Input() event: TableData = {};
  @Output() closeEventModal = new EventEmitter<void>();

  labelTranslation: ColumnTranslations = {
    id: 'ID',
    title: 'Título',
    description: 'Descripción',
    envenDate: 'Fecha',
    user: 'Usuario',
    cost: 'Monto',
    saleChannel: 'Cliente',
  };

  userData: TableData = {};
  isSale: boolean = false;
  elements: string[] =[];
  splitElementsResult: { leftColumn: string[], rightColumn: string[] } = { leftColumn: [], rightColumn: [] };


  constructor(private userService: UsersService, private salesChannelService: SalesChannelsService) {
    
  }

  ngOnInit() {
    console.log(this.event['title']);
    if(this.event['title'] === "Venta"){
      this.isSale = true;
      this.formatDescription();
      console.log(this.elements);
      this.splitElements();
      console.log('Left: ',this.splitElementsResult.leftColumn);
      console.log('Right: ',this.splitElementsResult.rightColumn);
    }
  }
  closeModal() {
    this.closeEventModal.emit();
  }

  objectKeys(obj: TableData): string[] {
    return Object.keys(obj).filter(key => key !== 'soldBatches' && key !== 'unitsPrice' && key !== 'quantities' && key !== 'description');
  }
  

  splitKeys(): { leftColumn: string[], rightColumn: string[] } {
    const keys = this.objectKeys(this.event);
    const middleIndex = Math.ceil(keys.length / 2);
    return {
      leftColumn: keys.slice(0, middleIndex),
      rightColumn: keys.slice(middleIndex)
    };
  }

  translateLabel(column: string): string {
    return this.labelTranslation[column] || column;
  }

  splitElements(): void {
    const middleIndex = Math.ceil(this.elements.length / 2);
    this.splitElementsResult = {
      leftColumn: this.elements.slice(0, middleIndex),
      rightColumn: this.elements.slice(middleIndex),
    };
  }

  private formatDescription(){
    this.elements = this.event['description'].split(',');
  }
}

interface ColumnTranslations {
  [key: string]: string;
}
