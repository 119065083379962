<nav class="navbar navbar-container">
  <div class="container-fluid text-center">
    <div class="row tex-white">
      <div class="col col-md-3 col-lg col-xl-2">
        <a routerLink="/home" class="hidden-link"></a>
      </div>
      <div class="col d-flex">
        <div class="row">
          <div class="col justify-content-center">
            <a  routerLink="/inventory" class="nav-link" routerLinkActive="active-link">INVENTARIO</a>
          </div>
          <div class="col justify-content-center">
            <a routerLink="/production" class="nav-link" routerLinkActive="active-link">PRODUCCION</a>
          </div>
          <div class="col justify-content-center">
            <a routerLink="/recipes" class="nav-link" routerLinkActive="active-link">RECETAS</a>
          </div>
        </div>
      </div>
      <div class="col d-flex col-lg-1"></div>
      <div class="col d-flex justify-content-end">
        <div class="row">
          <div class="col justify-content-center">
            <a routerLink="/history" class="nav-link" routerLinkActive="active-link">HISTORIAL</a>
          </div>
          <div class="col justify-content-center" *ngIf="isAdmin">
            <a routerLink="/users" class="nav-link" routerLinkActive="active-link">USUARIOS</a>
          </div>
          <div class="col justify-content-end">
            <div class="dropdown">
              <button class="btn btn-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-list"></i>
              </button>
              <ul class="dropdown-menu dropdown-menu-end custom-dropdown-menu">
                <!--li><button routerLink="#" class="dropdown-item" type="button">| VER CALENDARIO</button></li-->
                <li><button routerLink="/providers" class="dropdown-item" type="button">| PROVEEDORES</button></li>
                <li><button routerLink="/clients" class="dropdown-item" type="button">| CLIENTES</button></li>
                <li><button routerLink="/profile" class="dropdown-item" type="button">| MI PERFIL</button></li>
                <li><button class="dropdown-item" type="button" (click)="logout()">| CERRAR SESIÓN</button></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
