import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UsersService } from '../../core/services/api/users.service';
import { AuthService } from '../../core/services/auth/auth.service';
import { EditProfileModalComponent } from './editProfile/edit-profile-modal/edit-profile-modal.component';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [NavbarComponent, ReactiveFormsModule, CommonModule, EditProfileModalComponent],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit {
  
  userId: any;
  user: any = {};
  openEditProfileModal: boolean = false;

  constructor(
    private authService: AuthService,
    private userService: UsersService
  ) {}

  ngOnInit(): void {
    this.userId = this.authService.getUserId();
    //console.log('UserId: ', this.userId);
    if(this.userId){
      this.getData();
    }
    
  }

  openUserModal(): void {
    this.openEditProfileModal = true;
  }

  closeUserModal(): void {
    this.getData();
    this.openEditProfileModal = false;
    
  }

  private getData(): void {
    this.userService.getUsersById(this.userId).subscribe((data: any) => {
      this.user = data
    });
  }
}
