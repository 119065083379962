<!-- table.component.html -->
<div class="table-container">
  <!-- Spinner de carga -->
  <div *ngIf="!isTableReady" class="spinner-container text-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Cargando..</span>
    </div>
  </div>

  <!-- Mensaje de no hay datos -->
  <div
    *ngIf="isTableReady && data.length === 0"
    class="no-data-container text-center"
  >
    <h4>{{ noDataMessage }}</h4>
  </div>

  <!-- Tabla y paginador -->
  <div *ngIf="isTableReady && data.length > 0" class="table-wrapper">
    <nav class="pagination-container">
      <ul class="pagination justify-content-center custom-pagination">
        <li class="page-item" [class.disabled]="currentPage === 1">
          <a class="page-link" (click)="setPage(currentPage - 1)">
            <i class="bi bi-chevron-left"></i>
          </a>
        </li>

        <li class="page-item" *ngIf="currentPage > 2">
          <a class="page-link" (click)="setPage(1)">1</a>
        </li>
        <li class="page-item disabled" *ngIf="currentPage > 3">
          <a class="page-link">...</a>
        </li>
        <li
          class="page-item"
          *ngFor="let page of getPageRange()"
          [class.active]="currentPage === page"
        >
          <a class="page-link" (click)="setPage(page)">{{ page }}</a>
        </li>
        <li
          class="page-item disabled"
          *ngIf="currentPage < totalPages - 2 && totalPages > 5"
        >
          <a class="page-link">...</a>
        </li>
        <li
          class="page-item"
          *ngIf="currentPage < totalPages - 1 && totalPages > 3"
        >
          <a class="page-link" (click)="setPage(totalPages)">{{
            totalPages
          }}</a>
        </li>

        <li class="page-item" [class.disabled]="currentPage === totalPages">
          <a class="page-link" (click)="setPage(currentPage + 1)">
            <i class="bi bi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </nav>

    <table class="table table-custom">
      <thead>
        <tr>
          <th
            *ngFor="let column of getColumns()"
            class="text-uppercase text-center"
          >
            <a (click)="onHeaderClick(column)">
              {{ translateColumn(column) }}
              <i
                *ngIf="getSortIcon(column)"
                [ngClass]="getSortIcon(column)"
              ></i>
            </a>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of paginatedData" (click)="onRowClick(item)">
          <td *ngFor="let column of getColumns()" class="text-center">
            <!-- Comprobar si el valor es booleano -->
            <ng-container *ngIf="item[column] === true; else falseIcon">
              <i class="bi bi-check-circle text-success"></i>
            </ng-container>
            <ng-template #falseIcon>
              <ng-container *ngIf="item[column] === false">
                <i class="bi bi-x-circle text-danger"></i>
              </ng-container>
            </ng-template>

            <!-- Mostrar expiration solo si la diferencia es menor a 60 años -->
            <ng-container *ngIf="column === 'expiration'">
              <div
                *ngIf="getAgeDifference(item[column]) <= 60; else noExpiration"
              >
                {{ item[column] | date : "dd/MM/yyyy" }}
              </div>
              <ng-template #noExpiration>
                <div>-</div>
              </ng-template>
            </ng-container>

            <!-- Mostrar valor normal si no es expiration -->
            <ng-container *ngIf="column !== 'expiration'">
              <div *ngIf="column === 'cost'">${{ item[column] }}</div>
              <div *ngIf="column !== 'cost'">
                {{ item[column] }}
              </div>
            </ng-container>
          </td>
        </tr>
        <!-- Filas vacías para completar hasta 10 filas -->
        <tr
          *ngFor="let _ of [].constructor(itemsPerPage - paginatedData.length)"
        >
          <td *ngFor="let column of getColumns()" class="text-center"></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
