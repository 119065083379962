import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError} from 'rxjs';
import { handleError } from '../../utils/api-error-handler.utils';
import { environment } from '../../../../evironments/environment';

@Injectable({
  providedIn: 'root'
})
export class RawMaterialsService {

  private apiUrl = environment.apiUrl + '/api/RawMaterials';

  constructor(private http: HttpClient) {}

  getRawMaterials(): Observable<any> {
    return this.http.get<any>(this.apiUrl).pipe(catchError(handleError));
  }

  getRawMaterialById(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`).pipe(catchError(handleError));
  }
  createRawMaterial(rm: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, rm).pipe(catchError(handleError));
  }

  updateRawMaterial(rm: any): Observable<any> {
    console.log(JSON.stringify(rm));
    return this.http.put<any>(this.apiUrl, rm).pipe(catchError(handleError));
  }

  deleteRawMaterial(id: any): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${id}`).pipe(catchError(handleError));
  }


  //CUSTOM METHODS

  getRawMaterialsTypes(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/getTypes`).pipe(catchError(handleError));
  }
}
