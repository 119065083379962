<app-navbar></app-navbar>
<div class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100">
    
    <div class="col-flex justify-content-center align-items-center">
        <div class="row header-row">
            <div class="col">
                <h1 class="view-title">ULTIMOS MOVIMIENTOS</h1>
            </div>
            <div class="col d-flex justify-content-end">
                <button class="btn btn-custom">BUSQUEDA AVANZADA</button>
            </div>
        </div>

        <app-table [data]="movements" [columnsExcluded]="excludeColumns" (rowClicked)="onRowClicked($event)"></app-table>

    </div>
</div>

<app-event *ngIf="openEventModal" [event]="selectedEvent" (closeEventModal)="closeEventModal()"></app-event>