import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';
import { SearchBarComponent } from '../../shared/components/search-bar/search-bar.component';
import { CardComponent } from './card/card.component';
import { CarouselComponent } from './carousel/carousel.component';
import { RecipeAbstraction } from '../../core/interfaces/recipe-abstraction';
import { RecipesService } from '../../core/services/api/recipes.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { filterTableData } from '../../core/utils/table-data.utils';
import { TableData } from '../../core/interfaces/table-data';

@Component({
  selector: 'app-recipes',
  standalone: true,
  imports: [NavbarComponent, SearchBarComponent, CardComponent, CarouselComponent, CommonModule],
  templateUrl: './recipes.component.html',
  styleUrl: './recipes.component.scss'
})
export class RecipesComponent implements OnInit{
  recipes: RecipeAbstraction[] = [];
  backUpRecipes: RecipeAbstraction[] = [];
  excludedColumns: string[] = [];
  constructor(private recipeService: RecipesService,  private router: Router) {
    this.getRecipes();
  }

  ngOnInit(): void {
    this.getRecipes();
  }

  goTo(): void {
    this.router.navigate(['/recipes/add']);
  }

  handleSearch(value: string) {
    this.recipes = this.backUpRecipes;
    this.recipes = filterRecipeData(this.recipes, this.excludedColumns, value);
    console.log(this.recipes);
  }

  private getRecipes(): void {
    this.recipeService.getRecipes().subscribe((res) => {
      this.recipes = res;
      this.backUpRecipes = res;
    });
  }
}

function filterRecipeData(
  data: RecipeAbstraction[],
  excludeColumns: string[],
  filterValue: string
): RecipeAbstraction[] {
  if (!filterValue) {
    return data; // Sin valor de filtro, no filtrar
  }

  const filter = filterValue.trim().toLowerCase();

  return data.filter((item) => {
    return Object.keys(item).some((key) => {
      if (excludeColumns.includes(key)) {
        return false;
      }
      const value = item[key as keyof RecipeAbstraction];
      if (typeof value === 'string') {
        return value.toLowerCase().includes(filter);
      }
      return false;
    });
  });
}