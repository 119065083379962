import { Component, OnInit} from '@angular/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit{
  isAdmin: boolean = false;
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.isAdmin = this.authService.getUserRole() === 'Admin';
  }

  logout() {
    this.authService.logOut();
    this.router.navigate(['/']);
  }
}
