import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';
import { TableComponent } from '../../shared/components/table/table.component';
import { SearchBarComponent } from '../../shared/components/search-bar/search-bar.component';
import { TableData } from '../../core/interfaces/table-data';
import { CommonModule } from '@angular/common';
import { InventoryService } from '../../core/services/enums/inventory.service';
import { filterTableData } from '../../core/utils/table-data.utils';
import { DecisionModalComponent } from './decision-modal/decision-modal.component';
import { AddInputComponent } from './add-input/add-input.component';
import { AddRawMaterialComponent } from './add-raw-material/add-raw-material.component';
import { RawMaterialComponent } from './raw-material/raw-material.component';
import { InputsComponent } from './inputs/inputs.component';
@Component({
  selector: 'app-inventory',
  standalone: true,
  imports: [
    NavbarComponent,
    TableComponent,
    SearchBarComponent,
    CommonModule,
    DecisionModalComponent,
    AddInputComponent,
    AddRawMaterialComponent,
    RawMaterialComponent,
    InputsComponent,
  ],
  templateUrl: './inventory.component.html',
  styleUrl: './inventory.component.scss',
})
export class InventoryComponent implements OnInit {
  inputs: TableData[] = [];
  backUpInputs: TableData[] = [];
  editRawMaterialsModal: boolean = false;
  editInputsModal: boolean = false;
  inputsModal: boolean = false;
  rawMaterialsModal: boolean = false;
  decisionModal: boolean = false;
  isLoading: boolean = false;
  selectedProduct: any;

  excludeColumns: string[] = [
    'provider',
    'description',
    'batches',
    'descripction',
    'admissionDate',
    'lastModificationDate',
    'expirationDate',
    'freeze',
  ];

  constructor(private inventoryService: InventoryService) {}

  ngOnInit(): void {
    this.getInventory();
  }

  openDecisionModal() {
    this.decisionModal = true;
  }

  closeDecisionModal(aux: string) {
    if (aux === 'addInput') {
      this.openAddInputModal();
    } else if (aux === 'addRawMaterial') {
      this.openAddRawMaterialModal();
    }
    this.decisionModal = false;
  }

  openAddInputModal() {
    this.inputsModal = true;
  }

  closeAddInputModal() {
    this.getInventory();
    this.inputsModal = false;
  }
  openAddRawMaterialModal() {
    this.rawMaterialsModal = true;
  }

  closeAddRawMaterialModal() {
    this.getInventory();
    this.rawMaterialsModal = false;
  }

  onRowClicked(prov: TableData) {
    this.selectedProduct = prov;
    if(this.selectedProduct['freeze'] !== undefined) {
      this.editRawMaterialsModal = true;
    } else {
      this.editInputsModal = true;
    }
  }

  closeProductModal() {
    this.getInventory();
    this.editRawMaterialsModal = false;
    this.editInputsModal = false;

  }

  handleSearch(value: string) {
    this.inputs = this.backUpInputs;
    this.inputs = filterTableData(this.inputs, this.excludeColumns, value);
  }

  private getInventory() {
    this.inventoryService.getInventory().subscribe((res) => {
      this.inputs = res;
      this.backUpInputs = res;
      this.isLoading = true;
    });
  }
}
