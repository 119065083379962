<app-navbar></app-navbar>
<div
  class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100"
>
  <div class="row justify-content-center align-items-center w-100">
    <div class="col-10 col-main">
      <form
        #aux="ngForm"
        [formGroup]="batchForm"
        (ngSubmit)="onSubmit()"
        class="w-100"
      >
        <div class="row header-row mb-4">
          <div class="col">
            <h1 class="view-title">Modificar Lote</h1>
          </div>
          <div class="col d-flex justify-content-end">
            <button type="submit" class="btn btn-custom">GUARDAR</button>
          </div>
        </div>
        <div class="row custom-row">
          <div class="col-5">
            <div class="form-group">
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-3">
                    <label class="subtitle" for="name">Nombre</label>
                  </div>
                  <div class="col">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      name="name"
                      formControlName="name"
                      [ngClass]="{
                        'is-invalid':
                          (batchForm.get('name')?.dirty ||
                            batchForm.get('name')?.touched ||
                            aux.submitted) &&
                          batchForm.get('name')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        aux.submitted ||
                        (batchForm.get('name')?.invalid &&
                          (batchForm.get('name')?.dirty ||
                            batchForm.get('name')?.touched))
                      "
                      class="text-danger"
                    >
                      <div *ngIf="batchForm.get('name')?.errors?.['required']">
                        Nombre es requerido.
                      </div>
                      <div
                        *ngIf="!batchForm.get('name')?.errors?.['required'] && batchForm.get('name')?.errors?.['pattern']"
                      >
                        El nombre no debe contener números ni símbolos.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-3">
                    <label for="category" class="mb-0 subtitle"
                      >Categoria</label
                    >
                  </div>
                  <div class="col">
                    <select
                      id="category"
                      formControlName="category"
                      class="form-select me-2"
                      aria-label="category Selection"
                      placeholder="Seleccione un tipo"
                      [ngClass]="{
                        'is-invalid':
                          (batchForm.get('category')?.dirty ||
                            batchForm.get('category')?.touched ||
                            aux.submitted) &&
                          batchForm.get('category')?.invalid
                      }"
                    >
                      <option value="" disabled selected>
                        Selecciona una Categoria
                      </option>
                      <option *ngFor="let c of categories" [value]="c">
                        {{ c }}
                      </option>
                    </select>
                  </div>
                  <div class="col-2 d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-custom"
                      (click)="openAddCategoryModal()"
                    >
                      <i class="bi bi-plus-square-fill"></i>
                    </button>
                  </div>
                </div>
                <div class="error-container">
                  <div
                    *ngIf="aux.submitted && batchForm.get('category')?.invalid"
                    class="text-danger mt-1"
                  >
                    <div
                      *ngIf="batchForm.get('category')?.errors?.['required']"
                    >
                      La Categoria es necesaria.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-3">
                    <label class="subtitle" for="quantity">Cantidad</label>
                  </div>
                  <div class="col">
                    <input
                      type="text"
                      class="form-control"
                      id="quantity"
                      name="quantity"
                      formControlName="quantity"
                      [ngClass]="{
                        'is-invalid':
                          (batchForm.get('quantity')?.dirty ||
                            batchForm.get('quantity')?.touched ||
                            aux.submitted) &&
                          batchForm.get('quantity')?.invalid
                      }"
                    />
                  </div>
                  <div class="col-2">
                    <label class="subtitle" for="unit">Unidad</label>
                  </div>
                  <div class="col-2">
                    <select
                      id="unit"
                      formControlName="unit"
                      class="form-select me-2"
                      aria-label="Unit Selection"
                      [ngClass]="{
                        'is-invalid':
                          (batchForm.get('unit')?.dirty ||
                            batchForm.get('unit')?.touched ||
                            aux.submitted) &&
                          batchForm.get('unit')?.invalid
                      }"
                    >
                      <option value="" disabled selected>...</option>
                      <option *ngFor="let unit of units" [value]="unit">
                        {{ unit }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row error-container align-items-center">
                  <div
                    class="col-8 text-danger"
                    *ngIf="
                      aux.submitted ||
                      (batchForm.get('quantity')?.invalid &&
                        (batchForm.get('quantity')?.dirty ||
                          batchForm.get('quantity')?.touched))
                    "
                  >
                    <div
                      *ngIf="batchForm.get('quantity')?.errors?.['required']"
                    >
                      Es necesario ingresar una cantidad.
                    </div>
                    <div
                      *ngIf="!batchForm.get('quantity')?.errors?.['required'] && batchForm.get('quantity')?.errors?.['invalidQuantity']"
                    >
                      La cantidad debe ser un número válido mayor a 0.
                    </div>
                  </div>
                  <div
                    class="col text-danger"
                    *ngIf="
                      aux.submitted ||
                      (batchForm.get('unit')?.invalid &&
                        (batchForm.get('unit')?.dirty ||
                          batchForm.get('unit')?.touched))
                    "
                  >
                    <div *ngIf="batchForm.get('unit')?.errors?.['required']">
                      Ingrese una unidad.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col">
                    <label class="subtitle" for="expirationDate"
                      >Fecha de Vencimiento:</label
                    >
                  </div>
                  <div class="col">
                    <input
                      type="datetime-local"
                      class="form-control"
                      id="expirationDate"
                      formControlName="expirationDate"
                      [attr.min]="minDate"
                      [ngClass]="{
                        'is-invalid':
                          (batchForm.get('expirationDate')?.dirty ||
                            batchForm.get('expirationDate')?.touched ||
                            aux.submitted) &&
                          batchForm.get('expirationDate')?.invalid
                      }"
                    />
                  </div>
                  <div class="error-container">
                    <div
                      *ngIf="
                        aux.submitted &&
                        batchForm.get('expirationDate')?.invalid &&
                          (batchForm.get('expirationDate')?.dirty ||
                            batchForm.get('expirationDate')?.touched)
                      "
                      class="text-danger"
                    >
                      Se necesita una Fecha de Vencimiento.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row-container">
                <div class="row align-items-center">
                  <div class="col-3">
                    <label class="subtitle" for="quantity">Precio</label>
                  </div>
                  <div class="col">
                    <input
                      type="number"
                      class="form-control"
                      id="price"
                      name="price"
                      formControlName="price"
                      [ngClass]="{
                        'is-invalid':
                          (batchForm.get('price')?.dirty ||
                            batchForm.get('price')?.touched ||
                            aux.submitted) &&
                          batchForm.get('price')?.invalid
                      }"
                    />
                  </div>
                  <div class="col-2">
                    <label class="subtitle" for="unit">Costo</label>
                  </div>
                  <div class="col-2 cost">
                    {{ totalCost }}
                  </div>
                </div>
                <div class="error-container">
                  <div
                    *ngIf="
                      (batchForm.get('price')?.dirty ||
                        batchForm.get('price')?.touched ||
                        aux.submitted) &&
                      batchForm.get('price')?.invalid
                    "
                    class="text-danger"
                  >
                    El Precio debe ser igual o mayor al costo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-border">
            <div class="row align-items-center">
              <div class="col">
                <label class="subtitle">Ingredientes</label>
              </div>
              <div class="col d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-custom"
                  (click)="openRMFilters()"
                >
                  <i class="bi bi-filter-square-fill"></i>
                </button>
              </div>
            </div>
            <div formArrayName="ingredients" class="ingredient-list">
              <div
                *ngFor="
                  let materialControl of selectedIngredients.controls;
                  let i = index
                "
                [formGroupName]="i"
                class="ingredient-item"
              >
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    formControlName="selected"
                    id="material-{{ i }}"
                  />
                  <label class="form-check-label" for="material-{{ i }}">
                    {{ avaibleRawMaterials[i].name }} (Disponible:
                    {{ avaibleRawMaterials[i].quantity }}
                    {{ avaibleRawMaterials[i].unit }})
                  </label>
                </div>
                <div
                  *ngIf="materialControl.get('selected')?.value"
                  class="form-group"
                >
                  <label for="quantity-{{ i }}">Cantidad:</label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="quantity"
                    id="quantity-{{ i }}"
                    (change)="calculateCost()"
                    [ngClass]="{
                      'is-invalid':
                        (batchForm.get('quantity')?.dirty ||
                          batchForm.get('quantity')?.touched ||
                          aux.submitted) &&
                        batchForm.get('quantity')?.invalid
                    }"
                  />
                  <div
                    *ngIf="
                      aux.submitted ||
                      (materialControl.get('quantity')?.invalid &&
                        (materialControl.get('quantity')?.dirty ||
                          materialControl.get('quantity')?.touched))
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="materialControl.get('quantity')?.errors?.['min']"
                    >
                      La cantidad mínima es 1.
                    </div>
                    <div
                      *ngIf="materialControl.get('quantity')?.errors?.['max']"
                    >
                      La cantidad máxima es
                      {{ avaibleRawMaterials[i].quantity }}.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                (aux.submitted && batchForm.get('ingredients')?.invalid) ||
                (batchForm.get('ingredients')?.touched &&
                  batchForm.get('ingredients')?.invalid)
              "
              class="text-danger mt-2"
            >
              Debe seleccionarse al menos un ingrediente.
            </div>
          </div>

          <div class="col col-border">
            <div class="row align-items-center">
              <div class="col">
                <label class="subtitle">Insumos</label>
              </div>
              <div class="col d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-custom"
                  (click)="openIFilters()"
                >
                  <i class="bi bi-filter-square-fill"></i>
                </button>
              </div>
            </div>
            <div formArrayName="packages" class="ingredient-list">
              <div
                *ngFor="
                  let materialControl of selectedInputs.controls;
                  let i = index
                "
                [formGroupName]="i"
                class="ingredient-item"
              >
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    formControlName="selected"
                    id="material-{{ i }}"
                  />
                  <label class="form-check-label" for="material-{{ i }}">
                    {{ avaibleInputs[i].name }} (Disponible:
                    {{ avaibleInputs[i].quantity }} {{ avaibleInputs[i].unit }})
                  </label>
                </div>
                <div
                  *ngIf="materialControl.get('selected')?.value"
                  class="form-group"
                >
                  <label for="quantity-{{ i }}">Cantidad:</label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="quantity"
                    id="quantity-{{ i }}"
                    (change)="calculateCost()"
                    [ngClass]="{
                      'is-invalid':
                        (batchForm.get('quantity')?.dirty ||
                          batchForm.get('quantity')?.touched ||
                          aux.submitted) &&
                        batchForm.get('quantity')?.invalid
                    }"
                  />
                  <div
                    *ngIf="
                      aux.submitted ||
                      (materialControl.get('quantity')?.invalid &&
                        (materialControl.get('quantity')?.dirty ||
                          materialControl.get('quantity')?.touched))
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="materialControl.get('quantity')?.errors?.['min']"
                    >
                      La cantidad mínima es 1.
                    </div>
                    <div
                      *ngIf="materialControl.get('quantity')?.errors?.['max']"
                    >
                      La cantidad máxima es {{ avaibleInputs[i].quantity }}.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                (aux.submitted && batchForm.get('packages')?.invalid) ||
                (batchForm.get('packages')?.touched &&
                  batchForm.get('packages')?.invalid)
              "
              class="text-danger mt-2"
            >
              Debe seleccionarse al menos un insumo.
            </div>
          </div>
        </div>
        <div class="row custom-row">
          <hr />
          <div class="col">
            <div class="form-group">
              <label class="subtitle" for="description">Descripción</label>
              <!--textarea class="form-control" id="description" name="description"
								formControlName="description"></textarea-->
              <p-editor
                id="description"
                name="description"
                formControlName="description"
                class="form-control p-editor-custom"
                [style]="{ height: '240px' }"
                [ngClass]="{
                  'is-invalid':
                    (batchForm.get('description')?.dirty ||
                      batchForm.get('description')?.touched ||
                      aux.submitted) &&
                    batchForm.get('description')?.invalid
                }"
              >
                <ng-template pTemplate="header">
                  <span class="ql-formats">
                    <select class="ql-header" aria-label="Select header">
                      <option selected></option>
                      <option value="1">Heading 1</option>
                      <option value="2">Heading 2</option>
                      <option value="3">Heading 3</option>
                    </select>
                    <select class="ql-font" aria-label="Select font">
                      <option selected></option>
                      <option value="serif">Serif</option>
                      <option value="monospace">Monospace</option>
                    </select>
                    <button class="ql-bold" aria-label="Bold"></button>
                    <button class="ql-italic" aria-label="Italic"></button>
                    <button
                      class="ql-underline"
                      aria-label="Underline"
                    ></button>
                    <select class="ql-align" aria-label="Select align">
                      <option selected></option>
                      <option value="center">Center</option>
                      <option value="right">Right</option>
                      <option value="justify">Justify</option>
                    </select>
                    <button
                      class="ql-list"
                      value="ordered"
                      aria-label="Ordered list"
                    ></button>
                    <button
                      class="ql-list"
                      value="bullet"
                      aria-label="Bullet list"
                    ></button>
                    <button class="ql-link" aria-label="Insert link"></button>
                  </span>
                </ng-template>
              </p-editor>
            </div>
            <div class="error-container">
              <div
                *ngIf="
                  (aux.submitted && batchForm.get('description')?.invalid) ||
                  (batchForm.get('description')?.invalid &&
                    (batchForm.get('description')?.dirty ||
                      batchForm.get('description')?.touched))
                "
                class="text-danger"
              >
                Descripción es requerida.
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Add Category Modal -->

<div
  *ngIf="addCategoryModal"
  class="modal modal-bg fade show"
  tabindex="-1"
  style="display: block"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="confirmDeleteModalLabel">
          Agregar Tipo
        </h1>
        <button
          type="button"
          class="btn-close"
          (click)="closeAddCategoryModal()"
        ></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="addCategoryForm" (ngSubmit)="addCategory()">
          <div class="form-group">
            <label for="newCategory">Nuevo Tipo</label>
            <input
              type="text"
              class="form-control"
              id="newCategory"
              formControlName="newCategory"
              placeholder="Ingrese el nuevo tipo"
            />
            <div
              *ngIf="
                addCategoryForm.get('newCategory')?.invalid &&
                (addCategoryForm.get('newCategory')?.dirty ||
                  addCategoryForm.get('newType')?.touched)
              "
              class="text-danger mt-1"
            >
              <div
                *ngIf="addCategoryForm.get('newCategory')?.errors?.['required']"
              >
                La categoria es requerida.
              </div>
              <div
                *ngIf="addCategoryForm.get('newCategory')?.errors?.['pattern']"
              >
                La categoria no debe contener números ni símbolos.
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeAddCategoryModal()"
        >
          CANCELAR
        </button>
        <button type="submit" class="btn btn-success" (click)="addCategory()">
          GUARDAR
        </button>
      </div>
    </div>
  </div>
</div>
