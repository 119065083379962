import { Component, EventEmitter, output, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator, phoneValidator, cuitValidator } from '../../../core/utils/validators';
import { SalesChannelsService } from '../../../core/services/api/saleschannels.service';

@Component({
  selector: 'app-add-client',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './add-client.component.html',
  styleUrl: './add-client.component.scss'
})
export class AddClientComponent {
  @Output() closeModalEvent = new EventEmitter<void>();
  clientForm: FormGroup;
  confirmPassword: string = '';

  constructor(private salesChannelService: SalesChannelsService, private formBuilder: FormBuilder) {
    this.clientForm = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.pattern('^[a-zA-ZÀ-ÿ0-9 ]*$')]],
      cuit: ['', [Validators.required, cuitValidator]],
      email: ['', [Validators.required, emailValidator]],
      phone: ['', [Validators.required, phoneValidator]],
      cp: ['', [Validators.required, Validators.pattern('^(?!0000)[0-9]{4}$')]], // Ejemplo para un CP de 4-6 dígitos
      address: ['', [Validators.required]],
      line2: [''],
    });
  }

  closeModal() {
    this.closeModalEvent.emit();
  }

  onSubmit() {
    if (this.clientForm.valid) {
      const fullAddress = this.clientForm.get('cp')?.value +'|'+ this.clientForm.get('address')?.value + '|' + this.clientForm.get('line2')?.value;
      const sc ={
        name: this.clientForm.get('name')?.value,  
        cuit: this.clientForm.get('cuit')?.value,
        email: this.clientForm.get('email')?.value,
        phone: this.clientForm.get('phone')?.value,
        addres: fullAddress
      }
      //Call to backend
    this.salesChannelService.createSaleChannel(sc).subscribe(
      (response) => {
        this.closeModalEvent.emit(); // Cerrar el modal
      },
      (error) => {
        console.error('Error al crear el canal de venta:', error);
      }
    );
    }
    else {
      this.clientForm.markAllAsTouched();
      console.error('Formulario inválido. Revise los campos.');
    }
    
  }
}
