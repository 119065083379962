import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { User } from '../../../core/interfaces/entities/user';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TableData } from '../../../core/interfaces/table-data';
import { UsersService } from '../../../core/services/api/users.service';
import { phoneValidator, emailValidator, matchPasswordValidator } from '../../../core/utils/validators';
import { DeleteModalComponent } from '../../../shared/components/delete-modal/delete-modal.component';
import { AuthService } from '../../../core/services/auth/auth.service';
@Component({
  selector: 'app-user',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormsModule, DeleteModalComponent],
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnChanges {
  @Input() userInput: TableData = {};
  @Output() closeUserModalEvent = new EventEmitter<void>();

  userForm: FormGroup;
  isToggled: boolean = false;
  confirmDeleteModal: boolean = false;
  myId: any;

  constructor(private UsersService: UsersService, private fb: FormBuilder, private AuthService: AuthService) { 
    this.userForm = this.fb.group({
      email: [{ value: '', disabled: !this.isToggled }, [Validators.required, emailValidator]],
      name: [{ value: '', disabled: !this.isToggled }, [Validators.required,  Validators.pattern("^[a-zA-ZÀ-ÿ'\\- ]+$"),]],
      surname: [{ value: '', disabled: !this.isToggled }, [Validators.required,  Validators.pattern("^[a-zA-ZÀ-ÿ'\\- ]+$"),]],
      phone: [{ value: '', disabled: !this.isToggled }, [Validators.required, phoneValidator]],
      password: [{ value: '', disabled: !this.isToggled }],
      confirmPassword: [{ value: '', disabled: !this.isToggled }],
      role: [{ value: '', disabled: !this.isToggled }, Validators.required]
    },{ validator: matchPasswordValidator() });
  }

  ngOnInit(){
    this.myId = this.AuthService.getUserId();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userInput'] && changes['userInput'].currentValue) {
      const user = this.transformUserInput(this.userInput);
      this.userForm.patchValue(user);
      this.userForm.get('role')?.setValue(user.role);
    }
  }

  toggleEditMode() {
    this.isToggled = !this.isToggled;
    if (this.isToggled) {
      this.userForm.enable(); // Habilita todos los controles del formulario
    } else {
      this.userForm.disable(); // Deshabilita todos los controles del formulario
    }
  }

  closeModal() {
    this.closeUserModalEvent.emit();
  }

  onSubmit() {
    if (this.userForm.valid) {;
      const userToSave: TableData = {
        id: this.userInput['id'],
        name: this.userForm.get('name')?.value,
        surname: this.userForm.get('surname')?.value,
        email: this.userForm.get('email')?.value,
        phone: this.userForm.get('phone')?.value,
        role: this.userForm.get('role')?.value,
        password: this.userForm.get('password')?.value || this.userInput['password'] // La contraseña es opcional
      };
      //console.log('User to save:', userToSave);
      // Call backend
      this.UsersService.updateUser(userToSave).subscribe(
        () => {
          this.closeUserModalEvent.emit();
        }
      );
    } else {
      this.userForm.markAllAsTouched();
    }
  }

  confirmDelete() {
    this.confirmDeleteModal = true;
  }

  closeDeleteModal() {
    this.confirmDeleteModal = false;
  }

  deleteUser() {
    //Call backend
    //console.log('User to delete:', this.userInput['id']);
    this.UsersService.deleteUser(this.userInput['id']).subscribe(
      response => {
        // Aquí puedes manejar la respuesta si es necesario
        //console.log('Usuario eliminado:', response);
        // Cerrar el modal de confirmación de eliminación
        this.confirmDeleteModal = false;
        // Emitir el evento para cerrar el modal del usuario
        this.closeUserModalEvent.emit();
      },
      error => {
        // Aquí puedes manejar cualquier error que ocurra durante la eliminación
        console.error('Error al eliminar el usuario:', error);
      }
    );
  }

  isMe(){
    return this.userInput['id'] === this.myId;
  }
  private transformUserInput(data: TableData): User {
    //console.log('Data:', data);
    return {
      id: data['id'],
      name: data['name'] ?? '',
      surname: data['surname'] ?? '',
      email: data['email'] ?? '',
      phone: data['phone'] ?? '',
      password: '',  // No mostramos la contraseña
      role: data['role'] ?? ''
    };
  }
}
