import { CommonModule, } from '@angular/common';
import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { TableData } from '../../../../core/interfaces/table-data';
import { emailValidator, matchPasswordValidator, phoneValidator } from '../../../../core/utils/validators';
import { User } from '../../../../core/interfaces/entities/user';
import { UsersService } from '../../../../core/services/api/users.service';
@Component({
  selector: 'app-edit-profile-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './edit-profile-modal.component.html',
  styleUrl: './edit-profile-modal.component.scss'
})
export class EditProfileModalComponent implements OnInit {
  @Input() userInput: TableData = {};
  @Output() close = new EventEmitter<void>();

  userForm: FormGroup;

  constructor(private fb: FormBuilder, private UsersService: UsersService) {
    this.userForm = this.fb.group({
      email: [ '', [Validators.required, emailValidator]],
      name: [{ value: ''}, [Validators.required,  Validators.pattern('[a-zA-Z ]*')]],
      surname: [{ value: ''}, [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      phone: ['', [Validators.required, phoneValidator]],
      password: [''],
      confirmPassword: [''],
      role: ['', Validators.required]
    },{ validator: matchPasswordValidator() });
  }

  ngOnInit(): void {
    const user = this.transformUserInput(this.userInput);
    this.userForm.patchValue(user);
  }

  onSubmit() {
    if (this.userForm.valid) {
      let pass = this.userInput['password'];
      if (this.userForm.get('password')?.value !== '') {
        pass = this.userForm.get('password')?.value;
      }
      const userToSave: TableData = {
        id: this.userInput['id'],
        name: this.userForm.get('name')?.value,
        surname: this.userForm.get('surname')?.value,
        email: this.userForm.get('email')?.value,
        phone: this.userForm.get('phone')?.value,
        role: this.userForm.get('role')?.value,
        password: pass
      };
      console.log('User to save:', userToSave);
      // Call backend
      this.UsersService.updateUser(userToSave).subscribe(
        () => {
          this.close.emit();
        }
      );
    } else {
      this.userForm.markAllAsTouched();
    }
  }

  closeModal() {
    this.close.emit();
  }

  private transformUserInput(data: TableData): User {
    console.log('Data:', data);
    return {
      id: data['id'],
      name: data['name'] ?? '',
      surname: data['surname'] ?? '',
      email: data['email'] ?? '',
      phone: data['phone'] ?? '',
      password: '',  // No mostramos la contraseña
      role: data['role'] ?? ''
    };
  }
}
